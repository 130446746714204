import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import Tabs from 'components/common/Tabs/Tabs';
import Typography from 'components/common/Typography/Typography';
import { useFetchTrips } from 'hooks/trips';
import { isComponentPageAtom } from 'state/store';
import { ReactComponent as GlobusSvg } from 'styles/icons/globus.svg';
import { ITrip } from 'types/trips';
import { parse } from 'utils/qs';

import TripItem from './components/TripItem/TripItem';

import HelpTooltip from 'components/common/HelpTooltip';
import Loader from 'components/common/Loader';
import { useCurrentSection } from 'hooks/general';
import { useProfile } from 'hooks/profile';
import { router } from 'index';
import styles from './Trips.module.scss';

interface TripsProps {
  className?: string;
  currentTrip?: ITrip;
  isTripLoading?: boolean;
}

const getAreNextFromUrl = (
  tripId: string | undefined,
  nextTrips: ITrip[],
  pastTrips: ITrip[]
) => {
  if (tripId) {
    const { areNext } = parse(window.location.search);
    if (areNext !== undefined) {
      return areNext ? areNext === 'true' : true;
    }
  }
  return nextTrips.length > 0 || (!nextTrips.length && !pastTrips.length);
};
const replaceOrAddAreNextQueryParam = (
  index: number,
  futureTripsData: { isFetched: boolean; trips: ITrip[] },
  pastTripsData: { isFetched: boolean; trips: ITrip[] }
) => {
  const { Id } =
    index === 0 ? futureTripsData.trips[0] : pastTripsData.trips[0];
  const searchString = new URLSearchParams(window.location.search);
  if (searchString.has('areNext')) {
    searchString.delete('areNext');
  }
  searchString.append('areNext', (index === 0).toString());
  router.navigate({
    pathname: `/detailed-trips/${Id}`,
    search: searchString.toString(),
  });
};
const useReplaceAreNext = (id: string | undefined) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (id) return;
    const searchString = new URLSearchParams(window.location.search);
    if (searchString.has('areNext')) {
      searchString.delete('areNext');
    }
    navigate(`${window.location.pathname}?${searchString}`);
  }, []);
};
const useTripsTabByDefailt = (
  futureTripsData: { isFetched: boolean; trips: ITrip[] },
  pastTripsData: { isFetched: boolean; trips: ITrip[] }
) => {
  const { id } = useParams<{ id: string }>();
  const { guid } = useProfile()[0].value;
  const [selectedTab, setSelectedTab] = useState(0);
  const prevGuid = useRef<string>('');
  useEffect(() => {
    if (
      !(
        prevGuid.current !== guid &&
        futureTripsData.isFetched &&
        pastTripsData.isFetched
      )
    ) {
      return;
    }
    setSelectedTab(
      getAreNextFromUrl(id, futureTripsData.trips, pastTripsData.trips) ? 0 : 1
    );
    prevGuid.current = guid;
  }, [futureTripsData.trips, pastTripsData.trips]);
  return { selectedTab, setSelectedTab };
};

const redirectIfFutureOrPastEmpty = (
  defaultTrips: ITrip[],
  fallbackTrips: ITrip[],
  newAreNext: string,
  setTab: (v: boolean) => void
) => {
  const searchParams = new URLSearchParams(window.location.search);
  if (defaultTrips.length) {
    router.navigate({
      pathname: `/detailed-trips/${defaultTrips[0].Id}`,
      search: window.location.search,
    });
    setTab(true);
    return;
  }
  if (searchParams.has('areNext')) {
    searchParams.set('areNext', newAreNext);
  }
  router.navigate({
    pathname: `/detailed-trips/${fallbackTrips[0].Id}`,
    search: searchParams.toString(),
  });
  setTab(false);
};

const useRedirectIfNoTrip = ({
  areNext,
  currentTrip,
  futureTripsData,
  isTripLoading,
  pastTripsData,
  setSelectedTab,
}: {
  currentTrip?: ITrip;
  isTripLoading?: boolean;
  futureTripsData: { trips: ITrip[]; isLoading: boolean };
  pastTripsData: { trips: ITrip[]; isLoading: boolean };
  areNext: boolean;
  setSelectedTab: (v: number) => void;
}) => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (
      !id ||
      currentTrip ||
      isTripLoading ||
      futureTripsData.isLoading ||
      pastTripsData.isLoading
    ) {
      return;
    }
    if (!(futureTripsData.trips.length || pastTripsData.trips.length)) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('areNext')) {
        searchParams.delete('areNext');
      }
      router.navigate({
        pathname: `/profile`,
        search: window.location.search,
      });
      return;
    }
    if (areNext) {
      redirectIfFutureOrPastEmpty(
        futureTripsData.trips,
        pastTripsData.trips,
        'false',
        (v) => setSelectedTab(v ? 0 : 1)
      );

      return;
    }
    redirectIfFutureOrPastEmpty(
      pastTripsData.trips,
      futureTripsData.trips,
      'true',
      (v) => setSelectedTab(v ? 1 : 0)
    );
  }, [id, currentTrip, isTripLoading, futureTripsData, pastTripsData]);
};
const Trips: FC<TripsProps> = ({ className, currentTrip, isTripLoading }) => {
  const { id } = useParams<{ id: string }>();
  const isComponentPage = useAtomValue(isComponentPageAtom);
  const { guid } = useProfile()[0].value;
  const currentSection = useCurrentSection();
  const futureTripsData = useFetchTrips(guid, true);
  const pastTripsData = useFetchTrips(guid, false);
  const { selectedTab, setSelectedTab } = useTripsTabByDefailt(
    futureTripsData,
    pastTripsData
  );
  useReplaceAreNext(id);
  const areNext = selectedTab === 0;
  const { fetchNextPage, hasNextPage, isLoading, trips } = areNext
    ? futureTripsData
    : pastTripsData;
  useRedirectIfNoTrip({
    areNext,
    currentTrip,
    futureTripsData,
    isTripLoading,
    pastTripsData,
    setSelectedTab,
  });

  const hasFutureTrips = futureTripsData.trips.length > 0;
  const hasPastTrips = pastTripsData.trips.length > 0;
  const tabs = [
    {
      Content: (
        <TripsContainer
          trips={trips}
          currentTrip={currentTrip}
          section="Next"
          isLoading={isLoading}
          areNext={areNext}
        />
      ),
      index: 0,
      isDisabled: !hasFutureTrips,
      title: (
        <div
          className={styles.tab}
          data-e2e-tab={!hasFutureTrips ? 'No Next trips' : 'Next trips'}
        >
          <Typography
            variant="bodyMedium"
            className={classNames(styles.tabTitle, styles.fw500)}
          >
            {!hasFutureTrips ? 'No Next trips' : 'Next trips'}
          </Typography>
        </div>
      ),
    },
    {
      Content: (
        <TripsContainer
          currentTrip={currentTrip}
          trips={trips}
          section="Past"
          isLoading={isLoading}
          areNext={areNext}
        />
      ),
      index: 1,
      isDisabled: !hasPastTrips,
      title: (
        <div
          className={styles.tab}
          data-e2e-tab={!hasPastTrips ? 'No Past trips' : 'Past trips'}
        >
          <Typography
            variant="bodyMedium"
            className={classNames(styles.tabTitle, styles.fw500)}
          >
            {!hasPastTrips ? 'No Past trips' : 'Past trips'}
          </Typography>
        </div>
      ),
    },
  ];

  const onTabChange = (index: number) => {
    setSelectedTab(index);
    if (currentTrip) {
      replaceOrAddAreNextQueryParam(index, futureTripsData, pastTripsData);
    }
  };

  return (
    <div
      className={classNames(
        styles.trips,
        !isComponentPage && styles.noComponentPageTrips,
        className
      )}
    >
      {!isComponentPage && currentSection !== 'pickTrip' && (
        <div
          className={classNames('mb-3 flex w-fit items-center gap-1')}
          id="tripsSummaryHeading"
        >
          <Typography variant="bodyLarge2">Trips Summary</Typography>
          <HelpTooltip
            heading="Trips Summary"
            description="In this section you can see the FCMplatform – OBT booked trips of the customer, our view would have the next trips and the past trips (Already travelled), clicking here you would have a full view of the trip of the customer with the hotel, flight and car details."
          />
        </div>
      )}
      <div>
        <Tabs initial={selectedTab} onActiveChange={onTabChange} tabs={tabs} />
      </div>
      {hasNextPage && (
        <Button
          disabled={isLoading}
          className={styles.loadMore}
          variant="secondary"
          onClick={() => fetchNextPage()}
        >
          Load more trips
        </Button>
      )}
    </div>
  );
};

export default Trips;

interface ITripsContainerProps {
  section: 'Past' | 'Next';
  trips: ITrip[];
  isLoading: boolean;
  currentTrip: ITrip | undefined;
  areNext: boolean;
}

const TripsContainer: FC<ITripsContainerProps> = ({
  areNext,
  currentTrip,
  isLoading,
  section,
  trips,
}) => {
  return (
    <div>
      {(!isEmpty(trips) || currentTrip) && (
        <TripsList areNext={areNext} trips={trips} currentTrip={currentTrip} />
      )}
      {isEmpty(trips) && !currentTrip && !isLoading && (
        <EmptyTrips section={section} />
      )}
      {isLoading && <Loader e2e="trips_loader" className="mt-4 h-[72px]" />}
    </div>
  );
};

interface ITripsListProps {
  trips: ITrip[];
  currentTrip: ITrip | undefined;
  areNext: boolean;
}

const TripsList: FC<ITripsListProps> = ({ areNext, currentTrip, trips }) => {
  const selectedTripInList = trips.find((trip) => trip.Id === currentTrip?.Id);
  const currentTripTop = currentTrip && !selectedTripInList;

  return (
    <div className={styles.tripsContainer}>
      {currentTripTop && (
        <TripItem
          className="mb-2"
          currentTrip={currentTrip}
          trip={currentTrip}
          areNext={areNext}
        />
      )}
      {trips.map((trip, idx) => (
        <TripItem
          currentTrip={currentTrip}
          trip={trip}
          key={trip.Id}
          areNext={areNext}
        />
      ))}
    </div>
  );
};

interface IEmptyTrips {
  section: string;
}

const EmptyTrips: FC<IEmptyTrips> = ({ section }) => {
  return (
    <div className={styles.emptyContainer}>
      <div>
        <GlobusSvg />
      </div>
      <div className={styles.text}>
        <p className={styles.main}>{section} trips</p>
        <p className={styles.sub}>have not been added yet</p>
      </div>
    </div>
  );
};
