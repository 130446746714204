import { useIsMutating } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import ActionNotification from 'components/common/ActionNotification/ActionNotification';
import Button from 'components/common/Button/Button';
import HelpTooltip from 'components/common/HelpTooltip';
import CirclePlus from 'components/common/Icons/CirclePlus';
import Modal from 'components/common/Modal';
import Typography from 'components/common/Typography/Typography';
import NoteSpinnerOverlay from 'components/pages/Profile/components/InternalNotes/NoteSpinnerOverlay';
import {
  useAddCompanyDoc,
  useDeleteCompanyDoc,
  useEditCompanyDoc,
  useFetchCompanyDocs,
} from 'hooks/company';
import { atom, useAtom } from 'jotai';
import { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import { renderPlurals } from 'utils/format';
import CompanyDocInput from './CompanyDocInput';
import DocumentItem from './DocumentItem';
import EmptyCompanyDocs from './EmptyCompanyDocs';

export const CompanyDocSkeleton = () => {
  return (
    <div className="flex flex-col gap-3 bg-clarity-white p-3">
      <div className="flex justify-between">
        <div className="h-3 w-[100px] bg-grey-2" />
      </div>
      <div className="h-3 w-full bg-grey-2" />
    </div>
  );
};

export const confirmDeleteCompanyDocOpenAtom = atom<
  | {
      companyGuid: string;
      documentGuid: string;
      title: string;
      url: string;
    }
  | undefined
>(undefined);

const CompanyDocs: FC = () => {
  const { data } = useFetchCompanyDocs();
  const [confirmDeleteCompanyDocOpen, setConfirmDeleteCompanyDocOpen] = useAtom(
    confirmDeleteCompanyDocOpenAtom
  );

  const retry = useRef<undefined | (() => void)>(undefined);

  const total = data?.length;
  const [companyDocAddShown, setCompanyDocAddShown] = useState(false);
  const isAddCompanyDocLoading = useIsMutating({
    mutationKey: ['addCompanyDoc'],
  });

  const [error, setError] = useState<AxiosError | undefined>();
  const { error: deleteCompanyDocError, mutate: deleteCompanyDoc } =
    useDeleteCompanyDoc();
  const { error: addCompanyDocError, mutate: addCompanyDoc } =
    useAddCompanyDoc();
  const { error: editCompanyDocError, mutate: editCompanyDoc } =
    useEditCompanyDoc();

  useEffect(() => {
    setError(deleteCompanyDocError as AxiosError | undefined);
  }, [deleteCompanyDocError]);

  useEffect(() => {
    setError(editCompanyDocError as AxiosError | undefined);
  }, [editCompanyDocError]);

  useEffect(() => {
    setError(addCompanyDocError as AxiosError | undefined);
  }, [addCompanyDocError]);

  const closeCompanyDocInput = () => {
    setCompanyDocAddShown(false);
  };

  return (
    <>
      <div className="relative flex grow flex-col gap-2 bg-clarity-white p-4 lg:w-0">
        <div className="flex items-center gap-1">
          <Typography variant="bodyLarge2">Company Documents</Typography>
          <HelpTooltip
            description="Please add here any Company documents that may help with processing traveller's cases. These documents can be travel policies or other related ones. These documents will be seen by any other travel consultant searching this Company."
            heading="Company Documents"
          />
        </div>
        {companyDocAddShown && (
          <CompanyDocInput
            mode="ADD"
            onClose={closeCompanyDocInput}
            retry={retry}
            addCompanyDoc={addCompanyDoc}
            editCompanyDoc={editCompanyDoc}
          />
        )}
        {!!total && (
          <Typography variant="bodySmall" color="charcoalGray">
            <span className="font-medium">{total}</span>{' '}
            {renderPlurals({
              count: total,
              entity: ['document', 'documents'],
              showAmount: false,
            })}{' '}
            displayed
          </Typography>
        )}
        <div className="flex flex-col">
          {!!isAddCompanyDocLoading && (
            <div className="relative">
              <CompanyDocSkeleton />
              <NoteSpinnerOverlay />
            </div>
          )}
          {!!total && (
            <div className="flex flex-col border-2 border-grey-2">
              {data.map((doc) => (
                <DocumentItem
                  {...doc}
                  key={doc.documentGuid}
                  className="border-b border-b-grey-2 last:border-b-0"
                  {...{
                    addCompanyDoc,
                    editCompanyDoc,
                    retry,
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {!total && !isAddCompanyDocLoading && <EmptyCompanyDocs />}

        <Button
          variant="text"
          className={classNames(
            'absolute right-4 top-4 flex gap-1 hover:text-deep-green active:text-fcm-green',
            {
              'cursor-default text-fcm-green': companyDocAddShown,
            }
          )}
          customPadding
          onClick={() => setCompanyDocAddShown(true)}
        >
          <CirclePlus />
          Add Document
        </Button>
        {!!confirmDeleteCompanyDocOpen && (
          <Modal
            isOpen={!!confirmDeleteCompanyDocOpen}
            setIsOpen={() => setConfirmDeleteCompanyDocOpen(undefined)}
            className="flex flex-col gap-5 p-4"
          >
            <div className="flex justify-between">
              <Typography variant="bodyLarge2">Delete Document</Typography>
              <Button
                variant="text"
                className="flex items-center justify-center"
                onClick={() => setConfirmDeleteCompanyDocOpen(undefined)}
              >
                <CloseSvg />
              </Button>
            </div>
            <div>
              <Typography variant="bodyMedium">
                Are you sure you want to delete the document{' '}
                <span className="break-words font-medium">
                  “{confirmDeleteCompanyDocOpen.title}”
                </span>
                ?
              </Typography>
            </div>
            <div className="flex justify-end gap-2">
              <Button
                variant="secondary"
                onClick={() => setConfirmDeleteCompanyDocOpen(undefined)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  deleteCompanyDoc(confirmDeleteCompanyDocOpen.documentGuid);
                  retry.current = () =>
                    deleteCompanyDoc(confirmDeleteCompanyDocOpen.documentGuid);
                  setConfirmDeleteCompanyDocOpen(undefined);
                }}
              >
                Yes, delete
              </Button>
            </div>
          </Modal>
        )}
      </div>
      {error && !error?.request?.status && (
        <ActionNotification
          isOpen={!!error}
          onClose={() => setError(undefined)}
          variant="error"
          retry={() => {
            if (typeof retry.current === 'function') {
              setError(undefined);
              retry.current();
            }
          }}
          text={error.message}
        />
      )}
    </>
  );
};

export default CompanyDocs;
