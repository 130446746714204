import classNames from 'classnames';
import { atom, useAtom, useAtomValue } from 'jotai';
import { FC, useEffect, useRef, useState } from 'react';

import Button from 'components/common/Button/Button';
import CirclePlus from 'components/common/Icons/CirclePlus';
import Typography from 'components/common/Typography/Typography';
import { isComponentPageAtom } from 'state/store';
import NoteSvg from 'styles/icons/note.svg';
import { INote, NotePriorityKeys } from 'types/notes';
import { IProfile } from 'types/profile';
import { isNA } from 'utils/common';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';

import { AxiosError } from 'axios';
import ActionNotification from 'components/common/ActionNotification/ActionNotification';
import HelpTooltip from 'components/common/HelpTooltip';
import Loader from 'components/common/Loader';
import {
  useAddNote,
  useDeleteNote,
  useEditNote,
  useFetchNotes,
} from 'hooks/internalNotes';
import { useProfile } from 'hooks/profile';
import { renderPlurals } from 'utils/format';
import styles from './InternalNotes.module.scss';
import NoteInput from './NoteInput';
import NoteItem from './NoteItem';
import NoteSpinnerOverlay from './NoteSpinnerOverlay';
import Modal from 'components/common/Modal';

enum Mode {
  Create = 'CREATE',
  Edit = 'EDIT',
  Nothing = 'NOTHING',
}

export const PAYLOAD_SIZE = 5;
export const NOTES_DEFAULT_PAGINATION = { left: 0, page: 1 };

const NoteSkeleton = () => {
  return (
    <div className="flex flex-col gap-3 bg-clarity-white p-3">
      <div className="flex justify-between">
        <div className="h-3 w-[100px] bg-grey-2" />
        <div className="h-3 w-[120px] bg-grey-2" />
      </div>
      <div className="h-3 w-full bg-grey-2" />
      <div className="h-3 w-full bg-grey-2" />
    </div>
  );
};

export const confirmDeleteInternalNoteOpenAtom = atom<INote['id'] | undefined>(
  undefined
);

const InternalNotes: FC = () => {
  const [mode, setMode] = useState(Mode.Nothing);
  const profile = useProfile()[0].value;
  const isComponentPage = useAtomValue(isComponentPageAtom);
  const retry = useRef<undefined | Function>(undefined);
  const {
    data: notes,
    error: fetchNotesError,
    isFetching: isFetchNotesFetching,
    isLoading: isFetchNotesLoading,
  } = useFetchNotes();
  const {
    addNote,
    error: addNoteError,
    isPending: isAddNoteLoading,
  } = useAddNote();
  const { editNote, error: editNoteError } = useEditNote();
  const { deleteNote, error: deleteNoteError } = useDeleteNote();

  const [error, setError] = useState<AxiosError | undefined>();

  useEffect(() => {
    setError(fetchNotesError as AxiosError | undefined);
  }, [fetchNotesError]);

  useEffect(() => {
    setError(addNoteError as AxiosError | undefined);
  }, [addNoteError]);

  useEffect(() => {
    setError(editNoteError as AxiosError | undefined);
  }, [editNoteError]);

  useEffect(() => {
    setError(deleteNoteError as AxiosError | undefined);
  }, [deleteNoteError]);

  const onAddMode = () => {
    setMode(Mode.Create);
  };

  const resetMode = () => {
    setMode(Mode.Nothing);
  };

  const onDeleteNote = (id: number) => {
    setConfirmDeleteInternalNoteOpen(id);
  };

  const onEditNote = async (
    id: INote['id'],
    newPriority: NotePriorityKeys,
    newText: string
  ) => {
    editNote({ id, newPriority, newText });
    retry.current = () => editNote({ id, newPriority, newText });
  };

  const onAddNote = (data: {
    newPriority: NotePriorityKeys;
    newText: string;
  }) => {
    retry.current = () => onAddNote(data);
    addNote(data);
    setMode(Mode.Nothing);
  };
  const [confirmDeleteInternalNoteOpen, setConfirmDeleteInternalNoteOpen] =
    useAtom(confirmDeleteInternalNoteOpenAtom);
  return (
    <div
      className={classNames(
        styles.notesContainer,
        !isComponentPage && styles.notesContainerSpacing
      )}
    >
      <Header onAdd={onAddMode} mode={mode} profile={profile} />
      {mode !== Mode.Nothing && (
        <NoteInput
          className={styles.mt4}
          mode={mode}
          onSubmit={onAddNote}
          onCancel={resetMode}
          key={mode}
          priority={1}
        />
      )}

      {!!notes.length && (
        <NotesList
          notes={notes}
          onDeleteNote={onDeleteNote}
          onEditNote={onEditNote}
          isAddNoteLoading={isAddNoteLoading}
        />
      )}
      {isFetchNotesLoading && <Loader e2e="notes_loader" className="mt-2" />}
      {!isFetchNotesFetching && !notes.length && <EmptyNotes />}
      {error && !error?.request?.status && (
        <ActionNotification
          isOpen={!!error}
          onClose={() => setError(undefined)}
          variant="error"
          retry={() => {
            if (typeof retry.current === 'function') {
              setError(undefined);
              retry.current();
            }
          }}
          text={error.message}
        />
      )}
      {!!confirmDeleteInternalNoteOpen && (
        <Modal
          isOpen={!!confirmDeleteInternalNoteOpen}
          setIsOpen={() => setConfirmDeleteInternalNoteOpen(undefined)}
          className="flex flex-col gap-5 p-4"
        >
          <div className="flex justify-between">
            <Typography variant="bodyLarge2">Delete Note</Typography>
            <Button
              variant="text"
              className="flex items-center justify-center"
              onClick={() => setConfirmDeleteInternalNoteOpen(undefined)}
            >
              <CloseSvg />
            </Button>
          </div>
          <Typography variant="bodyMedium">
            Are you sure you want to delete the Note?
          </Typography>
          <div className="flex justify-end gap-2">
            <Button
              variant="secondary"
              onClick={() => setConfirmDeleteInternalNoteOpen(undefined)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                deleteNote(confirmDeleteInternalNoteOpen);
                retry.current = () => deleteNote(confirmDeleteInternalNoteOpen);
                setConfirmDeleteInternalNoteOpen(undefined);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

interface IHeader {
  onAdd: Function;
  mode: Mode;
  profile: IProfile;
}

const Header: FC<IHeader> = ({ mode, onAdd, profile }) => {
  const isComponentPage = useAtomValue(isComponentPageAtom);
  return (
    <div className={styles.headerContainer}>
      {!isComponentPage && (
        <div className="flex items-center gap-1">
          <h3
            className={classNames(styles.title, 'w-fit')}
            id="internalNotesHeading"
          >
            Internal Notes
          </h3>
          <HelpTooltip
            heading="Internal Notes"
            description="Please leave here any characteristics of your customer that connects us to them with empathy for example: “Travelling with a dog”, “He lives in New York”, “Has 3 kids and one new born”, “He doesn’t like to fly with American airlines”. This information would be seen by any other travel consultant searching this profile."
          />
        </div>
      )}
      {!isNA(profile.guid) && (
        <Button
          variant="text"
          className={classNames(
            styles.noteBtn,
            mode === Mode.Create && styles.activeNoteBtn,
            'hover:text-deep-green active:text-fcm-green'
          )}
          customPadding={!isComponentPage}
          onClick={() => onAdd()}
          disabled={mode === Mode.Create}
        >
          <CirclePlus />
          <span>Add Note</span>
        </Button>
      )}
    </div>
  );
};

interface INotesList {
  notes: INote[];
  onDeleteNote: Function;
  onEditNote: (
    id: INote['id'],
    newPriority: NotePriorityKeys,
    newText: string
  ) => void;
  isAddNoteLoading: boolean;
}

const NotesList: FC<INotesList> = ({
  isAddNoteLoading,
  notes,
  onDeleteNote,
  onEditNote,
}) => {
  console.log('isAddNoteLoading', isAddNoteLoading);
  return (
    <div className={styles.notesListContainer}>
      <Typography variant="bodySmall2" color="charcoalGray" className="mt-2">
        <span className={styles.halfbold}>{notes.length}</span>{' '}
        {renderPlurals({
          count: notes.length,
          entity: ['note', 'notes'],
          showAmount: false,
        })}{' '}
        displayed
      </Typography>
      <div className={styles.notesList}>
        {isAddNoteLoading && (
          <div className="relative">
            <NoteSkeleton />
            <NoteSpinnerOverlay />
          </div>
        )}
        {notes.map((note) => {
          return (
            <NoteItem
              onDeleteNote={onDeleteNote}
              onEditNote={onEditNote}
              key={note.id}
              {...note}
            />
          );
        })}
      </div>
    </div>
  );
};

interface IEmptyNotes {}

const EmptyNotes: FC<IEmptyNotes> = () => {
  return (
    <div className={styles.emptyNotes}>
      <img src={NoteSvg} alt="note" />
      <div className={styles.text}>
        <span className={styles.main}>Internal Notes</span>
        <span className={styles.sub}>have not been added yet</span>
      </div>
    </div>
  );
};

interface ILoadMoreBtn {
  count: number;
  type: string;
  onLoadMore: () => void;
}

const LoadMoreBtn: FC<ILoadMoreBtn> = ({ count, onLoadMore, type }) => {
  const typeText = count === 1 ? type : `${type}s`;
  return (
    <Button
      className={styles.loadMoreBtn}
      variant="secondary"
      onClick={onLoadMore}
    >
      Load {count} more {typeText}
    </Button>
  );
};

export default InternalNotes;
