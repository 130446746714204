import classNames from 'classnames';
import { FC, Fragment, useEffect, useRef, useState } from 'react';

import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography/Typography';
import { NOTES_PRIORITY } from 'constants/notes';
import { ReactComponent as DeleteSvg } from 'styles/icons/delete.svg';
import palette from 'styles/palette';
import { INote, NotePriorityKeys } from 'types/notes';

import InternalNotePriority from './InternalNotePriority';

import RequiredStar from 'components/common/RequiredStar/RequiredStar';
import TextArea, { useTextArea } from 'components/common/TextArea';
import { getValidateMaxChars } from 'components/pages/Company/components/CompanyDocInput';
import { useIsEditingOrDeletingCompanyNote } from 'hooks/company';
import { useProfile } from 'hooks/profile';
import styles from './InternalNotes.module.scss';
import NoteSpinnerOverlay from './NoteSpinnerOverlay';

enum Mode {
  Create = 'CREATE',
  Edit = 'EDIT',
  Nothing = 'NOTHING',
}
interface INoteInput {
  mode: Mode;
  text?: string;
  author?: string;
  time?: string;
  priority?: NotePriorityKeys;
  className?: string;
  onSubmit: (
    value: Pick<INote, 'priority' | 'text'> & Partial<Pick<INote, 'id'>>
  ) => void;
  onCancel: Function;
  onDeleteNote?: Function;
  id?: INote['id'];
}
const MAX_CHARS = 500;

const NoteInput: FC<INoteInput> = ({
  author,
  className,
  id,
  mode,
  onCancel,
  onDeleteNote: onDelete,
  onSubmit,
  priority,
  text = '',
  time,
}) => {
  const [newPriority, setNewPriority] = useState(priority);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const profile = useProfile()[0].value;
  useEffect(() => {
    const allowedModes = [Mode.Create, Mode.Edit];

    const textarea = textareaRef.current;
    if (textarea && allowedModes.includes(mode)) {
      const end = textarea.value.length;
      textarea.setSelectionRange(end, end);
      textarea.focus();
    }
  }, [mode]);

  const isEditingOrDeletingNote = useIsEditingOrDeletingCompanyNote(id);
  const {
    errors,
    setValue,
    value: newText,
    ...rest
  } = useTextArea({
    errorRules: [getValidateMaxChars(MAX_CHARS)],
    initialValue: text,
    placeholder: 'Enter your note text',
  });
  const hasError = !!errors.length;
  const isUpdateDisabled =
    mode === Mode.Edit && newText === text && newPriority === priority;
  return (
    <div
      className={classNames(className, styles.noteInputContainer, 'relative')}
    >
      <p className={styles.title}>
        {mode === Mode.Create ? 'Add' : 'Edit'} Note
      </p>
      <div>
        <div className="flex gap-1">
          <p className={styles.formInputLabel}>Select Priority</p>
          <RequiredStar variant="bodyMedium" />
        </div>
        <div className={styles.priorityContainer}>
          {Object.keys(NOTES_PRIORITY)
            .reverse()
            .map((priorityNumber, idx) => {
              const isLast = idx === Object.keys(NOTES_PRIORITY).length - 1;
              const isFirst = idx === 0;
              const isSelected = newPriority === Number(priorityNumber);
              return (
                <Fragment key={priorityNumber}>
                  <InternalNotePriority
                    isSelect
                    priority={Number(priorityNumber) as NotePriorityKeys}
                    withLabelPriority={false}
                    onClick={setNewPriority}
                    className={classNames(
                      'flex w-[25%] min-w-[80px] grow cursor-pointer justify-center py-2',
                      {
                        'ml-1': isFirst && isSelected,
                        'mr-1': isLast && isSelected,
                      },
                      styles.robotoLabel,
                      isSelected
                        ? styles.selectedPriority
                        : styles.notSelectedPriority
                    )}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
      <div className="flex gap-1">
        <p className={classNames('pb-2', styles.formInputLabel)}>Note</p>
        <RequiredStar variant="bodyMedium" />
      </div>
      <TextArea
        className="mb-2"
        ref={textareaRef}
        {...{ errors, hasError, setValue, value: newText, ...rest }}
      />

      {mode === Mode.Edit && (
        <Typography
          variant="bodySmall"
          color="charcoalGray"
          className={styles.mb8}
        >
          Note added by{' '}
          <span className={classNames(styles.bold, 'max-w-full break-words')}>
            {author}
          </span>
          , {time}
        </Typography>
      )}
      <div className={styles.flexBetween}>
        <div className={styles.buttons}>
          <Button
            disabled={!newText.trim().length || hasError || isUpdateDisabled}
            onClick={() =>
              onSubmit({
                id,
                priority: newPriority!,
                text: newText,
              })
            }
          >
            {mode === Mode.Create ? 'Add Note' : 'Update Note'}
          </Button>

          <Button variant="text" onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
        {onDelete && (
          <Button
            variant="text"
            className={styles.deleteBtn}
            onClick={() => onDelete()}
            customPadding
          >
            <DeleteSvg color={palette['midnight-black']} />
            Delete Note
          </Button>
        )}
      </div>
      {isEditingOrDeletingNote && <NoteSpinnerOverlay />}
    </div>
  );
};
export default NoteInput;
