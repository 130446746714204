import always from 'ramda/es/always';
import compose from 'ramda/es/compose';
import concat from 'ramda/es/concat';
import fromPairs from 'ramda/es/fromPairs';
import ifElse from 'ramda/es/ifElse';
import isEmpty from 'ramda/es/isEmpty';
import join from 'ramda/es/join';
import last from 'ramda/es/last';
import map from 'ramda/es/map';
import split from 'ramda/es/split';
import toPairs from 'ramda/es/toPairs';

export const parse: (v: string) => { [key: string]: string } = ifElse(
  isEmpty,
  always({}),
  compose<any, any, any, any, any, any>(
    fromPairs,
    map(split('=')),
    split('&'),
    last,
    split('?')
  )
);

export const stringify = ifElse(
  isEmpty,
  () => '',
  compose(concat('?'), join('&'), map(join('=')), toPairs)
);

export function removeURLParameter(url: any, parameter: any) {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    const pars = urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function addUrlParameter(url: string, parameter: string, value: string) {
  const resultUrl = new URL(url);
  resultUrl.searchParams.append(parameter, value);
  return resultUrl.href.replaceAll('+', '%20');
}

export function buildQueryFromObject(queryObj: any) {
  return Object.entries(queryObj)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .filter(Boolean)
    .join('&');
}
