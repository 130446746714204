import { isEmpty } from 'ramda';
import { FC, useState } from 'react';

import SearchInput from 'components/common/SearchInput/SearchInput';
import Table from 'components/common/Table/Table';
import { travellerTableColumns } from 'constants/travellersTable';
import { useProfile } from 'hooks/profile';
import { useFetchTravellers } from 'hooks/travellers';

import Loader from 'components/common/Loader';
import Typography from 'components/common/Typography/Typography';
import { useInfiniteLoadTravellers } from 'hooks/general';
import styles from './Travellers.module.scss';

const EmptyTravellersSearchResults = () => {
  return (
    <Typography variant="bodyLarge" color="grey-6" className="mt-2 text-center">
      No travellers found matching search criteria.
    </Typography>
  );
};

export const MAX_SEARCH_PAGES = 10;

const Travellers: FC = () => {
  const profile = useProfile()[0].value;

  const [searchPattern, setSearchPattern] = useState('');
  const {
    currentPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    travellers,
  } = useFetchTravellers({
    guid: profile.guid,
    searchPattern,
  });
  const { ref } = useInfiniteLoadTravellers({
    fetchNextPage,
    guid: profile.guid,
  });

  if (!profile) return null;

  const showNoData = isEmpty(travellers) && !isFetching;
  const renderRef = !isFetching && hasNextPage;

  return (
    <>
      <SearchInput
        className="mt-5"
        placeholder="Type to search for a traveller"
        onSearch={(value) => setSearchPattern(value)}
        resetSearch={() => setSearchPattern('')}
      />
      {isFetching && !isFetchingNextPage ? (
        <div className={styles.spinner}>
          <Loader e2e="travellers-list" />
        </div>
      ) : showNoData ? (
        <EmptyTravellersSearchResults />
      ) : (
        <>
          <Table columns={travellerTableColumns} data={travellers || []} />
          {isFetchingNextPage && (
            <div className={styles.spinner}>
              <Loader e2e="travellers-list" />
            </div>
          )}
        </>
      )}
      {renderRef && <div ref={ref} />}
      {currentPage === MAX_SEARCH_PAGES && (
        <Typography
          variant="bodyLarge"
          color="grey-6"
          className="mt-2 text-center"
        >
          Please narrow search input for more results.
        </Typography>
      )}
    </>
  );
};

export default Travellers;
