import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { is, isEmpty, toPairs } from 'ramda';
import { FC } from 'react';

import CopyButton from 'components/common/CopyButton/CopyButton';
import Car from 'components/common/Icons/Car';
import Hotel from 'components/common/Icons/Hotel';
import Plane from 'components/common/Icons/Plane';
import Train from 'components/common/Icons/Train';
import Line from 'components/common/Line/Line';
import Tabs from 'components/common/Tabs/Tabs';
import { isComponentPageAtom } from 'state/store';
import { ReactComponent as CheckSvg } from 'styles/icons/check.svg';
import MembPreferences from 'styles/icons/memb_preferences.svg';
import palette from 'styles/palette';
import { sendGoogleEvent } from 'utils/googleTag';

import HelpTooltip from 'components/common/HelpTooltip';
import { useProfile } from 'hooks/profile';
import styles from './Preferences.module.scss';

const checkHasPreferences = (preferences: any) => {
  if (isEmpty(preferences)) {
    return false;
  }
  return !toPairs(preferences).every(
    ([, value]) => value === false || value === undefined
  );
};

const checkHasMemberships = (memberships: any) => {
  if (!memberships) return false;
  return !isEmpty(memberships);
};

const Preferences: FC = () => {
  const isComponentPage = useAtomValue(isComponentPageAtom);
  const profile = useProfile()[0].value;

  const sectionsData = [
    { key: 'H', preferences: profile.hotelPreferences, sectionName: 'Hotel' },
    { key: 'A', preferences: profile.airPreferences, sectionName: 'Air' },
    { key: 'C', preferences: profile.carPreferences, sectionName: 'Car' },
    { key: 'R', preferences: profile.railPreferences, sectionName: 'Train' },
  ];

  const result = sectionsData.map(({ key, preferences, sectionName }) => {
    const membershipNumbers = profile.membershipNumbers[key];
    const hasMembershipNumbers = checkHasMemberships(membershipNumbers);
    const hasPreferences = checkHasPreferences(preferences);

    return hasMembershipNumbers || hasPreferences ? (
      <Sections
        preferences={preferences}
        membership={membershipNumbers}
        hasMemberships={hasMembershipNumbers}
        hasPreferences={hasPreferences}
      />
    ) : (
      <EmptySection section={sectionName} />
    );
  });
  const tabs = [
    {
      Content: result[0],
      icon: <Hotel />,
      iconActive: <Hotel fill={palette['deep-green']} />,
      index: 0,
      title: 'Hotel',
    },
    {
      Content: result[1],
      icon: <Plane />,
      iconActive: <Plane fill={palette['deep-green']} />,
      index: 1,
      title: 'Air',
    },
    {
      Content: result[2],
      icon: <Car />,
      iconActive: <Car fill={palette['deep-green']} />,
      index: 2,
      title: 'Car',
    },
    {
      Content: result[3],
      icon: <Train />,
      iconActive: <Train fill={palette['deep-green']} />,
      index: 3,
      title: 'Train',
    },
  ];

  const trackTabChangedGtm = (index: number) => {
    const tabName = tabs.find((tab) => tab.index === index)?.title;
    if (!tabName) return;
    sendGoogleEvent('preference_tab_select', { preference_tab: tabName });
  };

  return (
    <div className={classNames(styles.container, !isComponentPage && 'p-5')}>
      {!isComponentPage && (
        <div
          className={classNames(styles.header, 'flex w-fit items-center gap-1')}
          id="trendsAndPreferencesHeading"
        >
          <div>Trends and Preferences</div>
          <HelpTooltip
            heading="Trends and Preferences"
            description="This section would provide trends data of the customer and preferences gathered in Agentport, also you would have the memberships relation for each type of travel."
          />
        </div>
      )}
      <div
        className={classNames(styles.tabsContainer, !isComponentPage && 'mt-3')}
      >
        <Tabs tabs={tabs} onActiveChange={trackTabChangedGtm} />
      </div>
    </div>
  );
};

interface ISectionsProps {
  preferences: { [key: string]: any };
  membership: { label: string; value: string | number }[];
  hasPreferences: Boolean;
  hasMemberships: Boolean;
}

const Sections: FC<ISectionsProps> = ({
  hasMemberships,
  hasPreferences,
  membership,
  preferences,
}) => {
  const renderPreferences = () =>
    hasPreferences && (
      <div className={styles.preferencesContainer}>
        <>
          <div className={styles.heading}>
            <span className={styles.headingContent}>Preferences</span>
          </div>
          {toPairs(preferences).map(([label, value]: any) => {
            return value ? (
              <Line key={label}>
                <span>{label}</span>
                <span>
                  {is(Boolean, value) ? <CheckSvg color="black" /> : value}
                </span>
              </Line>
            ) : null;
          })}
        </>
      </div>
    );

  const renderMembership = () =>
    hasMemberships && (
      <div className={styles.membershipContainer}>
        <div className={styles.heading}>
          <span className={styles.headingContent}>Membership</span>
        </div>
        {membership.map((membership: any) => {
          return (
            <Line key={membership.value}>
              <span>{membership.label}</span>
              <div className={styles.inlineIcon}>
                <span className="break-all text-right">{membership.value}</span>
                <CopyButton
                  value={membership.value}
                  className={classNames('ml-[6px] h-[13px] w-[13px]')}
                />
              </div>
            </Line>
          );
        })}
      </div>
    );

  return (
    <div className={styles.mt4}>
      {renderPreferences()}
      {renderMembership()}
    </div>
  );
};

interface IEmptySectionProps {
  section: string;
}

const EmptySection: FC<IEmptySectionProps> = ({ section }) => {
  return (
    <div className={styles.emptyContainer}>
      <div>
        <img src={MembPreferences} alt="member preferences" />
      </div>
      <div className={styles.text}>
        <p className={styles.main}>{section} trends and preferences</p>
        <p className={styles.sub}>have not been added yet</p>
      </div>
    </div>
  );
};

export default Preferences;
