import Car from 'components/common/Icons/Car';
import Hotel from 'components/common/Icons/Hotel';
import Plane from 'components/common/Icons/Plane';
import Train from 'components/common/Icons/Train';
import { BookingType } from 'types/trips';

export const iconMap = {
  [BookingType.Car]: <Car data-e2e-icon="Car" className="w-5" />,
  [BookingType.Flight]: <Plane data-e2e-icon="Air" className="w-5" />,
  [BookingType.Hotel]: <Hotel data-e2e-icon="Hotel" className="w-5" />,
  [BookingType.Train]: <Train data-e2e-icon="Train" className="w-5" />,
};

export const iconMap2 = {
  Car: <Car data-e2e-icon="Car" className="w-5" />,
  Flight: <Plane data-e2e-icon="Air" className="w-5" />,
  Hotel: <Hotel data-e2e-icon="Hotel" className="w-5" />,
  Train: <Train data-e2e-icon="Train" className="w-5" />,
};
