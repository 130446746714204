import { useFutureAndPastTrips } from 'hooks/trips';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isAuthedAtom, profileAtom } from 'state/store';
import { QueryKey, apiService } from 'utils/apiService';
import OnboardingTooltip from './OnboardingTooltip/OnboardingToooltip';
import { useProfile } from 'hooks/profile';
const useDisableScroll = (data: number | null | undefined) => {
  useEffect(() => {
    const body = document.querySelector('body')?.classList;
    if (!body) return;
    if (data === undefined || data === null) {
      body.remove('disableScroll');
    } else {
      if (body?.contains('disableScroll')) return;
      body.add('disableScroll');
    }
  }, [data]);
};
export const useOnboarding = () => {
  const getBoundingClientRect = (selector: string) => {
    return (
      document.getElementById(selector)?.getBoundingClientRect() || {
        left: 0,
        top: 0,
        width: 0,
      }
    );
  };
  const internalNotesHeading = getBoundingClientRect('internalNotesHeading');
  const tripsSummaryHeading = getBoundingClientRect('tripsSummaryHeading');
  const trendsAndPreferencesHeading = getBoundingClientRect(
    'trendsAndPreferencesHeading'
  );
  const onboardingSteps = [
    {
      arrowClassName: 'left absolute top-[-4px] left-[10px]',
      className: ' left-[11px] top-[39px] w-[320px]',
      description:
        'Welcome to Super Profile. This is the initial point when finding a profile. Here you’ll have the contact phone, email and company related to the profile you’ve found.',
      heading: 'Contact Details',
      id: 'WLCM',
    },
    {
      arrowClassName: 'left absolute top-[-4px] left-[12px]',
      className: ' left-[20px] top-[100px] w-[320px]',
      description:
        'Super Profile provides the information of the roles for each profile, here you would have the relation if it’s a traveller or an arranger and if the traveller profile is associated with an arranger.',
      heading: 'Traveller and Travel Arranger',
      id: 'TRVL',
    },
    {
      arrowClassName: 'left absolute top-[10px] left-[-4px]',
      className: ' flex left-[250px] top-[193px] w-[320px]',
      description:
        'Navigate through here to copy, authenticate and search for the data related to names, phones, emails, Office ID, GDS profile locator, or others related to the customer profile. This information is synced with Agentport and when hovering your mouse close to the “+1” clouds in mobile or email, Superprofile would show you the additional contact points of the customer.',
      heading: 'Profile Details',
      id: 'PRFL',
    },
    {
      arrowClassName: 'left absolute top-[12px] left-[-4px]',
      className: ' flex w-[312px]',
      description:
        'In this section you can see the FCMplatform – OBT booked trips of the customer, our view would have the next trips and the past trips (Already travelled), clicking here you would have a full view of the trip of the customer with the hotel, flight, train and car details.',
      heading: 'Trips Summary',
      id: 'TRPS',
      style: {
        left: `${
          (tripsSummaryHeading.left +
            tripsSummaryHeading.width +
            window.scrollX || 0) + 10
        }px`,
        top: `${tripsSummaryHeading.top + window.scrollY - 7}px`,
      },
    },
    {
      arrowClassName: 'left absolute top-[12px] left-[-4px]',
      className: ' flex lg:w-[312px] w-[264px]',
      description:
        'This section would provide trends data of the customer and preferences gathered in Agentport, also you would have the memberships relation for each type of travel.',
      heading: 'Trends and Preferences',
      id: 'TRND',
      style: {
        left: `${
          (trendsAndPreferencesHeading.left +
            trendsAndPreferencesHeading.width +
            window.scrollX || 0) + 10
        }px`,
        top: `${trendsAndPreferencesHeading.top + window.scrollY - 8}px`,
      },
    },
    {
      arrowClassName: 'left absolute top-[12px] left-[-4px]',
      className: ` flex w-[312px]`,
      description:
        'Please leave here any characteristics of your customer that connects us to them with empathy for example: “Travelling with a dog”, “He lives in New York”, “Has 3 kids and one new born”, “He doesn’t like to fly with American airlines”. This information would be seen by any other travel consultant searching this profile.',
      heading: 'Internal Notes',
      id: 'INNT',
      style: {
        left: `${
          (internalNotesHeading.left +
            internalNotesHeading.width +
            window.scrollX || 0) + 30
        }px`,
        top: `${internalNotesHeading.top + window.scrollY - 8}px`,
      },
    },
  ];
  const profile = useProfile()[0].value;
  const isAuthed = useAtomValue(isAuthedAtom);

  const { isFetched: tripsFetched } = useFutureAndPastTrips(profile.guid);
  const { data: onboardingStepIndex } = useQuery({
    enabled: tripsFetched && isAuthed,
    queryFn: async () => {
      const data = await apiService<{ steps: string[] }>({
        key: [QueryKey.Onboarding],
        method: 'get',
        url: '/onboarding/steps',
      });
      if (data.steps.includes(onboardingSteps[onboardingSteps.length - 1].id)) {
        return null;
      }
      return onboardingSteps.findIndex((v) => !data.steps.includes(v.id)) || 0;
    },

    queryKey: ['getOnboarding'],
  });
  useDisableScroll(onboardingStepIndex);

  return { onboardingStepIndex, onboardingSteps };
};

const Onboarding = () => {
  const { onboardingStepIndex, onboardingSteps } = useOnboarding();
  if (onboardingStepIndex === undefined || onboardingStepIndex === null)
    return null;
  return (
    <div className="absolute left-0 top-0 z-50 h-full w-full">
      <OnboardingTooltip
        {...onboardingSteps[onboardingStepIndex]}
        step={onboardingStepIndex + 1}
      />
    </div>
  );
};
export default Onboarding;
