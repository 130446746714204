import { FC } from 'react';

import { ReactComponent as SuccessSvg } from 'styles/icons/check.svg';
import { ReactComponent as InfoSvg } from 'styles/icons/info.svg';
import { ReactComponent as WarningSvg } from 'styles/icons/warning.svg';
import palette from 'styles/palette';

import { cva, VariantProps } from 'cva';
import styles from './Alert.module.scss';

const style = cva(styles.container, {
  compoundVariants: [
    {
      className: 'bg-ui-red-lighten',
      type: 'error',
      variant: 'filled',
    },
    {
      className: 'bg-ui-orange-lighten',
      type: 'warning',
      variant: 'filled',
    },
    {
      className: 'bg-ui-blue-lighten',
      type: 'info',
      variant: 'filled',
    },
    {
      className: 'bg-ui-green-lighten',
      type: 'success',
      variant: 'filled',
    },
  ],
  variants: {
    type: {
      error: styles.errorContainer,
      info: styles.infoContainer,
      success: styles.successContainer,
      warning: styles.warningContainer,
    },
    variant: {
      default: 'bg-clarity-white',
      filled: 'border-0 pt-[10px] pl-3 [&>svg]:mt-[2px]',
    },
  },
});

export type AlertComponentType = 'warning' | 'error' | 'info' | 'success';

interface AlertProps extends VariantProps<typeof style> {
  type: AlertComponentType;
  className?: string;
  iconSize?: number;
  children: any;
}

const Alert: FC<AlertProps> = ({
  children,
  className,
  iconSize = 20,
  type,
  variant = 'default',
}) => {
  const icon = {
    error: (
      <InfoSvg
        className="shrink-0"
        data-e2e-icon="error"
        color={palette['ui-red']}
        width={iconSize}
        height={iconSize}
      />
    ),
    info: (
      <InfoSvg
        className="shrink-0"
        data-e2e-icon="info"
        color={palette['ui-blue-darken']}
        width={iconSize}
        height={iconSize}
      />
    ),
    success: (
      <SuccessSvg
        className="shrink-0"
        data-e2e-icon="success"
        color={palette['ui-green']}
        width={iconSize}
        height={iconSize}
      />
    ),
    warning: (
      <WarningSvg
        className="shrink-0"
        data-e2e-icon="warning"
        color={palette['ui-orange']}
        width={iconSize}
        height={iconSize}
      />
    ),
  };
  return (
    <div className={style({ type, variant })}>
      {icon[type]}
      <div className={className}>{children}</div>
    </div>
  );
};

export default Alert;
