import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import palette from 'styles/palette';
import { Company } from 'types/profile';
import Button from '../Button/Button';
import Input from '../Input';
import Typography from '../Typography/Typography';
import CompanyFilterCheckbox from './CompanyFilterCheckbox';
import { SearchCompanyFiltersProps } from './SearchCompanyFilters';

import { useOnClickOutside } from 'hooks/general';
import { ReactComponent as RotateSvg } from 'styles/icons/rotate.svg';
import { ReactComponent as SearchSvg } from 'styles/icons/search.svg';

interface IFilterDialogProps extends SearchCompanyFiltersProps {
  closeFilterDialog: () => void;
  filterEnabled: boolean;
}
const FilterDialog: FC<IFilterDialogProps> = ({
  closeFilterDialog,
  companies,
  filterEnabled,
  selectedCompanies,
  setSelectedCompanies,
}) => {
  const [localSelectedCompanies, setLocalSelectedCompanies] = useState<
    Company[]
  >([]);
  const [filterValue, setFilterValue] = useState('');
  const [touched, setTouched] = useState(false);
  const filterDialogRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const filteredCompanies = companies.filter(
    (company) =>
      company.Name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
      company.PseudoCity.toLowerCase().includes(
        filterValue.trim().toLowerCase()
      )
  );

  useEffect(() => {
    setLocalSelectedCompanies([...selectedCompanies]);
  }, []);

  useOnClickOutside(filterDialogRef, (e: MouseEvent) => {
    const manageFilterButton = document.getElementById('manageFilterButton');
    if (
      manageFilterButton?.contains(e?.target as Node) ||
      e.target === manageFilterButton
    ) {
      return;
    }
    closeFilterDialog();
  });

  return (
    <div
      ref={filterDialogRef}
      className="h-[280px] shadow-[0px_4px_12px_0px_#636F7A29] w-[320px] absolute top-10 right-0 bg-clarity-white p-4 z-10 flex flex-col gap-3 "
    >
      <div className="flex relative items-center">
        <SearchSvg
          width={16}
          color={filterValue ? palette['deep-green'] : palette['grey-5']}
          className="absolute left-2"
        />
        <Input
          className="w-full pr-8 pl-8"
          value={filterValue}
          setValue={setFilterValue}
          placeholder="Type to search"
          errors={[]}
          initialValue={undefined}
          ref={inputRef}
        />
        {filterValue && (
          <Button
            variant="text"
            customPadding
            onClick={() => {
              setFilterValue('');
              inputRef.current?.focus();
            }}
            className="absolute right-2 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center"
          >
            <CloseSvg height={12} width={12} color={palette['grey-5']} />
          </Button>
        )}
      </div>
      {filteredCompanies.length ? (
        <div className="flex flex-col gap-1                                  ">
          <div className="flex justify-between pr-[25px]">
            <Typography variant="bodySmall2" color="charcoalGray">
              Company Name
            </Typography>
            <Typography variant="bodySmall2" color="charcoalGray">
              PCC
            </Typography>
          </div>
          <div className="h-[132px] pr-[6px] flex flex-col gap-2 overflow-auto">
            {filteredCompanies.map((company) => (
              <CompanyFilterCheckbox
                key={`${company.PseudoCity} - ${company.Name}`}
                {...{
                  companies: localSelectedCompanies,
                  company,
                  onChange: (checked: boolean) => {
                    const companyId = localSelectedCompanies.findIndex(
                      (v) =>
                        v.PseudoCity === company.PseudoCity &&
                        v.Name === company.Name
                    );
                    setTouched(true);
                    if (!checked) {
                      localSelectedCompanies.splice(companyId, 1);
                      setLocalSelectedCompanies([...localSelectedCompanies]);
                      return;
                    }
                    setLocalSelectedCompanies([
                      ...localSelectedCompanies,
                      company,
                    ]);
                  },
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <Typography variant="bodyMedium" color="charcoalGray" className="grow">
          No matches found for your request, please update search input or
          contact support.
        </Typography>
      )}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Button
            variant="primary"
            disabled={!touched}
            onClick={() => {
              setSelectedCompanies([...localSelectedCompanies]);
              closeFilterDialog();
            }}
          >
            Apply Filter
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              closeFilterDialog();
            }}
          >
            Cancel
          </Button>
        </div>
        {filterEnabled && (
          <Button
            variant="text"
            className="flex gap-1 items-center"
            disabled={!filterEnabled}
            color={palette['grey-4']}
            onClick={() => {
              setSelectedCompanies([]);
              closeFilterDialog();
            }}
          >
            <RotateSvg />
            Clear
          </Button>
        )}
      </div>
    </div>
  );
};
export default FilterDialog;
