import classNames from 'classnames';
import EstimatedDateInfo from 'components/pages/DetailedTrips/components/AirSection/EstimatedDateInfo';
import { FC } from 'react';
import ElementWithTooltip from './ElementWithTooltip';
import Typography from './Typography/Typography';

const FlightDate: FC<{
  date: string;
  showPreciseDate: boolean;
  preciseDate: string;
  cityCode: string;
  className: string;
  type: 'detailedTrips' | 'profileTrips';
}> = ({ cityCode, className, date, preciseDate, showPreciseDate, type }) => {
  if (type === 'detailedTrips') {
    return (
      <div className={className}>
        <div className="flex items-center gap-1">
          <ElementWithTooltip value={date}>
            <Typography
              variant="bodyMedium"
              data-e2e-flight_summary_departure_date
            />
          </ElementWithTooltip>

          {showPreciseDate && (
            <EstimatedDateInfo
              value={preciseDate}
              data-e2e-flight_summary_departure_local_date
              className="self-center"
            />
          )}
        </div>
        <Typography variant="bodyMedium2">{cityCode}</Typography>
      </div>
    );
  }
  return (
    <div className={classNames(className)}>
      <div className="flex items-center gap-1">
        <ElementWithTooltip value={date}>
          <Typography
            variant="bodySmall"
            data-e2e-flight_summary_departure_date
            color="charcoal-grey"
          />
        </ElementWithTooltip>
        {showPreciseDate && (
          <EstimatedDateInfo
            value={preciseDate}
            data-e2e-flight_summary_departure_local_date
            className="self-center"
          />
        )}
      </div>
      <ElementWithTooltip value={cityCode}>
        <Typography variant="bodySmall" />
      </ElementWithTooltip>
    </div>
  );
};
export default FlightDate;
