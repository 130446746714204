import { Strategy } from '@floating-ui/react';
import {
  ControlledPopper,
  useControlledPopper,
} from 'components/common/Popper/Popper';
import Typography, {
  TypographyProps,
} from 'components/common/Typography/Typography';
import { inTS } from 'components/pages/Starting/Starting';
import {
  BREAKPOINTS,
  PAGES_WITHOUT_AGENT_DATA,
  PAGES_WITH_SEARCH,
} from 'constants/general';
import debounce from 'debounce';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  ReactElement,
  RefObject,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import {
  useMutation,
  useQuery,
  QueryKey as TQueryKey,
} from '@tanstack/react-query';
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  isAuthedAtom,
  prevLocationsAtom,
  prevRouteAtom,
  requireCallerAuthAtom,
  searchAtom,
} from 'state/store';

import { ISearchAPI, ISearchEntity } from 'types/search';
import { useMediaQuery } from 'usehooks-ts';
import { QueryKey, apiService, isAxiosError } from 'utils/apiService';
import { getSectionFromUrl, pushTravellersOrProfilePage } from 'utils/common';
import { isBiggerThenContainer } from 'utils/format';
import { removeURLParameter, stringify } from 'utils/qs';
import classNames from 'classnames';

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Function
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useInfiniteLoadTravellers = ({
  fetchNextPage,
  guid,
}: {
  guid: string;
  fetchNextPage: () => void;
}) => {
  const { inView, ref } = useInView();

  useEffect(() => {
    if (inView && guid) {
      fetchNextPage();
    }
  }, [inView, guid]);
  return { ref };
};

export const useMinLoadingTime = (time: number = 1000) => {
  const [minLoadingDone, setMinLoadingDone] = useState(false);
  const timeoutId = useRef<number>();
  useEffect(() => {
    timeoutId.current = window.setTimeout(() => {
      setMinLoadingDone(true);
    }, time);
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);
  return [minLoadingDone, setMinLoadingDone];
};

const useSearchShortcuts = (
  results: ISearchEntity[],
  options: {
    resetSearch: () => void;
    onEnter: (search: ISearchEntity) => void;
  }
) => {
  const [selectedItem, setSelectedItem] = useState<number | undefined>(
    undefined
  );
  const handleSetSelectedItem = (i: number | undefined) => {
    setSelectedItem(i);
    if (i === undefined) return;
    document.querySelector(`[data-search-row-index='${i}']`)?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    });
  };

  const handleShortcuts = useCallback(
    (event: KeyboardEvent) => {
      const functionsMap = {
        ArrowDown: () => {
          if (selectedItem !== undefined && selectedItem < results.length - 1) {
            handleSetSelectedItem(selectedItem + 1);
            return;
          }
          handleSetSelectedItem(0);
        },
        ArrowUp: () => {
          if (selectedItem !== undefined && selectedItem > 0) {
            handleSetSelectedItem(selectedItem - 1);
            return;
          }
          handleSetSelectedItem(results.length - 1);
        },
        Enter: () => {
          if (selectedItem === undefined) return;
          options.onEnter(results[selectedItem]);
        },
        Escape: () => {
          options.resetSearch();
        },
      };
      const key = event.key;
      if (!inTS(key, functionsMap)) return;
      const functionToCall = functionsMap[key];
      if (!functionToCall) return;
      event.preventDefault();
      functionToCall();
    },
    [selectedItem]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleShortcuts);

    return () => {
      document.removeEventListener('keydown', handleShortcuts);
    };
  }, [handleShortcuts]);

  return { handleSetSelectedItem, selectedItem, setSelectedItem };
};

export const useSearchPage = ({
  mapResults,
  onChange,
  onEnter,
}: {
  onChange?: (value: string) => void;
  onEnter: (search: ISearchEntity) => void;
  mapResults?: (results: ISearchEntity[]) => ISearchEntity[];
}) => {
  const location = useLocation();
  const dispatchSearch = useSetAtom(searchAtom);
  const query = useRouterQuery();
  const queryRaw = useRawRouterQuery();
  const navigate = useNavigate();
  const changeQueryParams = useCallback(
    debounce((queryString: any) => {
      navigate({
        pathname: location.pathname,
        search: queryString,
      });
    }, 300),
    []
  );

  const resetResults = () => dispatchSearch({ type: 'RESET' });
  const { mutate: startSearch } = useSearch();
  const {
    handleChange,
    results,
    search,
    searchIsPending,
    setMessage,
    setTerm,
    term,
    ...rest
  } = useSimpleSearch({
    mapResults,
    onChange: (value) => {
      const newQuery = {
        ...queryRaw,
      };
      if (value) {
        newQuery.searchTerm = encodeURIComponent(value);
      } else {
        delete newQuery.searchTerm;
      }
      changeQueryParams(stringify(newQuery));
      if (onChange) {
        onChange(value);
      }
      handleSetSelectedItem(undefined);
    },
    onSearch: (value) => {
      startSearch({
        key: [QueryKey.Search, value, 'Main'],
        searchPattern: value,
        searchType: 'Main',
      });
    },
    resetSearch: resetResults,
  });

  useEffect(() => {
    const searchTerm = query.searchTerm;
    if (!searchTerm) return;
    setTerm(searchTerm);
  }, []);

  useEffect(() => {
    if (term.length < 3 && term.length > 0) {
      setMessage('3 characters required to initiate search');
    }
    if (term.length !== 0 || !results.length) return;
    resetResults();
  }, [results, term]);

  useEffect(() => {
    if (term.length === 0) setMessage('');
    if (term.length < 3) return;
    setMessage('');
    dispatchSearch({ type: 'LOADING' });
    search(term);
  }, [term]);

  const { handleSetSelectedItem, selectedItem, setSelectedItem } =
    useSearchShortcuts(results, {
      onEnter,
      resetSearch: () => handleChange(''),
    });
  return {
    ...rest,
    handleChange,
    handleSetSelectedItem,
    results,
    search,
    searchIsPending,
    selectedItem,
    setSelectedItem,
    setTerm,
    term,
  };
};

export const useSearchInput = (values: {
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  resetSearch: () => void;
}) => {
  const { search, term, ...rest } = useSimpleSearch(values);
  useEffect(() => {
    search(term);
  }, [term]);
  return { search, term, ...rest };
};

export const useSimpleSearch = ({
  mapResults,
  onChange,
  onSearch,
  resetSearch,
}: {
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  resetSearch: () => void;
  mapResults?: (results: ISearchEntity[]) => ISearchEntity[];
}) => {
  const [term, setTerm] = useState('');
  const [message, setMessage] = useState('');
  const search = useAtomValue(searchAtom);
  const searchIsPending = search.loading;
  const results = search.value?.Result || [];
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch(value);
    }, 200),
    [onSearch]
  );
  const navigate = useNavigate();

  useEffect(() => {
    const status = search.error?.request?.status;
    switch (status) {
      case 400:
      case 404:
        setMessage(
          'No matches found, please update search input or contact support.'
        );
        break;
      case 403:
        navigate('/authorization-error');
        break;
    }
  }, [search.error]);

  const handleChange = (value: string) => {
    setTerm(value);

    if (onChange) {
      onChange(value);
    }
  };
  useEffect(() => {
    inputRef?.current?.focus();
    return () => {
      resetSearch();
    };
  }, []);
  const searchIsFulfilled = search.loading && !search.error;
  useEffect(() => {
    if (searchIsFulfilled || results.length || term.length < 3) {
      return;
    }
    setMessage(
      'No matches found, please update search input or contact support.'
    );
  }, [searchIsFulfilled, results, term]);

  return {
    handleChange,
    inputRef,
    message,
    results: mapResults ? mapResults(results) : results,
    search: debouncedSearch,
    searchIsFulfilled,
    searchIsPending,
    setMessage,
    setTerm,
    term,
  };
};

export const useSearchRowClick = () => {
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const navigate = useNavigate();
  const handleClick = (id: string) => {
    pushTravellersOrProfilePage({
      id,
      navigate,
      requireCallerAuth,
    });
  };
  return { handleClick };
};

// const useOverflowTooltipOld = <T extends HTMLElement | null>(
//   value: string,
//   children: any,
//   ref: RefObject<HTMLElement>
// ) => {
//   const { isOpen, setIsOpen } = useControlledPopper();
//   const [showTooltip, setShowTooltip] = useState(false);

//   const timeoutId = useRef<number>();
//   useEffect(() => {
//     setShowTooltip(isBiggerThenContainer(ref));
//   }, [value]);

//   const openWithTimeout = () => {
//     timeoutId.current = window.setTimeout(() => {
//       setIsOpen(true);
//     }, 500);
//   };

//   if (!showTooltip) {
//     return { withOverflowTooltip: children };
//   }
//   return {
//     withOverflowTooltip: (
//       <ControlledPopper
//         onMouseLeave={() => {
//           clearInterval(timeoutId.current);
//           setIsOpen(false);
//         }}
//         onMouseEnter={openWithTimeout}
//         setOpen={() => {}}
//         open={isOpen}
//         className="max-w-[400px]"
//         content={
//           <Typography
//             className="wrap min-w-[14ch]"
//             variant="bodyMedium"
//             color="clarity-white"
//           >
//             {value}
//           </Typography>
//         }
//         trigger="hover"
//         placement="top"
//         strategy="absolute"
//         controlledArrow
//         type="tooltip"
//       >
//         {children}
//       </ControlledPopper>
//     ),
//   };
// };

export const useOverflowTooltip = (
  value: string,
  children: ReactElement,
  shouldReplaceChildren: boolean = true,
  strategy: Strategy = 'absolute',
  variant: TypographyProps['variant'] = 'bodyMedium',
  className: string = ''
) => {
  const { isOpen, setIsOpen } = useControlledPopper();
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);
  const args: [
    ReactElement,
    { ref: RefObject<HTMLElement>; className: string },
    string
  ] = [
    children,
    {
      className: (children?.props?.className || '') + ' textWrap',
      ref,
    },
    value,
  ];
  if (!shouldReplaceChildren) {
    args.pop();
  }
  const element = cloneElement(...args);
  const timeoutId = useRef<number>();
  useEffect(() => {
    setShowTooltip(isBiggerThenContainer(ref));
  }, [value]);

  const openWithTimeout = () => {
    timeoutId.current = window.setTimeout(() => {
      setIsOpen(true);
    }, 500);
  };

  if (!showTooltip) {
    return { withOverflowTooltip: element };
  }
  return {
    withOverflowTooltip: (
      <ControlledPopper
        onMouseLeave={() => {
          clearInterval(timeoutId.current);
          setIsOpen(false);
        }}
        onMouseEnter={openWithTimeout}
        setOpen={() => {}}
        open={isOpen}
        className="max-w-[400px]"
        content={
          <Typography
            className={classNames('wrap min-w-[14ch]', className)}
            variant={variant}
            color="clarity-white"
          >
            {value}
          </Typography>
        }
        trigger="hover"
        placement="top"
        strategy={strategy}
        controlledArrow
        type="tooltip"
      >
        {element}
      </ControlledPopper>
    ),
  };
};
export const useAgentData = () => {
  interface IAgent {
    Email: string;
    CountryCode: string;
    ContactPrivacyTeamEmail: string;
    IsAgentExistsOnAgentPort: boolean;
  }
  const isAuthed = useAtomValue(isAuthedAtom);
  const currentSection = useCurrentSection();
  const getAgentData = () => {
    return apiService<IAgent>({ method: 'get', url: '/agents/current' });
  };
  const navigate = useNavigate();
  const result = useQuery({
    enabled: isAuthed && !PAGES_WITHOUT_AGENT_DATA.includes(currentSection),
    onSuccess: (v) => {
      if (v?.IsAgentExistsOnAgentPort) return;
      navigate('/authorization-error');
    },

    queryFn: getAgentData,

    queryKey: ['getAgentData'],
  });
  return result;
};
export const useIsSmallScreen = () => {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.MD - 1}px )`);
  return isSmallScreen;
};

export const useRouterQuery = () => Object.fromEntries(useSearchParams()[0]);

export const useRawRouterQuery = () =>
  Object.fromEntries(
    [...useSearchParams()[0]].map(([key, value]) => [
      key,
      encodeURIComponent(value),
    ])
  );

export const useSearch = () => {
  // TODO: make a query instead of mutation
  const dispatchSearch = useSetAtom(searchAtom);
  const navigate = useNavigate();
  const prevSearchRequestAbortController = useRef<AbortController>();
  return {
    mutate: async ({
      key,
      searchPattern,
      searchType,
    }: {
      searchPattern: string;
      searchType:
        | 'Main'
        | 'Name'
        | 'Email'
        | 'Phone'
        | 'ArrangerId'
        | 'EmployeeId';
      key?: TQueryKey;
    }) => {
      if (!searchPattern) {
        dispatchSearch({ type: 'RESET' });
        return;
      }
      if (prevSearchRequestAbortController?.current?.abort) {
        prevSearchRequestAbortController.current.abort();
      }
      const abortController = new AbortController();
      prevSearchRequestAbortController.current = abortController;
      try {
        const response = await apiService<ISearchAPI>({
          key,
          method: 'get',
          signal: abortController.signal,
          url: `/profile?SearchPattern=${encodeURIComponent(
            searchPattern
          )}&SearchType=${searchType}&page=1&size=100`,
        });
        dispatchSearch({ type: 'FULFILLED', value: response });
      } catch (error) {
        if (!isAxiosError(error)) {
          console.log('SEARCH ERROR', error);
          return;
        }
        if (error?.code === 'ERR_CANCELED') {
          return;
        }
        if (error.request.status === 403) {
          navigate('/authorization-error', { replace: true });
          return;
        }
        dispatchSearch({ error, type: 'ERROR' });
      }
    },
  };
};
export const useSavePreviousRoute = () => {
  const location = useLocation();
  const setPrevRoute = useSetAtom(prevRouteAtom);
  const currentLocationRef = useRef<Location | undefined>();
  const [prevLocations, setPrevLocations] = useAtom(prevLocationsAtom);
  const currentSection = getSectionFromUrl(location.pathname);
  const isSearchPageChanges =
    PAGES_WITH_SEARCH.includes(currentSection) &&
    PAGES_WITH_SEARCH.includes(
      getSectionFromUrl(currentLocationRef?.current?.pathname || '')
    );
  useEffect(() => {
    if (currentLocationRef.current) {
      if (isSearchPageChanges) {
        const prevLocationsCopy = [...prevLocations];
        prevLocationsCopy.pop();
        setPrevLocations([...prevLocationsCopy, currentLocationRef.current]);
      } else {
        setPrevRoute(formatPrevLocation(currentLocationRef.current));
        setPrevLocations([...prevLocations, currentLocationRef.current]);
      }
    }

    currentLocationRef.current = location;
  }, [location]);
};

const formatPrevLocation = (location: Location | undefined) => {
  return location
    ? `${location?.pathname}${removeURLParameter(location?.search, 'code')}`
    : '';
};

export const useCurrentSection = () => {
  return getSectionFromUrl(useLocation().pathname);
};
