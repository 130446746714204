import { Strategy } from '@floating-ui/react';
import { useOverflowTooltip } from 'hooks/general';
import { FC, ReactElement } from 'react';
import { TypographyProps } from './Typography/Typography';

const ElementWithTooltip: FC<{
  value: string;
  children: ReactElement;
  shouldReplaceChildren?: boolean;
  strategy?: Strategy;
  variant?: TypographyProps['variant'];
  className?: string;
}> = ({
  children,
  className,
  shouldReplaceChildren = true,
  strategy = 'fixed',
  value,
  variant = 'bodyMedium',
}) => {
  const { withOverflowTooltip } = useOverflowTooltip(
    value,
    children,
    shouldReplaceChildren,
    strategy,
    variant,
    className
  );
  return withOverflowTooltip;
};
export default ElementWithTooltip;
