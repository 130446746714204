import { length } from 'ramda';
import { FC } from 'react';

import Phone from 'styles/icons/phone.svg';

import Typography from '../Typography/Typography';

import { PAGES_TO_SHOW_ASSIGN } from 'constants/general';
import { useCurrentSection } from 'hooks/general';
import { useAtomValue } from 'jotai';
import {
  breadcrumbsAtom,
  phoneAssignmentAtom,
  profileAtom,
  profileSourceAtom,
} from 'state/store';
import styles from './UnrecognisedPhone.module.scss';
import { useProfile } from 'hooks/profile';

const UnrecognisedPhone: FC = () => {
  let phoneAssignment = useAtomValue(phoneAssignmentAtom);
  const source = useAtomValue(profileSourceAtom);
  const currentSection = useCurrentSection();
  const breadcrumbs = useAtomValue(breadcrumbsAtom).value;

  const hasBreadcrumbs = !!length(breadcrumbs);
  const profile = useProfile()[0].value;

  const assignedPhones = hasBreadcrumbs
    ? breadcrumbs[0].phones?.map((p) => p.CountryCode + p.PhoneNumber)
    : [profile.phones.map((p) => p.CountryCode + p.PhoneNumber)];

  const isAssociated = assignedPhones?.some((p) => p === phoneAssignment);

  if (isAssociated) {
    return null;
  }

  if (phoneAssignment?.length === 10) {
    const [, ...parts] = phoneAssignment.match(
      /(.{3})(.{3})(.{4})/
    ) as RegExpMatchArray; // TODO: need to think if it's the best way and how to type that
    phoneAssignment = parts.join(' ');
  }

  if (PAGES_TO_SHOW_ASSIGN.includes(currentSection)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Typography component="p" variant="bodySmall" className={styles.source}>
          Details obtained from {source}
        </Typography>
        <div className={styles.phone}>
          <img src={Phone} alt="Phone" className={styles.img} />
          <Typography component="span" variant="bodySmall">
            {phoneAssignment}
          </Typography>
        </div>

        <Typography component="p" variant="bodySmall">
          Phone number is not associated with any profile.
        </Typography>
      </div>
    </div>
  );
};
export default UnrecognisedPhone;
