import classNames from 'classnames';
import { useOverflowTooltip } from 'hooks/general';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { breadcrumbsAtom, profileAtom } from 'state/store';
import palette from 'styles/palette';
import { IProfile } from 'types/profile';
import Chevron from '../Icons/Chevron';
import Vip from '../Vip/Vip';
import styles from './TravellerCrumb.module.scss';
import { useProfile } from 'hooks/profile';

const TravellerCrumb = ({
  isLastCrumb,
  path,
  profile,
}: {
  profile: Pick<IProfile, 'fullName' | 'guid' | 'isArranger' | 'VIP'>;
  isLastCrumb: boolean;
  path: string;
}) => {
  const { value: currentProfile } = useProfile()[0];

  const { withOverflowTooltip: fullNameElement } = useOverflowTooltip(
    profile.fullName.trim(),
    <div
      className={classNames(styles.name, isLastCrumb && styles.lastCrumbName)}
    />,
    true,
    'fixed'
  );

  const [{ value: breadcrumbs }, dispatchBreadcrumbs] =
    useAtom(breadcrumbsAtom);
  const VIP = profile.VIP;
  const navigate = useNavigate();

  return (
    <>
      <div
        className={classNames(
          styles.crumb,
          !!breadcrumbs.length && styles.crumbOfMany,
          'flex max-w-fit gap-4'
        )}
        onClick={() => {
          if (profile.guid === currentProfile.guid) return;
          dispatchBreadcrumbs({ type: 'POP' });
          navigate(path);
        }}
      >
        <div
          className={isLastCrumb ? 'max-w-full' : 'max-w-[calc(100%_-_24px)]'}
        >
          <div className="flex items-center gap-2">
            {fullNameElement}
            {VIP && <Vip value={VIP} />}
          </div>

          <div
            className={classNames(
              styles.position,
              isLastCrumb && styles.lastCrumbPosition
            )}
          >
            {profile.isArranger ? 'Travel Arranger' : 'Traveller'}
          </div>
        </div>
        {!isLastCrumb && (
          <Chevron className={styles.chevron} fill={palette['grey-4']} />
        )}
      </div>
    </>
  );
};
export default TravellerCrumb;
