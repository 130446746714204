import classNames from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';

import styles from './Tabs.module.scss';

// import Hotel from '../Icons/Hotel'

interface ITab {
  index: number;
  title: string | ReactNode;
  icon?: ReactNode;
  iconActive?: ReactNode;
  count?: number;
  Content?: any;
  isDisabled?: boolean;
}

interface ITabsProps {
  tabs: ITab[];
  initial?: number;
  onActiveChange?: (index: number) => void;
}

const Tabs: FC<ITabsProps> = ({ initial = 0, onActiveChange, tabs }) => {
  const [active, setActive] = useState(initial);

  useEffect(() => {
    setActive(initial);
  }, [initial]);

  const handleActive = (index: number, isDisabled?: boolean) => () => {
    if (!isDisabled && index !== active) {
      setActive(index);
      onActiveChange && onActiveChange(index);
    }
  };
  if (!tabs[active]) {
    return null;
  }
  return (
    <>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            key={tab.index}
            data-e2e-tab={typeof tab.title === 'string' ? tab.title : null}
            className={classNames({
              [styles.active]: tab.index === active,
              [styles.disabled]: tab.isDisabled,
            })}
            onClick={handleActive(tab.index, tab.isDisabled)}
          >
            {tab.icon ? (
              <span className={styles.icon}>
                {tab.index === active ? tab.iconActive : tab.icon}
              </span>
            ) : null}
            {tab.title}
            {tab.count ? (
              <span
                className={classNames(styles.count, {
                  [styles.activeCount]: tab.index === active,
                })}
              >
                {tab.count}
              </span>
            ) : null}
          </div>
        ))}
      </div>
      {tabs[active].Content}
    </>
  );
};

export default Tabs;
