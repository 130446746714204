import Typography from 'components/common/Typography/Typography';
import { useIsSmallScreen, useRouterQuery } from 'hooks/general';
import { useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { phoneAssignmentAtom, profileSourceAtom } from 'state/store';
import { ReactComponent as AuthorizationErrorSvg } from 'styles/icons/authorization_error.svg';
import { getAccessToken } from 'utils/token';

export type JwtInfo = {
  sub: string;
  name: string;
  ver: number;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  jti: string;
  amr: string[];
  idp: string;
  preferred_username: string;
  auth_time: number;
  at_hash: string;
};

const AuthorizationError = () => {
  const query = useRouterQuery();
  const phoneAssignmentDispatch = useSetAtom(phoneAssignmentAtom);
  const isSmallScreen = useIsSmallScreen();
  const dispatchSource = useSetAtom(profileSourceAtom);

  useEffect(() => {
    phoneAssignmentDispatch({ type: 'CLEAR' });
    dispatchSource({ type: 'CLEAR' });
  }, []);
  const token = getAccessToken();
  return (
    <div
      className="noMinWidth m-0 flex grow flex-col items-center justify-center bg-clarity-white md:-m-4"
      data-e2e-id="restrictedAccess"
    >
      <AuthorizationErrorSvg
        className="mb-[24px]"
        height={isSmallScreen ? 120 : undefined}
      />

      <div className="flex max-w-[720px] flex-col items-center gap-[6px] text-center">
        {query.error === 'okta_access' ? (
          <div className="flex flex-col items-center gap-3 md:gap-6 ">
            <div className="flex flex-col gap-[6px]">
              <Typography variant="headingSmall" color="grey-6">
                No Okta Access
              </Typography>
              <div className="px-10">
                <Typography variant="bodyLarge" color="grey-6">
                  We&apos;ve noticed that you don&apos;t have an OKTA account
                  with access to Super Profile.
                </Typography>
                <Typography variant="bodyLarge" color="grey-6">
                  Please contact your local OKTA team for support raising a
                  ticket on Service Now to request the access to Super Profile -
                  PROD tile:{' '}
                  <a
                    href="https://flightcentreau.service-now.com/sp?id=index"
                    className="underline"
                  >
                    https://flightcentreau.service-now.com/sp?id=index
                  </a>
                </Typography>
                <Typography variant="bodyLarge" color="grey-6">
                  Service Portal - Support Portal
                </Typography>
              </div>
            </div>
            {token && (
              <Typography
                variant="bodyMedium"
                color="black"
                className="rounded-full bg-grey-1 px-3 py-1"
              >
                OKTA Email: {(jwtDecode(token) as JwtInfo).preferred_username}
              </Typography>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3 md:gap-6 ">
            <div className="flex flex-col gap-[6px]">
              <Typography variant="headingSmall" color="grey-6">
                This user doesn&apos;t exist in AgentPort
              </Typography>
              <Typography variant="bodyLarge" color="grey-6" className="px-10">
                Please create the user with his respective PCCs or request a new
                user for AgentPort on the corporate support ticketing system{' '}
                <a
                  className="underline"
                  href="https://corporatesupport.fctgl.com/s/"
                >
                  https://corporatesupport.fctgl.com/s/
                </a>
              </Typography>
            </div>
            {token && (
              <Typography
                variant="bodyMedium"
                color="black"
                className="rounded-full bg-grey-1 px-3 py-1"
              >
                OKTA Email: {(jwtDecode(token) as JwtInfo).preferred_username}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorizationError;
