import { FC, HTMLProps } from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

interface SpinnerProps extends HTMLProps<HTMLDivElement> {
  'data-e2e-spinner'?: string;
}

const Spinner: FC<SpinnerProps> = (props) => {
  return (
    <div {...props} className={classNames(styles.loader, props.className)} />
  );
};

export default Spinner;
