import { useMutationState, useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { queryClient } from 'index';

import { IApiNote, INote } from 'types/notes';
import { QueryKey, apiService } from 'utils/apiService';
import { sendGoogleEvent } from 'utils/googleTag';
import { IApiCompanyNote } from './company';
import { useProfile } from './profile';

export const mapNote = (note: IApiNote | IApiCompanyNote) => ({
  author: note.CreatedBy,
  id: note.NoteGuid,
  parentId: 'CompanyGuid' in note ? note.CompanyGuid : note.TravelerGuid,
  priority: note.Priority,
  text: note.Note,
  time: format(new Date(note.CreatedAtUtc), 'EEE d MMM, hh:mm aaa'),
  updatedAt:
    note.UpdatedAtUtc &&
    format(new Date(note.UpdatedAtUtc), 'EEE d MMM, hh:mm aaa'),
  updatedBy: note.UpdatedBy,
});

export const useFetchNotes = () => {
  const profile = useProfile()[0].value;
  const { data, ...rest } = useQuery({
    enabled: !!profile.guid && profile.guid !== 'N/A',
    queryFn: () => {
      return apiService<IApiNote[]>({
        key: [QueryKey.InternalNotes, profile.guid],
        method: 'get',
        url: `/internalnotes?travelerGuid=${profile.guid}`,
      });
    },
    queryKey: ['fetchNotes', profile.guid],
    select: (notes) => notes.map((note) => mapNote(note)),
  });
  return { ...rest, data: data || [] };
};

export const useDeleteNote = () => {
  const { refetch: refetchNotes } = useFetchNotes();
  const profile = useProfile()[0].value;

  const { mutate: deleteNote, ...rest } = useMutation({
    mutationFn: async (id: INote['id']) => {
      try {
        await apiService({
          method: 'delete',
          url: `/internalnotes?noteGuid=${id}&travelerGuid=${profile.guid}`,
        });

        queryClient.removeQueries({ queryKey: [QueryKey.InternalNote, id] });
        queryClient.removeQueries({
          queryKey: [QueryKey.InternalNotes, profile.guid],
        });

        refetchNotes();
        sendGoogleEvent('internal_note_delete');
      } catch (e) {
        throw new Error('Failed to delete note. Profile not found.');
      }
    },

    mutationKey: ['deleteNote'],
  });
  return { deleteNote, ...rest };
};
export const useAddNote = () => {
  const { refetch: refetchNotes } = useFetchNotes();
  const profile = useProfile()[0].value;

  const { mutate: addNote, ...rest } = useMutation({
    mutationFn: async (data: { newText: string; newPriority: number }) => {
      try {
        await apiService({
          data: {
            note: data.newText,
            noteGuid: null,
            priority: data.newPriority,
            travelerGuid: profile.guid,
          },
          method: 'post',
          url: '/internalnotes',
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.InternalNotes, profile.guid],
        });
        refetchNotes();
        sendGoogleEvent('internal_note_create');
      } catch (e) {
        throw new Error('Failed to add note. Profile not found.');
      }
    },

    mutationKey: ['addNote'],
  });
  return { addNote, ...rest };
};
export const useEditNote = () => {
  const { refetch: refetchNotes } = useFetchNotes();
  const profile = useProfile()[0].value;

  const { mutate: editNote, ...rest } = useMutation({
    mutationFn: async (data: {
      id: INote['id'];
      newText: string;
      newPriority: number;
    }) => {
      try {
        await apiService({
          data: {
            note: data.newText,
            noteGuid: data.id,
            priority: data.newPriority,
            travelerGuid: profile.guid,
          },
          method: 'post',
          url: `/internalnotes`,
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.InternalNote, data.id],
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.InternalNotes, profile.guid],
        });
        refetchNotes();
        sendGoogleEvent('internal_note_edit');
      } catch (e) {
        throw new Error('Failed to edit note. Profile not found.');
      }
    },

    mutationKey: ['editNote'],
  });
  return {
    editNote,
    ...rest,
  };
};

export const useIsEditingOrDeletingNote = (id?: INote['id']) => {
  if (!id) {
    return false;
  }
  const isEditingNote = useMutationState({
    filters: { mutationKey: ['editNote'], status: 'pending' },
    select: (mutation) =>
      (mutation?.state?.variables as { id: string }).id === id,
  }).filter(Boolean);
  const isDeletingNote = useMutationState({
    filters: { mutationKey: ['deleteNote'], status: 'pending' },
    select: (mutation) => {
      return (mutation?.state?.variables as string) === id;
    },
  }).filter(Boolean);

  return !!(isEditingNote.length || isDeletingNote.length);
};
