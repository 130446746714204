import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './Wrapper.module.scss';

interface IWrapperProps {
  className?: string;
}

const Wrapper: FC<PropsWithChildren<IWrapperProps>> = ({
  children,
  className,
}) => <div className={classNames(styles.wrapper, className)}>{children}</div>;

export default Wrapper;
