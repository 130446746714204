import { applySpec, converge, pathOr, prop } from 'ramda';
import { useInfiniteQuery } from '@tanstack/react-query';

import { MAX_SEARCH_PAGES } from 'components/pages/Travellers/Travellers';
import { IResultEntity, ITraveller } from '../types/travellers';
import { apiService, isAxiosError } from '../utils/apiService';
import { router } from 'index';

const PAGE_SIZE = 25;
const fetchTravellers = async ({
  guid,
  page,
  searchPattern,
}: {
  guid: string;
  page: number;
  searchPattern?: string;
}) => {
  const searchTerm = searchPattern
    ? `%20${encodeURIComponent(searchPattern)}`
    : '';
  try {
    const response = (await apiService({
      method: 'get',
      url: `/profile?SearchPattern=${guid}${searchTerm}&SearchType=Main&page=${page}&size=${PAGE_SIZE}`,
    })) as { Result: IResultEntity[]; PageNo: number; Total: number };
    const items = prop('Result', response) ?? [];
    const newPage = prop('PageNo', response);
    const total = prop('Total', response);
    return { items, newPage, total };
  } catch (e) {
    if (isAxiosError(e) && e?.request?.status === 403) {
      router.navigate('/authorization-error', { replace: true });
      return { items: [], newPage: 0, total: 0 };
    }

    return { items: [], newPage: 0, total: 0 };
  }
};

export const useFetchTravellers = ({
  guid,
  searchPattern,
}: {
  guid: string;
  searchPattern?: string;
}) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    status,
  } = useInfiniteQuery({
    enabled: !!guid && guid !== 'N/A',
    getNextPageParam: (lastPage) => {
      if (!lastPage) return undefined;
      return lastPage.total > PAGE_SIZE * lastPage.newPage &&
        lastPage.newPage < MAX_SEARCH_PAGES
        ? lastPage?.newPage + 1
        : undefined;
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) =>
      fetchTravellers({ guid, page: pageParam, searchPattern }),
    queryKey: ['trips', guid, searchPattern],
  });
  const travellers = data?.pages.map((value) => value.items).flat();
  const mappedTravellers = travellers && mapTravellers(travellers);
  return {
    currentPage: data?.pages.at(-1)?.newPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    status,
    travellers: mappedTravellers,
  };
};
export const mapTraveller = applySpec({
  TravelerGuid: pathOr('N/A', ['TravelerGuid']),
  VIP: pathOr('', ['VIP']),
  firstName: pathOr('N/A', ['FirstName']),
  lastName: pathOr('N/A', ['LastName']),
  locator: (traveller: IResultEntity) => traveller?.GDSLocator || 'N/A',
  name: converge(
    (...args) => {
      const [fn, ln] = args;
      return fn || ln ? `${fn} ${ln}` : 'N/A';
    },
    [pathOr('', ['FirstName']), pathOr('', ['LastName'])]
  ),
  pcc: (traveller: IResultEntity) => traveller?.Company?.PseudoCity || 'N/A',
  position: (traveller: IResultEntity) => traveller?.JobTitle || 'N/A',
});

const mapTravellers = (travellers: IResultEntity[]) => {
  return travellers.map((t) => mapTraveller(t) as ITraveller);
};
