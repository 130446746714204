import { FC } from 'react';

import Typography from 'components/common/Typography/Typography';
import { IFlightBooking, ILayover } from 'types/trips';
import { formatTime } from 'utils/trips';
import styles from '../../DetailedTrips.module.scss';

import { useWindowSize } from 'usehooks-ts';
import {
  displayFlightInfo,
  getBaggageInfo,
  getCityNameFromFlight,
  getIataCityName,
  renderMeals,
} from './AirSection';

interface DisplayInfo {
  time?: string;
  estimatedTime?: string;
  place: string;
  terminal: string | undefined;
  gate: string | undefined;
  type: 'dep' | 'arr';
}

const getDisplayDataFromLayover = (layover: ILayover) => {
  const layoverFlightBooking = layover.FlightBooking;
  const layoverFlightInfo = layoverFlightBooking.Flight;
  const sherpa = layover?.FlightBooking?.CovidInfo?.ExtraInfoUrl;
  const arrivalCity = getCityNameFromFlight(layoverFlightBooking, true);
  const arrivalIata = layoverFlightInfo?.ArrivalAirport?.Iata ?? 'N/A';
  const arrivalTerminal = layoverFlightInfo?.ArrivalTerminal;
  const arrivalGate = layoverFlightInfo?.ArrivalGate;
  const departureCity = getCityNameFromFlight(layoverFlightBooking, false);
  const departureIata = layoverFlightInfo?.DepartureAirport?.Iata ?? 'N/A';
  const departureTerminal = layoverFlightInfo?.DepartureTerminal;
  const departureGate = layoverFlightInfo?.DepartureGate;
  const departureDate = layoverFlightBooking.LocalDepartureAt;
  const arrivalDate = layoverFlightBooking.LocalArrivalAt;
  const layoverWaitDuration = layover.Duration;
  const layoverDuration = layoverFlightBooking?.Flight?.Duration;
  const convertedLayoverWaitDuration = layoverWaitDuration
    ? formatTime(layoverWaitDuration)
    : 'N/A';
  const convertedLayoverDuration = layoverDuration
    ? formatTime(layoverDuration)
    : 'Duration N/A';
  const flightOperatorInfo = `${layoverFlightInfo?.AirLine} ${
    layoverFlightInfo?.Number
  } ${
    layoverFlightInfo?.Operator &&
    'operated by ' + layoverFlightBooking?.Flight?.Operator
  }`.replaceAll(/null|undefined/g, '');
  const airplaneInfo = layoverFlightInfo?.Equipment.Name;
  const classOfService = layoverFlightBooking.ClassOfService;
  const options = [
    { label: 'Seat', value: layoverFlightBooking.Seat || '' },
    {
      label: 'Baggage Info',
      value: getBaggageInfo(layoverFlightBooking),
    },
    {
      render: () => renderMeals(layoverFlightBooking),
    },
  ];
  const depInfo: DisplayInfo = {
    estimatedTime: layoverFlightInfo?.EstimatedLocalDepartureAt || '',
    gate: departureGate || '',
    place: getIataCityName(
      departureIata,
      departureCity,
      'Departure Airport N/A'
    ),
    terminal: departureTerminal || '',
    time: departureDate,
    type: 'dep',
  };
  const arrInfo: DisplayInfo = {
    estimatedTime: layoverFlightInfo?.EstimatedLocalArrivalAt || '',
    gate: arrivalGate || '',
    place: getIataCityName(arrivalIata, arrivalCity, 'Arrival Airport N/A'),
    terminal: arrivalTerminal,
    time: arrivalDate,
    type: 'arr',
  };
  return {
    airplaneInfo,
    arrInfo,
    classOfService,
    convertedLayoverDuration,
    convertedLayoverWaitDuration,
    depInfo,
    departureCity,
    flightOperatorInfo,
    options,
    sherpa,
  };
};

export const getLayoverMainCityOrIata = (flightBooking: IFlightBooking) => {
  const departureAirport = flightBooking?.Flight?.DepartureAirport;
  return departureAirport?.MainCity?.Name || departureAirport?.Iata || 'N/A';
};

const Layover: FC<{ layover: ILayover }> = ({ layover }) => {
  const { width } = useWindowSize();
  const {
    airplaneInfo,
    arrInfo,
    classOfService,
    convertedLayoverDuration,
    convertedLayoverWaitDuration,
    depInfo,
    flightOperatorInfo,
    options,
    sherpa,
  } = getDisplayDataFromLayover(layover);
  const layoverStop = getLayoverMainCityOrIata(layover.FlightBooking);
  return (
    <div className="mt-2">
      <div className={styles.layoverTitle}>
        <Typography variant="bodySmall2">
          {convertedLayoverWaitDuration}
        </Typography>
        Layover{layoverStop ? `, ${layoverStop}` : ''}
      </div>
      {displayFlightInfo({
        airplaneInfo,
        arrInfo,
        classOfService,
        convertedDuration: convertedLayoverDuration,
        depInfo,
        flightOperatorInfo,
        options,
        sherpa,
        windowWidth: width,
      })}
    </div>
  );
};

export default Layover;
