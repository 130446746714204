import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Line.module.scss';

interface ILineProps {
  children: ReactNode[];
  customMargin?: boolean;
  className?: string;
}
const Line = ({ children, className, customMargin = false }: ILineProps) => (
  <div
    className={classNames(
      styles.lineContainer,
      !customMargin && styles.lineMargins,
      className
    )}
  >
    {children.map((child, idx) => (
      <div
        className={classNames(styles.line, 'bg-white max-w-[49%] text-left')}
        key={idx}
      >
        {child}
      </div>
    ))}
  </div>
);

export default Line;
