import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'index';
import { length } from 'ramda';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import Button from 'components/common/Button/Button';
import { ReactComponent as PhoneBackSvg } from 'styles/icons/phone_back.svg';
import { QueryKey, apiService } from 'utils/apiService';

import Typography from '../Typography/Typography';

import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import { renderPlurals } from 'utils/format';

import Modal from '../Modal';
import RequiredStar from '../RequiredStar/RequiredStar';
import Select, { ISelectOption } from '../Select/Select';

import palette from 'styles/palette';
import { getFirstAndLast } from 'utils/common';
import styles from './AssignInfo.module.scss';

import { PAGES_TO_SHOW_ASSIGN } from 'constants/general';
import { useCurrentSection } from 'hooks/general';
import useFetchProfile, { useProfile } from 'hooks/profile';
import { useAtom, useAtomValue } from 'jotai';
import {
  breadcrumbsAtom,
  phoneAssignmentAtom,
  requireCallerAuthAtom,
} from 'state/store';
import { IProfile } from 'types/profile';
import { PHONE_ORDER_ARR } from 'utils/profile';
import ActionNotification from '../ActionNotification/ActionNotification';
import Checkbox from '../Checkbox/Checkbox';

const AssignInfo: FC = () => {
  const profile = useProfile()[0].value;
  let [phoneAssignment, dispatchPhoneAssignment] = useAtom(phoneAssignmentAtom);
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const currentSection = useCurrentSection();
  const breadcrumbs = useAtomValue(breadcrumbsAtom).value;
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [notification, setNotification] = useState<{
    isOpen: boolean;
    type: 'success' | 'error';
  }>({ isOpen: false, type: 'success' });
  const [agreedToSave, setAgreedToSave] = useState(false);
  const [selectedPhoneType, setSelectedPhoneType] = useState<string | null>(
    null
  );
  const handleModalClose = () => {
    setIsAssignModalOpen(false);
    setAgreedToSave(false);
  };
  const { refetch } = useFetchProfile();
  const { isPending: isAssigning, mutate: assignPhoneToUser } = useMutation({
    mutationFn: () =>
      apiService({
        data: {
          PhoneNumber: phoneAssignment ?? '',
          PhoneType: selectedPhoneType,
        },
        method: 'post',
        url: `/profile/${userId}/phone/`,
      }),
    onError: () => {
      setNotification({ isOpen: true, type: 'error' });
    },
    onSettled: () => {
      handleModalClose();
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.Profile, profile.guid] });
      setNotification({ isOpen: true, type: 'success' });
      setSelectedPhoneType(null);
      refetch();
      dispatchPhoneAssignment({ type: 'CLEAR' });
    },
  });

  const hasBreadcrumbs = !!length(breadcrumbs);
  if (phoneAssignment?.length === 10) {
    const [, ...parts] = phoneAssignment.match(
      /(.{3})(.{3})(.{4})/
    ) as RegExpMatchArray; // TODO: need to think if it's the best way and how to type that
    phoneAssignment = parts.join(' ');
  }
  const getUserIdFromBreadcrumbs = () => {
    if (hasBreadcrumbs) {
      // Get the 'id' property of the first breadcrumb
      const firstBreadcrumb = breadcrumbs[0];
      return firstBreadcrumb.id;
    }
    // Get the 'guid' property of the 'profile' object
    return profile.guid;
  };
  const userId = getUserIdFromBreadcrumbs();

  const { refetch: refetchProfile } = useFetchProfile();
  const dismiss = () => {
    queryClient.removeQueries({ queryKey: ['fetchProfile', profile.guid] });
    refetchProfile();
    dispatchPhoneAssignment({ type: 'CLEAR' });
  };

  const retry = () => {
    assignPhoneToUser();
  };

  if (requireCallerAuth) return null;

  if (phoneAssignment && PAGES_TO_SHOW_ASSIGN.includes(currentSection)) {
    return (
      <div className={styles.container}>
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-4">
          <div className="flex">
            <PhoneBackSvg className="mr-[5.32px]" />
            <Typography component="span" variant="bodySmall">
              {phoneAssignment}
            </Typography>
          </div>
          <Typography variant="bodySmall">
            Phone Number is not associated with any profile.
          </Typography>
        </div>
        <div className="flex flex-col items-center gap-1 lg:flex-row lg:gap-4">
          <Typography variant="bodySmall">
            Do you want to assign it to the current profile?
          </Typography>

          <div className="flex gap-4 self-end">
            <Button
              customPadding
              variant="text"
              className="font-ibm-plex-sans font-medium"
              onClick={() => setIsAssignModalOpen(true)}
            >
              Yes, Assign
            </Button>
            <Button
              customPadding
              variant="text"
              className="font-ibm-plex-sans font-medium"
              onClick={dismiss}
            >
              Dismiss
            </Button>
          </div>
        </div>
        <AssignPhoneModal
          onClose={handleModalClose}
          onSubmit={assignPhoneToUser}
          openAssignModal={isAssignModalOpen}
          agreedToSave={agreedToSave}
          selectedPhoneType={selectedPhoneType}
          formattedPhone={phoneAssignment}
          setSelectedPhoneType={setSelectedPhoneType}
          setAgreedToSave={setAgreedToSave}
          profile={profile}
        />
        <ActionNotification
          isOpen={notification.isOpen && !isAssigning}
          onClose={() =>
            setNotification((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
          variant={notification.type}
          retry={retry}
          text={
            notification.type === 'error'
              ? 'Failed to assign the Phone Number.'
              : 'Phone Number successfully assigned to the Profile.'
          }
        />
      </div>
    );
  }

  if (PAGES_TO_SHOW_ASSIGN.includes(currentSection)) {
    return (
      <ActionNotification
        isOpen={notification.isOpen && !isAssigning}
        onClose={() =>
          setNotification((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
        variant={notification.type}
        retry={retry}
        text={
          notification.type === 'error'
            ? 'Failed to assign the Phone Number.'
            : 'Phone Number successfully assigned to the Profile.'
        }
      />
    );
  }

  return null;
};

type AssignPhoneModalProps = {
  openAssignModal: boolean;
  agreedToSave: boolean;
  selectedPhoneType: string | null;
  onClose: () => void;
  formattedPhone?: string;
  setSelectedPhoneType: Dispatch<SetStateAction<string | null>>;
  setAgreedToSave: Dispatch<SetStateAction<boolean>>;
  profile: IProfile;
  onSubmit: () => void;
};
export const PHONE_OPTIONS = {
  BUSINESS: 'B',
  HOME: 'H',
  MOBILE: 'C',
  TRUNK_LINE: 'T',
};
const mobileOptions: ISelectOption[] = [
  { label: 'Business', value: PHONE_OPTIONS.BUSINESS },
  { label: 'Mobile', value: PHONE_OPTIONS.MOBILE },
  { label: 'Home', value: PHONE_OPTIONS.HOME },
];

function AssignPhoneModal({
  agreedToSave,
  formattedPhone,
  onClose,
  onSubmit,
  openAssignModal,
  profile,
  selectedPhoneType,
  setAgreedToSave,
  setSelectedPhoneType,
}: AssignPhoneModalProps) {
  const displayProfileName = getFirstAndLast(profile);
  const closeAndResetModal = () => {
    onClose();
    setSelectedPhoneType(null);
  };
  return (
    <Modal isOpen={openAssignModal} setIsOpen={closeAndResetModal}>
      <div className="flex min-h-[220px] flex-col" data-e2e-assignment="modal">
        <div className="mb-5 flex items-center justify-between">
          <Typography variant="bodyLarge2" data-e2e-assignment="title">
            Assign Phone Number: {formattedPhone}
          </Typography>
          <CloseSvg
            color={palette['grey-5']}
            className="cursor-pointer"
            onClick={closeAndResetModal}
          />
        </div>
        <div className="mb-1 flex items-center gap-x-1">
          <Typography variant="bodyMedium">Contact Type</Typography>
          <RequiredStar variant="bodyMedium" />
        </div>
        <Select
          defaultText="Select"
          optionsList={mobileOptions}
          onSelect={(v) => setSelectedPhoneType(v)}
          listClassName="z-10"
          wrapperClassName="w-[200px] h-9"
        />
        <label className="mb-4 mt-2 flex items-center gap-x-2">
          <Checkbox
            onChange={setAgreedToSave}
            checked={agreedToSave}
            className="h-4 w-4"
            id="agreement"
            labelClassName="before:h-4 before:w-4 after:left-[3.5px] after:top-[3px]"
          />
          <Typography variant="bodyMedium" className="cursor-pointer">
            I confirm that the caller has agreed to save the phone number.
          </Typography>
        </label>
        <div className="mb-4 border-b border-grey-3"></div>
        {profile.phones.length > 0 ? (
          <>
            <Typography
              variant="bodySmall2"
              className="mb-2"
              color="charcoal-grey"
              data-e2e-assignment="otherPhonesTitle"
            >
              {profile.phones.length} Other Phone{' '}
              {renderPlurals({
                count: profile.phones.length,
                entity: ['Number', 'Numbers'],
                showAmount: false,
              })}{' '}
              for {displayProfileName}
            </Typography>
            <div
              data-e2e-list="phonesList"
              className="mb-5 flex max-h-[168px] gap-x-4 gap-y-1 overflow-auto"
            >
              <div className="flex flex-col gap-y-1">
                {profile.phones.map((phone) => {
                  return (
                    <Typography
                      key={phone.PhoneNumber}
                      variant="bodyMedium"
                      data-e2e-phone="phoneItem"
                    >
                      {`${phone.CountryCode ? `+${phone.CountryCode} ` : ''}${
                        phone.PhoneNumber
                      }`}
                    </Typography>
                  );
                })}
              </div>
              <div className="flex flex-col gap-y-1">
                {profile.phones.map((phone) => {
                  const phoneType =
                    PHONE_ORDER_ARR.find(({ code }) => code === phone.PhoneType)
                      ?.label ?? 'Mobile (Other mobile No.)';
                  return (
                    <Typography
                      key={phone.PhoneType}
                      variant="bodyMedium"
                      data-e2e-type="phoneType"
                    >
                      {phoneType}
                    </Typography>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography
              variant="bodySmall2"
              className="mb-2"
              color="charcoal-grey"
            >
              Other Phone Numbers for {profile.fullName}
            </Typography>
            <Typography
              variant="bodySmall"
              color="charcoal-grey"
              className="mb-4"
              data-e2e-assignment="otherPhonesEmpty"
            >
              There are no saved Numbers for this Profile yet
            </Typography>
          </>
        )}
        <div className="mt-auto flex justify-end">
          <Button
            variant="secondary"
            onClick={closeAndResetModal}
            className={styles.assignBtn}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!selectedPhoneType || !agreedToSave}
            onClick={onSubmit}
            className={styles.assignBtn}
          >
            Assign Phone Number
          </Button>
        </div>
      </div>
    </Modal>
  );
}
export default AssignInfo;
