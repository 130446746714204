import Spinner from 'components/common/Spinner/Spinner';

const NoteSpinnerOverlay = () => {
  return (
    <div className="absolute left-0 top-0 h-full w-full bg-black-a30">
      <div className="flex h-full -scale-50 items-center justify-center">
        <Spinner data-e2e-spinner="add_note_loader" />
      </div>
    </div>
  );
};
export default NoteSpinnerOverlay;
