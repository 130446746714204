import { useMutation } from '@tanstack/react-query';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import { MouseEvent, ReactElement, useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography/Typography';
import { oldRouteMap } from 'constants/general';
import Difficult from 'styles/icons/feedback/difficult.svg';
import Easy from 'styles/icons/feedback/easy.svg';
import Neutral from 'styles/icons/feedback/neutral.svg';
import Success from 'styles/icons/feedback/success.svg';
import VeryDifficult from 'styles/icons/feedback/very-difficult.svg';
import VeryEasy from 'styles/icons/feedback/very-easy.svg';
import { apiService } from 'utils/apiService';
import { renderPlurals } from 'utils/format';

import Loader from 'components/common/Loader';
import { prevRouteAtom } from 'state/store';
import styles from './Feedback.module.scss';

const scoreIcons = [
  { label: 'Very Difficult', svg: VeryDifficult },
  { label: 'Difficult', svg: Difficult },
  { label: 'Neutral', svg: Neutral },
  { label: 'Easy', svg: Easy },
  { label: 'Very Easy', svg: VeryEasy },
];
interface IFeedbackRequest {
  ScoreToDisplay: string;
  Text: string;
}

export const useBackButton = () => {
  const prevRoute = useAtomValue(prevRouteAtom);
  const navigate = useNavigate();

  const prevRouteWithoutQuery = prevRoute?.split('?')[0];
  const getBackButtonProps = () => {
    const handleGoBack = () => navigate(-1);
    if (!prevRouteWithoutQuery) {
      return {
        name: oldRouteMap['/'].name,
        onClick: () => navigate('/'),
      };
    }
    for (const key in oldRouteMap) {
      if (prevRouteWithoutQuery.includes('/multiple-profiles')) {
        return {
          name: 'Back to list',
          onClick: handleGoBack,
        };
      }
      if (matchPath(key, prevRouteWithoutQuery)) {
        return {
          name: oldRouteMap[key as keyof typeof oldRouteMap].name,
          onClick: handleGoBack,
        };
      }
    }
  };
  return getBackButtonProps();
};
const Feedback = (): ReactElement | null => {
  const [text, setText] = useState('');
  const [score, setScore] = useState('');

  const handleScore = (score: string) => () => {
    setScore(score);
  };

  const textExceed = text.trim().length - 2000;
  const isValid = (!!text.trim().length || !!score.length) && textExceed <= 0;
  const { data, mutate, status } = useMutation({
    mutationFn: (data: IFeedbackRequest) => {
      return apiService({
        data,
        method: 'post',
        url: '/feedback',
      });
    },

    mutationKey: ['sendFeedback'],
  });
  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    mutate({ ScoreToDisplay: score, Text: text });
  };

  const renderForm = () => (
    <form className={styles.container}>
      <div className={styles.title}>
        <div>How would you evaluate your experience with</div>
        <br />
        <div>the Super Profile?</div>
      </div>

      <div className={styles.score}>
        {scoreIcons.map(({ label, svg }) => (
          <div
            onClick={handleScore(label)}
            key={label}
            className={classnames({ [styles.active]: label === score })}
          >
            <img src={svg} alt="feedback" />
            <Typography
              variant="bodySmall2"
              color="charcoal-grey"
              className="mt-1"
            >
              {label}
            </Typography>
          </div>
        ))}
      </div>

      <div className={styles.text}>
        <textarea value={text} onChange={(evt) => setText(evt.target.value)} />
      </div>
      {textExceed > 0 ? (
        <div
          className={styles.hint}
        >{`Feedback length is exceeded by ${renderPlurals({
          count: textExceed,
          entity: ['character', 'characters'],
        })}`}</div>
      ) : null}
      <Button
        variant="primary"
        className={classnames(styles.button)}
        onClick={handleSubmit}
        type="submit"
        disabled={!isValid}
      >
        Submit Feedback
      </Button>
    </form>
  );
  const backButtonProps = useBackButton();

  const renderSuccess = () => {
    return (
      <div className={styles.container}>
        <div
          className={classnames(
            styles.title,
            'flex',
            'flex-col',
            'items-center'
          )}
        >
          <img src={Success} alt="success" className={styles.successImg} />
          <div> Thank you for your feedback!</div>
        </div>
        <Button
          variant="primary"
          className={styles.mt16}
          onClick={backButtonProps?.onClick}
        >
          {backButtonProps?.name.includes('Back to')
            ? backButtonProps?.name
            : `Back to ${backButtonProps?.name}`}
        </Button>
      </div>
    );
  };

  if (status === 'success' && data === '') {
    return renderSuccess();
  }
  if (status === 'idle' && data !== '') {
    // we get an empty string response when the mutations is completed
    return renderForm();
  }
  return <Loader e2e="feedback_loader" />;
};

export default Feedback;
