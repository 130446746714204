import {
  arrow,
  autoUpdate,
  flip,
  Middleware,
  offset,
  Placement,
  safePolygon,
  shift,
  Side,
  Strategy,
  OffsetOptions,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import classNames from 'classnames';
import { cloneElement, FC, HTMLProps, useRef, useState } from 'react';

import styles from './Popper.module.scss';

interface UncontrolledPopperProps extends HTMLProps<HTMLDivElement> {
  trigger: 'hover' | 'click';
  children: any;
  content: any;
  type?: 'popper' | 'tooltip' | 'onboarding';
  className?: string;
  placement?: Placement;
  offsetOptions?: OffsetOptions;
  noArrow?: boolean;
  customPadding?: boolean;
  controlledArrow?: boolean;
  strategy?: Strategy;
}

const UncontrolledPopper: FC<UncontrolledPopperProps> = (props) => {
  const [open, setOpen] = useState(false);

  return <ControlledPopper {...props} open={open} setOpen={setOpen} />;
};

interface ControlledPopperProps extends UncontrolledPopperProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ControlledPopper: FC<ControlledPopperProps> = ({
  children,
  className,
  content,
  controlledArrow = false,
  customPadding = false,
  noArrow = false,
  offsetOptions = 5,
  open,
  placement = 'right',
  setOpen,
  strategy: innerStrategy = 'absolute',
  trigger,
  type = 'popper',
  ...contentProps
}) => {
  const arrowEl = useRef<HTMLDivElement>(null);
  const moveArrowToReference: Middleware = {
    fn(args) {
      const style = arrowEl.current?.style;
      if (!controlledArrow || !style) return args; // TODO: maybe this should be on by default
      const { rects } = args;
      const boundingClientRect = document
        .querySelector('body')!
        .getBoundingClientRect();
      const setArrowPosition = ({
        counterDirection,
        floatingPopperValue,
        mainAxis,
        mainDirection,
        referenceValue,
        screenValue,
        transform,
      }: {
        counterDirection: Side;
        floatingPopperValue: number;
        mainAxis: number;
        mainDirection: Side;
        referenceValue: number;
        screenValue: number;
        transform: string;
      }) => {
        const ARROW_WIDTH = 5;

        if (mainAxis <= floatingPopperValue / 2) {
          const value =
            mainAxis +
            Math.min(referenceValue / 2, floatingPopperValue / 2) +
            'px';
          style[mainDirection] = `calc(min(50%, ${value}) - ${ARROW_WIDTH}px)`;
          style.transform = transform;

          return;
        }
        if (mainAxis + floatingPopperValue / 2 <= screenValue) {
          style[mainDirection] = floatingPopperValue / 2 - ARROW_WIDTH + 'px';
          style.transform = transform;

          return;
        }
        const referenceDiff = screenValue - (mainAxis + referenceValue / 2);
        style[counterDirection] =
          Math.round(referenceDiff) - ARROW_WIDTH + 'px';
        style[mainDirection] = 'initial';
        style.transform = transform;
      };
      if (args.placement === 'top' || args.placement === 'bottom') {
        const mainAxis = rects.reference.x;
        const floatingPopperValue = rects.floating.width;
        const referenceValue = rects.reference.width;
        setArrowPosition({
          counterDirection: 'right',
          floatingPopperValue,
          mainAxis,
          mainDirection: 'left',
          referenceValue,
          screenValue: boundingClientRect.width,
          transform: `rotate(45deg)`,
        });
      } else {
        const mainAxis = rects.reference.y;
        const floatingPopperValue = rects.floating.height;
        const referenceValue = rects.reference.height;
        setArrowPosition({
          counterDirection: 'bottom',
          floatingPopperValue,
          mainAxis,
          mainDirection: 'top',
          referenceValue,
          screenValue: boundingClientRect.height,
          transform: `translate(${
            args.placement === 'right' ? '-50%' : '0%'
          }, 50%) rotate(45deg)`,
        });
      }
      return {
        ...args,
      };
    },
    name: 'moveArrowToReference',
  };
  const {
    context,
    placement: computedPlacement,
    refs: { setFloating: floating, setReference: reference },
    strategy,
    x,
    y,
  } = useFloating({
    middleware: [
      offset(offsetOptions),
      shift(),
      arrow({
        element: arrowEl,
      }),
      flip(),
      moveArrowToReference,
    ],
    onOpenChange: setOpen,
    open,
    placement,
    strategy: innerStrategy,
    whileElementsMounted: autoUpdate,
  });

  const interactions = [
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    useHover(context, {
      enabled: trigger === 'hover',
      handleClose: safePolygon(),
    }),
    useClick(context, { enabled: trigger === 'click' }),
  ];

  const { getFloatingProps, getReferenceProps } = useInteractions(interactions);
  const isOnboarding = type === 'onboarding';
  const isPopper = type === 'popper';
  const isTooltip = type === 'tooltip';

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref: reference,
          ...children.props,
          ...contentProps,
        })
      )}
      {open && (
        <div
          {...getFloatingProps({
            className: classNames(
              {
                [styles.popover]: isPopper || isOnboarding,
                [styles.popoverPadding]:
                  (isPopper && !customPadding) ||
                  (isOnboarding && !customPadding),
                [styles.tooltip]: isTooltip,
                [styles.tooltipPadding]: isTooltip && !customPadding,
              },
              className
            ),
            ref: floating,
            style: {
              left: x ?? 0,
              position: strategy,
              top: y ?? 0,
            },
          })}
        >
          {content}
          {!noArrow && (
            <div
              ref={arrowEl}
              className={classNames('popperArrow', {
                [styles.onboardingArrow]: isOnboarding,
                [styles.popoverArrow]: isPopper,
                [styles.tooltipArrow]: isTooltip,
                [styles[computedPlacement]]: computedPlacement,
              })}
            />
          )}
        </div>
      )}
    </>
  );
};

export const useControlledPopper = () => {
  const [isOpen, setIsOpen] = useState(false);

  return { isOpen, setIsOpen };
};

export default UncontrolledPopper;
