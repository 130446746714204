import classNames from 'classnames';

import { format } from 'date-fns/esm';
import { FC } from 'react';

import {
  ControlledPopper,
  useControlledPopper,
} from 'components/common/Popper/Popper';
import Typography from 'components/common/Typography/Typography';
import { ReactComponent as InfoSvg } from 'styles/icons/info.svg';
import palette from 'styles/palette';

interface EstimatedDateInfoProps {
  value: string;
  className?: string;
}

const EstimatedDateInfo: FC<EstimatedDateInfoProps> = ({
  className,
  value,
  ...props
}) => {
  const { isOpen, setIsOpen } = useControlledPopper();
  return (
    <ControlledPopper
      open={isOpen}
      setOpen={setIsOpen}
      customPadding
      controlledArrow
      className="min-w-[22ch] p-3 pt-4"
      trigger="hover"
      placement="bottom"
      offsetOptions={{ mainAxis: 10 }}
      strategy="fixed"
      content={
        <div className="text-left">
          <Typography variant="bodySmall" color="charcoalGray">
            Original local time
          </Typography>
          <Typography variant="bodyMedium" color="black">
            {format(new Date(value), 'd MMM yyyy, h:mm a')}
          </Typography>
        </div>
      }
    >
      <InfoSvg
        width={11}
        height={11}
        color={isOpen ? palette['fcm-green'] : palette['grey-5']}
        className={classNames(className, 'shrink-0')}
        {...props}
      />
    </ControlledPopper>
  );
};

export default EstimatedDateInfo;
