import classNames from 'classnames';
import { Dispatch, FC, HTMLProps, SetStateAction, useState } from 'react';

import styles from './Checkbox.module.scss';

export const useCheckbox = () => {
  const [checked, setChecked] = useState(false);
  return { checked, setChecked };
};

interface ControlledCheckboxProps {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  className?: string;
  labelClassName?: string;
}
interface UncontrolledCheckboxProps {
  onChange: (checked: boolean) => void;
  className?: string;
  labelClassName?: string;
}

type HTMLPropsWithoutOverrides = Omit<
  HTMLProps<HTMLInputElement>,
  'checked' | ('onChange' & { id: string })
>;
type CheckboxProps = HTMLPropsWithoutOverrides &
  (ControlledCheckboxProps | UncontrolledCheckboxProps);

const Checkbox: FC<any> =
  // FC<CheckboxProps> // TODO: investigate how to do it properly
  ({ setChecked, ...rest }) => {
    if (setChecked) {
      return <ControlledCheckbox setChecked={setChecked} {...rest} />;
    }
    return <UncontrolledCheckbox {...rest} />;
  };

const ControlledCheckbox: FC<
  HTMLPropsWithoutOverrides & ControlledCheckboxProps
> = ({
  checked,
  className,
  disabled,
  id,
  labelClassName,
  setChecked,
  ...rest
}) => {
  return (
    <div className={classNames(styles.checkbox, className)}>
      <input
        {...rest}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={disabled}
      />
      <label htmlFor={id} className={labelClassName} />
    </div>
  );
};

const UncontrolledCheckbox: FC<
  HTMLPropsWithoutOverrides & UncontrolledCheckboxProps
> = ({ className, disabled, id, labelClassName, onChange, ...rest }) => {
  return (
    <div className={classNames(styles.checkbox, className)}>
      <input
        {...rest}
        type="checkbox"
        id={id}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <label htmlFor={id} className={labelClassName} />
    </div>
  );
};
export default Checkbox;
