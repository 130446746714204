import classNames from 'classnames';

import { format } from 'date-fns/esm';
import { FC } from 'react';
import { ReactComponent as TrainSvg } from 'styles/icons/amenities/train.svg';

import Typography from 'components/common/Typography/Typography';
import { ITrainBooking } from 'types/trips';

import { formatTime, getBookingType } from 'utils/trips';
import { renderOptions } from '../DetailedTrips';
import styles from '../DetailedTrips.module.scss';

export const getTrainDisplayDataFromBooking = (booking: ITrainBooking) => {
  const departureDate = booking.LocalDepartureAt || '';
  const arrivalDate = booking.LocalArrivalAt || '';
  const pickupLocation = booking.DepartureStation;
  const confirmationNumber = booking.NativeRecordLocator;
  const pickupLocationName =
    `${pickupLocation?.CountryCode}, ${pickupLocation?.CityName}, ${pickupLocation?.Name}`
      .replaceAll(/null,|null/g, '')
      .replaceAll(/,\s+$/g, '')
      .trim() || 'Departure Station N/A';
  const dropoffLocation = booking.ArrivalStation;
  const dropoffLocationName =
    `${dropoffLocation?.CountryCode}, ${dropoffLocation?.CityName}, ${dropoffLocation?.Name}`
      .replaceAll(/null,|null/g, '')
      .replaceAll(/,\s+$/g, '')
      .trim() || 'Arrival Station N/A';
  const operator = booking.Operator?.Name;
  const duration = booking.Duration;
  const convertedDuration = duration ? formatTime(duration) : 'N/A';
  const pnr = booking.PnrRecordLocator;
  const ticketNumber = booking.TicketNumber;
  const options = [
    {
      label: 'Number',
      value: booking.Number,
    },
    { label: 'Coach', value: booking.Coach },
    { label: 'Seat', value: booking.Seat },
  ];
  return {
    arrivalDate,
    confirmationNumber,
    convertedDuration,
    departureDate,
    dropoffLocationName,
    duration,
    operator,
    options,
    pickupLocationName,
    pnr,
    ticketNumber,
  };
};

const TrainSection: FC<{ booking: ITrainBooking; id: number }> = ({
  booking,
  id,
}) => {
  const {
    arrivalDate,
    confirmationNumber,
    convertedDuration,
    departureDate,
    dropoffLocationName,
    duration,
    operator,
    options,
    pickupLocationName,
    ticketNumber,
  } = getTrainDisplayDataFromBooking(booking);

  return (
    <div
      className={styles.sectionContainer}
      id={`${getBookingType(booking)}_${id}`}
    >
      <div className="flex gap-[20px]">
        <TrainSvg
          width={24}
          height={24}
          className={classNames(styles.jet, 'shrink ')}
          data-e2e-icon="Train"
        />
        <div className="w-full">
          <div className={styles.segmentPrimaryInfo}>
            <div className={classNames(styles.fixWidth, styles.from)}>
              <Typography
                variant="bodyMedium"
                data-e2e-train_summary_departure_date
              >
                {departureDate
                  ? format(new Date(departureDate), 'd MMM yyyy, h:mm a')
                  : 'N/A'}{' '}
              </Typography>
              <Typography variant="bodyMedium2">
                {pickupLocationName}
              </Typography>
            </div>
            <div className={classNames(styles.fixWidth, styles.to)}>
              <Typography
                variant="bodyMedium"
                className="text-right lg:text-left"
                data-e2e-train_summary_arrival_date
              >
                {arrivalDate
                  ? format(new Date(arrivalDate), 'd MMM yyyy, h:mm a')
                  : 'N/A'}
              </Typography>
              <Typography
                variant="bodyMedium2"
                className="text-right lg:text-left"
              >
                {dropoffLocationName}
              </Typography>
            </div>
            <div className={classNames('flex flex-col gap-1', styles.stops)}>
              <Typography variant="bodySmall" data-e2e-travel_duration>
                {duration ? convertedDuration : 'Duration N/A'}
              </Typography>
            </div>
          </div>
          <div className={classNames('mt-3 items-center ', styles.gdsBlock)}>
            <Typography
              variant="bodySmall2"
              className={classNames(
                'xs:mb-auto flex items-center justify-center bg-green-highlight lg:mb-0 lg:px-2 lg:py-1',
                styles.label
              )}
            >
              {booking.IsPassive ? 'Passive' : 'GDS'}
            </Typography>
            <div className="flex grow flex-col gap-1 bg-grey-1 px-2 py-1 lg:flex-row">
              <Typography className={styles.confirmation} variant="bodySmall">
                Confirmation Number:{' '}
                <span className="font-bold">{confirmationNumber || 'N/A'}</span>
              </Typography>
              <Typography
                variant="bodySmall"
                className={classNames('lg:ml-auto', styles.ticket)}
              >
                Ticket Number:{' '}
                <span className="font-bold">{ticketNumber || 'N/A'}</span>
              </Typography>
            </div>
          </div>
          <div className="mt-3">
            <div className="mt-3 flex flex-col gap-6 lg:flex-row lg:gap-0">
              <div className="flex  grow">
                <div className="grow">
                  <Typography variant="bodySmall2">
                    {operator || 'Operator: N/A'}
                  </Typography>
                </div>
              </div>
              <div
                className={classNames(
                  'flex flex-col gap-1',
                  styles.optionsBlock
                )}
              >
                {options.map(renderOptions)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainSection;
