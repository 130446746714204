import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { routeMap } from 'constants/general';
import { DevTools } from 'jotai-devtools';

import '@total-typescript/ts-reset';

import { Starting } from './components/pages/Starting/Starting';

import './styles/index.scss';
import { env } from 'env';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false,
    },
  },
});

export const router = createBrowserRouter([
  {
    children: routeMap.map((v) => {
      if (v.path === '/') {
        return { ...v, index: true, path: undefined };
      }
      return { ...v };
    }),
    element: <Starting />,
    path: '/',
  },
]);
createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    {env.REACT_APP_DEVTOOLS === 'true' && <DevTools />}
    <RouterProvider router={router} />
  </QueryClientProvider>
);
