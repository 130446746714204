import classNames from 'classnames';
import { FC, useState } from 'react';

import {
  useOverflowTooltip,
  useRouterQuery,
  useSearchPage,
} from 'hooks/general';

import Button from 'components/common/Button/Button';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import { ReactComponent as SearchIconSvg } from 'styles/icons/search_green.svg';
import palette from 'styles/palette';
import { ISearchEntity } from 'types/search';
import { getFirstAndLast } from 'utils/common';
import styles from './EjectedSearch.module.scss';

import ShortcutHelpers from 'components/common/ShortcutHelpers';
import { useSetAtom } from 'jotai';
import { searchAtom } from 'state/store';
import { ReactComponent as KeyboardShortcutSvg } from 'styles/icons/keyboardShortcut.svg';
import { formatEmail } from 'utils/profile';
import Vip from 'components/common/Vip/Vip';
import ElementWithTooltip from 'components/common/ElementWithTooltip';

const ItemsRow: FC<{
  handleClick: (item: ISearchEntity) => void;
  item: ISearchEntity;
  selected: boolean;
}> = ({ handleClick, item, selected }) => {
  const formattedName = getFirstAndLast({
    firstName: item.FirstName,
    lastName: item.LastName,
  });

  const formattedEmail = formatEmail(item);

  const { withOverflowTooltip: emailElement } = useOverflowTooltip(
    formattedEmail,
    <div className={styles.email} />
  );

  return (
    <div
      className={classNames(styles.searchRow, {
        'bg-grey-1': selected,
      })}
      onClick={() => handleClick(item)}
    >
      <div className={classNames('flex gap-1', styles.name)}>
        <ElementWithTooltip
          strategy="fixed"
          value={formattedName}
          className="whitespace-normal"
        >
          <div />
        </ElementWithTooltip>
        {item.VIP && <Vip value={item.VIP} />}
      </div>
      {emailElement}
      <KeyboardShortcutSvg
        color={selected ? palette['midnight-black'] : palette['grey-6']}
        opacity={selected ? '1' : 0.3}
      />
    </div>
  );
};

export const getParentUrl = () => {
  return window.location.ancestorOrigins[0];
};

export const postMessage = (value: any) => {
  const origin = getParentUrl();
  const ORIGIN_WHITELIST = [
    'fctgcorporate--dataart.sandbox.my.salesforce.com',
    'fctgcorporate--qa.sandbox.lightning.force.com',
    'fctgcorporate--staging.sandbox.my.salesforce.com',
    'fctgcorporate.my.salesforce.com',
    'fctgcorporate--staging.sandbox.lightning.force.com',
  ];
  const validOrigin = ORIGIN_WHITELIST.some((value) => origin?.includes(value));
  if (validOrigin || !origin) {
    parent.postMessage(value, origin);
    return;
  }
  console.error('INVALID ORIGIN');
};
const EjectedSearch: FC = () => {
  const query = useRouterQuery();
  const dispatchSearch = useSetAtom(searchAtom);
  const size = Number(query.size || '4');
  const handleClick = (profile: ISearchEntity) => {
    setLocalTerm(
      getFirstAndLast({
        firstName: profile.FirstName,
        lastName: profile.LastName,
      })
    );
    postMessage({
      data: {
        profile,
      },
      topic: 'sp:search_selected',
    });
    dispatchSearch({ type: 'RESET' });
  };
  const {
    handleChange,
    inputRef,
    message,
    results,
    searchIsPending,
    selectedItem,
    setSelectedItem,
    term,
  } = useSearchPage({
    mapResults: (results: ISearchEntity[]) => results.slice(0, size),
    onChange: (value) => {
      setLocalTerm(value);
    },
    onEnter: (value) => {
      handleClick(value);
    },
  });

  const [localTerm, setLocalTerm] = useState('');
  return (
    <div
      className={classNames(
        styles.page,
        'noMinWidth flex flex-col items-start gap-2'
      )}
    >
      <div className={styles.inputContainer}>
        <div className={styles.inputIcon}>
          {searchIsPending ? (
            <div className={styles.loader}></div>
          ) : (
            <SearchIconSvg />
          )}
        </div>
        <input
          ref={inputRef}
          className={classNames(styles.input, 'mainSearch')}
          placeholder="Start typing to search"
          value={localTerm || term}
          onChange={(e) => handleChange(e.target.value)}
        />
        {term && (
          <Button
            variant="text"
            customPadding
            onClick={() => {
              handleChange('');
              inputRef.current?.focus();
            }}
            className="absolute right-2 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center"
          >
            <CloseSvg height={12} width={12} color={palette['grey-5']} />
          </Button>
        )}
      </div>

      {term === localTerm && message ? (
        <div className={styles.resultsContainer}>
          <div className={classNames(styles.error)}>{message}</div>
          <ShortcutHelpers isError isSearchComponent />
        </div>
      ) : (
        <>
          {!!results.length && (
            <div className={styles.resultsContainer}>
              <div
                className={classNames(
                  styles.searchRow,
                  styles.header,
                  'pr-[36px]'
                )}
              >
                <div className={styles.name}>Name</div>
                <div className={styles.email}>Email</div>
              </div>
              <div className="max-h-[calc(100vh_-_150px)] overflow-auto">
                {results.slice(0, size).map((item, i) => (
                  <div
                    onMouseMove={() => setSelectedItem(i)}
                    key={`${item.TravelerGuid} + ${i}`}
                    data-search-row-index={i}
                  >
                    <ItemsRow
                      item={item}
                      selected={i === selectedItem}
                      handleClick={handleClick}
                    />
                  </div>
                ))}
              </div>
              <ShortcutHelpers isSearchComponent />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EjectedSearch;
