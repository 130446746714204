import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { findLast } from 'ramda';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from 'components/common/Alert/Alert';
import Chevron from 'components/common/Icons/Chevron';
import Typography from 'components/common/Typography/Typography';

import { ReactComponent as SmallRightArrow } from 'styles/icons/chevron_right.svg';
import palette from 'styles/palette';
import { ISearchEntity } from 'types/search';

import { MultipleProfilesHeader } from '../Starting/components/Header/Header';

import { useOverflowTooltip, useRouterQuery, useSearch } from 'hooks/general';
import {
  breadcrumbsAtom,
  prevLocationsAtom,
  requireCallerAuthAtom,
  searchAtom,
} from 'state/store';
import { QueryKey } from 'utils/apiService';
import { pushTravellersOrProfilePage } from 'utils/common';
import { EMAIL_ORDER_ARR, PHONE_ORDER_ARR, formatName } from 'utils/profile';
import styles from './MultipleProfiles.module.scss';

const MultipleProfilesCrumb = ({ isLastCrumb }: { isLastCrumb: boolean }) => {
  const [prevLocations] = useAtom(prevLocationsAtom);
  const navigate = useNavigate();
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const dispatchBreadcrumbs = useSetAtom(breadcrumbsAtom);
  if (requireCallerAuth) {
    return null;
  }
  return (
    <div
      onClick={() => {
        const multipleProfilesPage = findLast(
          (route) => route.pathname.includes('multiple-profiles'),
          prevLocations
        );
        if (!multipleProfilesPage) {
          return;
        }

        const { pathname, search } = multipleProfilesPage;

        dispatchBreadcrumbs({ type: 'POP' });

        navigate({
          pathname,
          search,
        });
      }}
      className={classNames(
        'flex cursor-pointer items-center gap-4',
        !isLastCrumb && 'max-w-[45%]'
      )}
    >
      <MultipleProfilesHeader isBreadcrumb />
      {!isLastCrumb && (
        <Chevron className={styles.chevron} fill={palette['grey-4']} />
      )}
    </div>
  );
};
const MultipleProfiles: FC = () => {
  const query = useRouterQuery();
  const searchPattern = query.email || query.phone || query.searchPattern;
  const [{ value }, dispatchSearch] = useAtom(searchAtom);
  const navigate = useNavigate();
  const searchType =
    'email' in query ? 'Email' : 'phone' in query ? 'Phone' : 'Main';
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const { mutate: startSearch } = useSearch();
  useEffect(() => {
    startSearch({
      key: [QueryKey.MultipleProfiles],
      searchPattern,
      searchType,
    });

    return () => {
      dispatchSearch({ type: 'RESET' });
    };
  }, []); // TODO: This doesn't use replaced and parsed phone from router saga, formatAndValidatePhone

  if (!searchPattern) return null;
  const dispatchBreadcrumbs = useSetAtom(breadcrumbsAtom);

  const onNavigate = (id: string) => () => {
    dispatchBreadcrumbs({
      type: 'ADD',
      value: {
        id: 'multiple-profiles',
        path: undefined,
        query,
        render: (isLastCrumb: boolean) => {
          return <MultipleProfilesCrumb isLastCrumb={isLastCrumb} />;
        },
      },
    });

    pushTravellersOrProfilePage({
      id,
      navigate,
      requireCallerAuth,
    });
  };
  const results =
    value?.Result.map((val) => {
      return { ...val, contactType: getContactType(val, searchPattern) };
    }) || [];
  return (
    <div>
      <Alert type="info">
        <Typography variant="bodyMedium">
          Multiple profiles found for{' '}
          <span className="font-medium">{searchPattern}.</span> Please check the
          details to choose the most suitable one.
        </Typography>
      </Alert>

      <ResultsContainer results={results} onNavigate={onNavigate} />
    </div>
  );
};
type Result = ISearchEntity & { contactType: string };

interface IResultsContainerProps {
  results: Result[];
  onNavigate: Function;
}

const getContactType = (searchEntity: ISearchEntity, searchPattern: string) => {
  if (
    searchEntity.PrimaryEmail?.toLowerCase() === searchPattern?.toLowerCase()
  ) {
    return 'Primary E-mail';
  }
  const phoneNumber = searchEntity?.Phones?.find(
    (val) =>
      searchPattern.includes(val.CountryCode + val.PhoneNumber) ||
      searchPattern.includes(val.PhoneNumber)
  );
  if (phoneNumber) {
    let phoneType = PHONE_ORDER_ARR.find(
      (val) => val.code === phoneNumber.PhoneType
    )?.label;
    if (phoneType && !['Fax', 'Pager'].includes(phoneType as string)) {
      phoneType += ' Phone';
    }
    return phoneType || '';
  }
  const email = searchEntity?.Emails?.find(
    (val) => val?.Email?.toLowerCase() === searchPattern?.toLowerCase()
  );
  if (email) {
    const emailType = EMAIL_ORDER_ARR.find(
      (val) => val.code === email.AddressType
    )?.label;
    if (emailType) {
      return emailType?.includes('E-mail')
        ? `${emailType}`
        : `${emailType} E-mail`;
    }
    return 'Alternative E-mail';
  }
  return 'Primary Phone';
};

const headerTitles = ['Name', 'Position', 'Company', 'Contact Type', ''];
const ResultsContainer: FC<IResultsContainerProps> = ({
  onNavigate,
  results,
}) => {
  return (
    <div className={styles.resultsContainer}>
      <div className={classNames(styles.header)}>
        {headerTitles.map((title) => {
          return (
            <Typography
              color="charcoalGray"
              variant="bodySmall2"
              key={title}
              className={classNames(styles.column, styles.columnHeader)}
            >
              {title}
            </Typography>
          );
        })}
      </div>
      <div className={styles.searchRowsContainer}>
        {results.map((item, i) => (
          <ItemsRow
            item={item}
            key={`${item.TravelerGuid} + ${i}`}
            onNavigate={onNavigate}
          />
        ))}
      </div>
    </div>
  );
};

const ItemsRow = ({ item, onNavigate }: any) => {
  const formattedName = formatName(item).trim() || 'N/A';
  const formattedJobTitle = item.JobTitle || 'N/A';
  const formattedCompany = item.Company.Name || 'N/A';
  const formattedContactType = item.contactType;

  const { withOverflowTooltip: nameElement } = useOverflowTooltip(
    formattedName,
    <Typography variant="bodyMedium2" className={styles.column} />
  );

  const { withOverflowTooltip: jobElement } = useOverflowTooltip(
    formattedJobTitle,
    <Typography variant="bodyMedium" className={styles.column} />
  );

  const { withOverflowTooltip: companyElement } = useOverflowTooltip(
    formattedCompany,
    <Typography variant="bodyMedium" className={styles.column} />
  );

  const { withOverflowTooltip: typeElement } = useOverflowTooltip(
    formattedContactType,
    <Typography variant="bodyMedium" className={styles.column}>
      {formattedContactType}
    </Typography>
  );

  return (
    <div
      className={styles.searchRow}
      onClick={onNavigate(item.TravelerGuid, item.IsArranger)}
    >
      {nameElement}
      {jobElement}
      {companyElement}
      {typeElement}
      <Typography variant="bodyMedium" className={styles.column}>
        <SmallRightArrow />
      </Typography>
    </div>
  );
};

export default MultipleProfiles;
