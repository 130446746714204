const Hotel = ({ fill = '#9CA5AE', transform = '', ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 16.5013C0.666504 16.0411 1.0396 15.668 1.49984 15.668H16.4998C16.9601 15.668 17.3332 16.0411 17.3332 16.5013C17.3332 16.9615 16.9601 17.3346 16.4998 17.3346H1.49984C1.0396 17.3346 0.666504 16.9615 0.666504 16.5013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 5.66797C5.6665 5.20773 6.0396 4.83464 6.49984 4.83464H7.33317C7.79341 4.83464 8.1665 5.20773 8.1665 5.66797C8.1665 6.12821 7.79341 6.5013 7.33317 6.5013H6.49984C6.0396 6.5013 5.6665 6.12821 5.6665 5.66797Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 9.0013C5.6665 8.54107 6.0396 8.16797 6.49984 8.16797H7.33317C7.79341 8.16797 8.1665 8.54107 8.1665 9.0013C8.1665 9.46154 7.79341 9.83464 7.33317 9.83464H6.49984C6.0396 9.83464 5.6665 9.46154 5.6665 9.0013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 12.3346C5.6665 11.8744 6.0396 11.5013 6.49984 11.5013H7.33317C7.79341 11.5013 8.1665 11.8744 8.1665 12.3346C8.1665 12.7949 7.79341 13.168 7.33317 13.168H6.49984C6.0396 13.168 5.6665 12.7949 5.6665 12.3346Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83317 5.66797C9.83317 5.20773 10.2063 4.83464 10.6665 4.83464H11.4998C11.9601 4.83464 12.3332 5.20773 12.3332 5.66797C12.3332 6.12821 11.9601 6.5013 11.4998 6.5013H10.6665C10.2063 6.5013 9.83317 6.12821 9.83317 5.66797Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83317 9.0013C9.83317 8.54107 10.2063 8.16797 10.6665 8.16797H11.4998C11.9601 8.16797 12.3332 8.54107 12.3332 9.0013C12.3332 9.46154 11.9601 9.83464 11.4998 9.83464H10.6665C10.2063 9.83464 9.83317 9.46154 9.83317 9.0013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83317 12.3346C9.83317 11.8744 10.2063 11.5013 10.6665 11.5013H11.4998C11.9601 11.5013 12.3332 11.8744 12.3332 12.3346C12.3332 12.7949 11.9601 13.168 11.4998 13.168H10.6665C10.2063 13.168 9.83317 12.7949 9.83317 12.3346Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83317 2.33464C4.61216 2.33464 4.4002 2.42243 4.24392 2.57871C4.08764 2.73499 3.99984 2.94695 3.99984 3.16797V16.5013C3.99984 16.9615 3.62674 17.3346 3.1665 17.3346C2.70627 17.3346 2.33317 16.9615 2.33317 16.5013V3.16797C2.33317 2.50493 2.59656 1.86904 3.0654 1.4002C3.53424 0.931361 4.17013 0.667969 4.83317 0.667969H13.1665C13.8295 0.667969 14.4654 0.931361 14.9343 1.4002C15.4031 1.86904 15.6665 2.50493 15.6665 3.16797V16.5013C15.6665 16.9615 15.2934 17.3346 14.8332 17.3346C14.3729 17.3346 13.9998 16.9615 13.9998 16.5013V3.16797C13.9998 2.94696 13.912 2.73499 13.7558 2.57871C13.5995 2.42243 13.3875 2.33464 13.1665 2.33464H4.83317Z"
        fill={fill}
      />
    </svg>
  );
};

export default Hotel;
