import classNames from 'classnames';
import CopyButton from 'components/common/CopyButton/CopyButton';
import Loader from 'components/common/Loader';
import Typography from 'components/common/Typography/Typography';
import { useProfile } from 'hooks/profile';
import { FC, ReactNode, useEffect } from 'react';
import { IProfileAPI } from 'types/profile';
import { isNA } from 'utils/common';
import { sendGoogleEvent } from 'utils/googleTag';
import CompanyInternalNotes from '../Profile/components/InternalNotes/CompanyInternalNotes';
import CompanyDocs from './components/CompanyDocs';

const Label: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="bodySmall" color="charcoalGray">
      {children}
    </Typography>
  );
};

const Value: FC<{ children: ReactNode | string }> = ({ children }) => {
  return (
    <Typography variant="bodyMedium2" className="wrap" color="midnight-black">
      {children}
    </Typography>
  );
};

export const formatCompanyResponse = (company: IProfileAPI['Company']) => {
  const formattedCompany: Partial<IProfileAPI['Company']> = {};
  let key: keyof typeof formattedCompany;

  for (key in company) {
    formattedCompany[key] = company[key] || 'N/A';
  }
  const unformattedGdsName = formattedCompany.GDSName?.toLowerCase();
  let GDSName = formattedCompany.GDSName;
  if (GDSName === '<Select>' || GDSName === 'None') {
    GDSName = 'N/A';
  } else if (unformattedGdsName?.includes('amadeus')) {
    GDSName = 'Amadeus';
  } else if (unformattedGdsName?.includes('sabre')) {
    GDSName = 'Sabre';
  }

  return {
    ...formattedCompany,
    GDSName,
  } as Required<IProfileAPI['Company']>;
};

const Company: FC = () => {
  const { companyGuid, guid, rawCompany } = useProfile()[0].value;

  useEffect(() => {
    sendGoogleEvent('open_company');
  }, []);

  if (guid === 'N/A' || !companyGuid) return <Loader />;
  return (
    <div className="flex gap-4 py-4">
      <div className={classNames('flex w-[184px] shrink-0 flex-col gap-2 ')}>
        <div>
          <Label>Company Name</Label>
          <Value>{rawCompany.Name}</Value>
        </div>
        <div>
          <Label>Company ID</Label>
          <Value>{rawCompany.CompanyGuid}</Value>
        </div>
        <div className="flex flex-col ">
          <Label>PCC / OID</Label>
          {!isNA(rawCompany.PseudoCity) ? (
            <div className="flex items-center gap-1">
              <Value>{rawCompany.PseudoCity}</Value>
              <CopyButton value={rawCompany.PseudoCity} />
            </div>
          ) : (
            <Value>N/A</Value>
          )}
        </div>
      </div>
      <div className="flex grow flex-col gap-4 overflow-hidden lg:flex-row">
        <CompanyDocs />
        <CompanyInternalNotes />
      </div>
    </div>
  );
};
export default Company;
