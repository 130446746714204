import { format } from 'date-fns/esm';
import { FC, useState } from 'react';

import Alert, { AlertComponentType } from 'components/common/Alert/Alert';
import Chevron from 'components/common/Icons/Chevron';
import Typography from 'components/common/Typography/Typography';
import { Alert as TAlert } from 'types/trips';

import { BREAKPOINTS } from 'constants/general';
import { useWindowSize } from 'usehooks-ts';

const formatDateForAlert = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const formatedStart = format(start, 'd MMM yyyy');
  const formatedEnd = format(end, 'd MMM yyyy');
  return `${formatedStart} - ${formatedEnd}`;
};

const ExpandableAlert: FC<{ alert: TAlert }> = ({ alert }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowSize();
  let alertLevel = alert.Level.toLowerCase() as AlertComponentType | 'critical';
  if (alertLevel === 'critical') alertLevel = 'error';
  return (
    <Alert type={alertLevel} className="grow" variant="filled" iconSize={16}>
      <div className="flex flex-col justify-between  lg:flex-row">
        {width >= BREAKPOINTS.LG ? (
          <div className="flex max-w-[60%]">
            <Typography variant="bodyMedium" data-e2e-title="trip-alert">
              {alert.Category}: {alert.Title}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col">
            <Typography variant="bodyMedium" data-e2e-title="trip-alert">
              {alert.Category}:{' '}
            </Typography>
            <Typography variant="bodyMedium" data-e2e-title="trip-alert">
              {alert.Title}
            </Typography>
          </div>
        )}
        <div className="flex items-baseline gap-2">
          <Typography variant="bodyMedium" data-e2e-date="trip-alert">
            {formatDateForAlert(alert.StartAt, alert.EndAt)}
          </Typography>
          <div
            className="ml-auto flex cursor-pointer items-center gap-2 lg:ml-0"
            onClick={() => setIsExpanded((expanded) => !expanded)}
          >
            <Typography variant="bodyMedium2">Details</Typography>
            <Chevron
              transform={isExpanded ? 'rotate(-90)' : 'rotate(90)'}
              fill="black"
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <Typography
          variant="bodySmall"
          className="pt-2 lg:max-w-[60%]"
          data-e2e-description="trip-alert"
        >
          {alert.Description}
        </Typography>
      )}
    </Alert>
  );
};

export default ExpandableAlert;
