import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { cva } from 'cva';
import { FC, useState } from 'react';

import Button from 'components/common/Button/Button';
import RequiredStar from 'components/common/RequiredStar/RequiredStar';
import Typography from 'components/common/Typography/Typography';
import { useMinLoadingTime } from 'hooks/general';
import { apiService } from 'utils/apiService';

import Checkbox, { useCheckbox } from 'components/common/Checkbox/Checkbox';
import Loader from 'components/common/Loader';
import { postMessage } from 'components/pages/EjectedSearch/EjectedSearch';
import { useProfile } from 'hooks/profile';
import { resizeTextArea } from 'utils/common';

interface IAuthenticationQuestion {
  Key: string;
  Value: string;
}

type Response = {
  AuthenticationQuestions: IAuthenticationQuestion[];
  RequiredNumberOfCorrectAnswers: number;
};
const getCallerAuthFields = async (id: string) => {
  return apiService<Response>({
    method: 'get',
    url: `profile/${id}/callerauthenticationquestions`,
  });
};

export const RequireCallerAuth = () => {
  const [answeredQuestions, setAnsweredQuestions] = useState<
    IAuthenticationQuestion[]
  >([]);
  const profile = useProfile()[0].value;

  const { data, isFetching } = useQuery({
    enabled: profile.guid !== 'N/A',
    queryFn: () => getCallerAuthFields(profile.guid),
    queryKey: ['getCallerAuthFields'],
  });
  const requiredCount = data?.RequiredNumberOfCorrectAnswers ?? 0;
  const [reasonText, setReasonText] = useState('');

  const [minLoadingDone] = useMinLoadingTime(1000);

  const isLoading =
    !data || isFetching || profile.guid === 'N/A' || !minLoadingDone;
  if (isLoading) {
    return <Loader e2e="caller_auth_loader" />;
  }

  return (
    <div
      className="flex grow flex-col gap-2"
      data-e2e-page="caller-authentication"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1 bg-clarity-white p-3">
          <div>
            <div className="flex justify-between">
              <Typography variant="bodyMedium" data-e2e-id="description">
                Please ask the caller for the information below to authenticate.
              </Typography>

              <div className="flex gap-1">
                <Typography variant="bodyMedium" data-e2e-id="itemsRequired">
                  {requiredCount} items required
                </Typography>
                <RequiredStar variant="bodyMedium" />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {data.AuthenticationQuestions.filter(({ Value }) => !!Value).map(
              ({ Key, Value }) => (
                <AuthenticationQuestion
                  key={Key}
                  name={Key}
                  value={Value}
                  setAnswered={(checked: boolean) => {
                    if (checked) {
                      setAnsweredQuestions([
                        ...answeredQuestions,
                        { Key, Value },
                      ]);
                    } else {
                      setAnsweredQuestions([
                        ...answeredQuestions.filter(
                          (value) => value.Key !== Key
                        ),
                      ]);
                    }
                  }}
                />
              )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1 bg-clarity-white p-4">
          <Typography variant="bodyMedium">
            Unable to authenticate using the choices above?
          </Typography>
          <textarea
            className="min-h-[56px] resize-none overflow-y-hidden border border-solid border-[$grey-5] bg-clarity-white p-2"
            value={reasonText}
            onChange={(e) => {
              resizeTextArea(e.target);
              if (e.target.value.length > 1000) return;
              setReasonText(e.target.value);
            }}
          />
          <Typography variant="bodySmall" color="grey-6">
            Please describe how you authenticate the caller.
          </Typography>
        </div>
      </div>
      <div className="sticky bottom-0 m-[-16px] mt-auto border-t border-solid border-grey-2 bg-clarity-white p-4">
        <Button
          variant="primary"
          className="ml-auto"
          disabled={answeredQuestions.length < requiredCount && !reasonText}
          onClick={() => {
            const message = {
              data: {
                AnsweredQuestions: answeredQuestions,
                FallbackReason: reasonText,
                RequiredNumberOfCorrectAnswers: requiredCount,
                TravelerGuid: profile.guid,
              },
              topic: 'sp:caller-auth',
            };
            postMessage(message);
          }}
        >
          Authenticate Caller
        </Button>
      </div>
    </div>
  );
};
const style = cva(
  `relative flex cursor-pointer justify-between border border-b-0 border-solid border-grey-2 bg-clarity-white 
   p-3 last:border-b hover:bg-[#fafafa] group`,
  {
    variants: {
      checked: { true: 'bg-green-highlight hover:bg-green-highlight' },
    },
  }
);
const AuthenticationQuestion: FC<{
  name: string;
  value: string;
  setAnswered: (checked: boolean) => void;
}> = ({ name, setAnswered, value }) => {
  const { checked, setChecked } = useCheckbox();

  const onChange = () => {
    setChecked(!checked);
    setAnswered(!checked);
  };

  return (
    <div className={style({ checked })} onClick={onChange}>
      <div
        id="indicator"
        className={classNames(
          'absolute left-0 top-0 h-full w-1 group-hover:bg-grey-4',
          checked && 'bg-deep-green group-hover:bg-deep-green'
        )}
      />
      <Typography variant="bodyMedium" className="basis-[45%]">
        {name}
      </Typography>
      <Typography variant="bodyMedium2" className="wrap basis-[45%]	">
        {value || 'N/A'}
      </Typography>
      <Checkbox checked={checked} setChecked={onChange} />
    </div>
  );
};

export default RequireCallerAuth;
