import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useFutureAndPastTrips } from 'hooks/trips';

import { useCurrentSection } from 'hooks/general';
import { useProfile } from 'hooks/profile';
import styles from './Menu.module.scss';

interface IMenuItem {
  symbol: string;
  title: string;
  path?: string;
  section?: string;
  disabled?: boolean;
}
const Menu = () => {
  const profile = useProfile()[0].value;

  const { futureTrips, pastTrips } = useFutureAndPastTrips(profile.guid);
  const trips = [...futureTrips, ...pastTrips];
  const currentSection = useCurrentSection();
  const navigate = useNavigate();

  const menuItems = [
    {
      id: 'Profile',
      path: '/profile',
      section: 'profile',
      symbol: 'P',
      title: 'Profile',
    },
    {
      disabled: !trips.length,
      id: 'DetailedTrips',
      path: `/detailed-trips/${trips[0]?.Id}`,
      section: 'detailed-trips',
      symbol: 'D',
      title: 'Detailed Trips',
    },
    {
      id: 'CompanyProfile',
      path: `/company/${profile?.companyGuid}`,
      section: 'company',
      symbol: 'O',
      title: 'Company Profile',
    },
  ];

  if (profile.isArranger) {
    menuItems.unshift({
      id: 'Travellers',
      path: '/travellers',
      section: 'travellers',
      symbol: 'A',
      title: 'Travellers',
    });
  }
  const handleClick = (item: IMenuItem) => () => {
    if (item.section === currentSection) return;

    if (item.path && !item.disabled) {
      navigate({ pathname: item.path, search: window.location.search });
    }
  };
  return (
    <>
      <div className={styles.menuContainer}>
        <div className={styles.menu}>
          {menuItems.map((item) => {
            const disabled = !item.path || item.disabled;
            return (
              <div
                id={item.id}
                className={classNames(styles.menuItem, {
                  [styles.activeItem]: item.section === currentSection,
                  [styles.disabledItem]: disabled,
                })}
                key={item.title}
                onClick={handleClick(item)}
              >
                <div
                  className={classNames(styles.symbol, {
                    [styles.activeBox]: item.section === currentSection,
                    [styles.disabledBox]: disabled,
                  })}
                >
                  {item.symbol}
                </div>
                <div>{item.title}</div>
              </div>
            );
          })}
        </div>

        {/* <div className={styles.statusContainer}>
                Profile up to date
                <img src={Check} alt="check" />
                <div className={styles.npsContainer}>
                  <div className={styles.nps}>NPS</div>
                  <div className={styles.number}>7</div>
                </div>
              </div> */}
      </div>
      <div className={styles.line} />
    </>
  );
};
export default Menu;
