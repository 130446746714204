import palette from 'styles/palette';

export const SearchIcon = ({ active, className }: any) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0784 11.0216C9.05202 11.8426 7.75009 12.3335 6.3335 12.3335C3.01979 12.3335 0.333496 9.64721 0.333496 6.3335C0.333496 3.01979 3.01979 0.333496 6.3335 0.333496C9.64721 0.333496 12.3335 3.01979 12.3335 6.3335C12.3335 7.75026 11.8425 9.05233 11.0213 10.0788L13.4715 12.529C13.7319 12.7894 13.7319 13.2115 13.4715 13.4718C13.2112 13.7322 12.789 13.7322 12.5287 13.4718L10.0784 11.0216ZM1.66683 6.3335C1.66683 3.75617 3.75617 1.66683 6.3335 1.66683C8.91083 1.66683 11.0002 3.75617 11.0002 6.3335C11.0002 7.5912 10.5026 8.7327 9.69363 9.57191C9.67108 9.5893 9.64937 9.60834 9.62869 9.62902C9.60803 9.64969 9.589 9.67137 9.57162 9.69391C8.73244 10.5027 7.59106 11.0002 6.3335 11.0002C3.75617 11.0002 1.66683 8.91083 1.66683 6.3335Z"
      fill={active ? palette['deep-green'] : palette['grey-6']}
    />
  </svg>
);
