import { UseMutateFunction, useMutationState } from '@tanstack/react-query';
import classNames from 'classnames';
import Button from 'components/common/Button/Button';
import EditedInfoPopover from 'components/common/EditedInfoPopover';
import ElementWithTooltip from 'components/common/ElementWithTooltip';
import OptionsPopover, {
  IOptionsRenderProps,
} from 'components/common/OptionsPopover';
import Typography from 'components/common/Typography/Typography';
import NoteSpinnerOverlay from 'components/pages/Profile/components/InternalNotes/NoteSpinnerOverlay';
import { format } from 'date-fns/esm';
import { ICompanyDoc } from 'hooks/company';
import { useProfile } from 'hooks/profile';
import { useSetAtom } from 'jotai';
import { FC, MutableRefObject, useState } from 'react';
import { ReactComponent as DeleteSvg } from 'styles/icons/delete.svg';
import { ReactComponent as EditSvg } from 'styles/icons/edit.svg';
import palette from 'styles/palette';
import CompanyDocInput from './CompanyDocInput';
import { confirmDeleteCompanyDocOpenAtom } from './CompanyDocs';

export const useIsEditingOrDeletingCompanyDoc = (
  documentGuid?: ICompanyDoc['documentGuid']
) => {
  if (!documentGuid) {
    return false;
  }
  const isEditingCompanyDoc = useMutationState({
    filters: { mutationKey: ['editCompanyDoc'], status: 'pending' },
    select: (mutation) =>
      (mutation?.state?.variables as { documentGuid: string })?.documentGuid ===
      documentGuid,
  }).filter(Boolean);
  const isDeletingCompanyDoc = useMutationState({
    filters: { mutationKey: ['deleteCompanyDoc'], status: 'pending' },
    select: (mutation) => {
      return (mutation?.state?.variables as string) === documentGuid;
    },
  }).filter(Boolean);

  return !!(isEditingCompanyDoc.length || isDeletingCompanyDoc.length);
};
const DocumentItem: FC<
  ICompanyDoc & {
    className?: string;
    retry: MutableRefObject<(() => void) | undefined>;
    addCompanyDoc: UseMutateFunction<
      any,
      unknown,
      {
        title: string;
        url: string;
      },
      unknown
    >;
    editCompanyDoc: UseMutateFunction<
      any,
      unknown,
      {
        documentGuid: string;
        title: string;
        url: string;
      },
      unknown
    >;
  }
> = ({
  className,
  createdAtUtc,
  createdBy,
  documentGuid,
  title,
  updatedAtUtc,
  updatedBy,
  url,
  ...rest
}) => {
  const profile = useProfile()[0].value;
  const setConfirmDeleteCompanyDocOpen = useSetAtom(
    confirmDeleteCompanyDocOpenAtom
  );
  const [isEdited, setIsEdited] = useState(false);
  const isEditingOrDeletingCompanyDoc =
    useIsEditingOrDeletingCompanyDoc(documentGuid);

  if (isEdited) {
    return (
      <CompanyDocInput
        {...{
          createdAtUtc,
          createdBy,
          documentGuid,
          title,
          updatedAtUtc,
          updatedBy,
          url,
          ...rest,
        }}
        mode="EDIT"
        onClose={() => setIsEdited(false)}
      />
    );
  }
  return (
    <div
      className={classNames(
        className,
        'relative flex items-end justify-between gap-2 p-3'
      )}
    >
      <div
        className="flex grow cursor-pointer flex-col gap-1 overflow-hidden"
        onClick={() => window.open(url)}
      >
        <ElementWithTooltip value={title} variant="bodySmall">
          <Typography variant="bodyMedium2" />
        </ElementWithTooltip>
        <div className="flex ">
          <ElementWithTooltip value={createdBy} variant="bodySmall">
            <Typography variant="bodySmall2" color="charcoalGray" />
          </ElementWithTooltip>
          <Typography
            variant="bodySmall2"
            color="charcoalGray"
            className="shrink-0"
          >
            ,
          </Typography>
          <Typography
            variant="bodySmall2"
            color="charcoalGray"
            className="ml-1 shrink-0"
          >
            {format(new Date(createdAtUtc), 'd MMM yyyy, hh:mm a')}
          </Typography>
        </div>
      </div>
      <div className="flex h-full items-end gap-2">
        {updatedBy && updatedAtUtc && (
          <EditedInfoPopover
            updatedAt={format(new Date(updatedAtUtc), 'd MMM yyyy, hh:mm a')}
            updatedBy={updatedBy}
          />
        )}
        <OptionsPopover className="flex w-5 justify-center">
          {({ close }: IOptionsRenderProps) => (
            <div className={'flex flex-col whitespace-nowrap px-0 py-1'}>
              <Button
                variant="text"
                className={'flex shrink-0 items-center gap-2 p-2 '}
                onClick={(e) => {
                  setIsEdited(true);
                  close();
                  e.stopPropagation();
                }}
              >
                <EditSvg color={palette['grey-6']} />
                <Typography variant="bodyMedium" color="charcoalGray">
                  Edit Document
                </Typography>
              </Button>
              <Button
                variant="text"
                className={'flex shrink-0 items-center gap-2 p-2 '}
                onClick={(e) => {
                  setConfirmDeleteCompanyDocOpen({
                    companyGuid: profile.companyGuid as string,
                    documentGuid,
                    title,
                    url,
                  });
                  close();
                  e.stopPropagation();
                }}
              >
                <DeleteSvg color={palette['grey-6']} />
                <Typography variant="bodyMedium" color="charcoalGray">
                  Delete Document
                </Typography>
              </Button>
            </div>
          )}
        </OptionsPopover>
      </div>
      {isEditingOrDeletingCompanyDoc && <NoteSpinnerOverlay />}
    </div>
  );
};
export default DocumentItem;
