import palette from 'styles/palette';

const Chevron = ({
  className = '',
  fill = palette['grey-6'],
  transform = '',
}: {
  transform?: string;
  className?: string;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={transform}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08893 5.41693C7.41437 5.74237 7.41437 6.27 7.08893 6.59544L2.08893 11.5954C1.76349 11.9209 1.23585 11.9209 0.910418 11.5954C0.58498 11.27 0.584981 10.7424 0.910418 10.4169L5.32116 6.00619L0.910418 1.59544C0.584981 1.27 0.584981 0.742366 0.910418 0.416929C1.23585 0.091492 1.76349 0.0914921 2.08893 0.416929L7.08893 5.41693Z"
        fill={fill}
      />
    </svg>
  );
};

export default Chevron;
