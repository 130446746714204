import { UseMutateFunction } from '@tanstack/react-query';
import classNames from 'classnames';
import Button from 'components/common/Button/Button';
import Input, { useInput } from 'components/common/Input';
import RequiredStar from 'components/common/RequiredStar/RequiredStar';
import TextArea, { useTextArea } from 'components/common/TextArea';
import Typography from 'components/common/Typography/Typography';
import NoteSpinnerOverlay from 'components/pages/Profile/components/InternalNotes/NoteSpinnerOverlay';
import { format } from 'date-fns';
import { ICompanyDoc } from 'hooks/company';
import { useProfile } from 'hooks/profile';
import { useSetAtom } from 'jotai';
import { FC, MutableRefObject } from 'react';
import { ReactComponent as TrashSvg } from 'styles/icons/trash.svg';
import palette from 'styles/palette';
import { confirmDeleteCompanyDocOpenAtom } from './CompanyDocs';
import { useIsEditingOrDeletingCompanyDoc } from './DocumentItem';

export const validateUrl = (value: string) => {
  try {
    return !new URL(value);
  } catch (e) {
    return 'Link is not valid';
  }
};

export const getValidateMaxChars = (maxChars: number) => (value: string) => {
  return (
    value?.length > maxChars &&
    `Limit of ${maxChars} characters is exceeded by ${value?.length - maxChars}`
  );
};

const LINK_MAX_CHARS = 2083;
const TITLE_MAX_CHARS = 500;

const DocumentField: FC<{
  label: string;
  Component: any;
  props: any;
}> = ({ Component, label, props }) => (
  <div className="flex grow flex-col">
    <div className="flex gap-1 ">
      <Typography variant="bodySmall2" color="charcoalGray" className="pb-2">
        {label}
      </Typography>
      <RequiredStar variant="bodyMedium" />
    </div>
    <Component {...props} />
  </div>
);

const DocumentInfo: FC<{ createdBy: string; createdAtUtc: string }> = ({
  createdAtUtc,
  createdBy,
}) => (
  <div className="flex flex-col gap-1">
    <Typography variant="bodySmall" color="charcoalGray">
      Document added by{' '}
      <span className="break-words font-bold">{createdBy}</span>
    </Typography>
    <Typography variant="bodySmall" color="charcoalGray">
      {format(new Date(createdAtUtc), 'EEE d MMM, hh:mm aaa')}
    </Typography>
  </div>
);
type ActionButtonsProps = (
  | {
      mode: 'EDIT';
      id: string;
    }
  | {
      mode: 'ADD';
    }
) & {
  disabled: boolean;
  inputProps: any;
  textAreaProps: any;
  onClose: () => void;
  retry: MutableRefObject<(() => void) | undefined>;
  editCompanyDoc: UseMutateFunction<
    any,
    unknown,
    {
      documentGuid: string;
      title: string;
      url: string;
    },
    unknown
  >;
  addCompanyDoc: UseMutateFunction<
    any,
    unknown,
    {
      title: string;
      url: string;
    },
    unknown
  >;
};
const ActionButtons: FC<ActionButtonsProps> = (props) => {
  const { value: profile } = useProfile()[0];
  const {
    addCompanyDoc,
    disabled,
    editCompanyDoc,
    inputProps,
    mode,
    onClose,
    retry,
    textAreaProps,
  } = props;
  const setConfirmDeleteCompanyDocOpen = useSetAtom(
    confirmDeleteCompanyDocOpenAtom
  );
  return (
    <div className="flex justify-start gap-2">
      {mode === 'EDIT' ? (
        <>
          <Button
            variant="primary"
            disabled={disabled}
            onClick={async () => {
              onClose();
              editCompanyDoc({
                documentGuid: props.id,
                title: inputProps.value,
                url: textAreaProps.value,
              });
              retry.current = () => {
                editCompanyDoc({
                  documentGuid: props.id,
                  title: inputProps.value,
                  url: textAreaProps.value,
                });
              };
            }}
          >
            Update Document
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="ml-auto flex gap-1"
            customPadding
            variant="text"
            onClick={async () => {
              setConfirmDeleteCompanyDocOpen({
                companyGuid: profile.companyGuid as string,
                documentGuid: props.id,
                title: inputProps.value,
                url: textAreaProps.value,
              });
            }}
          >
            <TrashSvg color={palette['midnight-black']} />
            Delete Document
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="primary"
            disabled={disabled}
            onClick={async () => {
              onClose();

              addCompanyDoc({
                title: inputProps.value,
                url: textAreaProps.value,
              });
              retry.current = () => {
                addCompanyDoc({
                  title: inputProps.value,
                  url: textAreaProps.value,
                });
              };
            }}
          >
            Add Document
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

const CompanyDocInput: FC<
  | {
      className?: string;
      onClose: () => void;
      retry: MutableRefObject<(() => void) | undefined>;
      addCompanyDoc: UseMutateFunction<
        any,
        unknown,
        {
          title: string;
          url: string;
        },
        unknown
      >;
      editCompanyDoc: UseMutateFunction<
        any,
        unknown,
        {
          documentGuid: string;
          title: string;
          url: string;
        },
        unknown
      >;
    } & (
      | ({
          mode: 'EDIT';
        } & ICompanyDoc)
      | { mode: 'ADD' }
    )
> = (props) => {
  const inputProps = useInput({
    errorRules: [getValidateMaxChars(TITLE_MAX_CHARS)],
    initialValue: props.mode === 'EDIT' ? props.title : '',
  });
  const textAreaProps = useTextArea({
    errorRules: [getValidateMaxChars(LINK_MAX_CHARS), validateUrl],
    initialValue: props.mode === 'EDIT' ? props.url : '',
    placeholder: '',
  });

  const { className, onClose } = props;
  const hasError = !!inputProps.errors.length || !!textAreaProps.errors.length;
  const isUpdateDisabled =
    props.mode === 'EDIT' &&
    inputProps.value === props.title &&
    textAreaProps.value === props.url;
  const disabled =
    !inputProps.value.trim() ||
    !textAreaProps.value.trim() ||
    hasError ||
    isUpdateDisabled;

  const isEditingOrDeletingDoc = useIsEditingOrDeletingCompanyDoc(
    props.mode === 'EDIT' ? props.documentGuid : ''
  );
  return (
    <div
      className={classNames(
        className,
        'relative flex flex-col gap-2 p-3 outline outline-2 outline-deep-green'
      )}
    >
      <Typography variant="bodyMedium2">
        {props.mode === 'EDIT' ? 'Edit Document' : 'Add Document'}
      </Typography>

      <DocumentField
        label="Document Title"
        Component={Input}
        props={{ className: 'grow', ...inputProps }}
      />
      <DocumentField
        label="Document Link"
        Component={TextArea}
        props={{ className: 'w-full', ...textAreaProps }}
      />

      {props.mode === 'EDIT' && (
        <DocumentInfo
          createdBy={props.createdBy}
          createdAtUtc={props.createdAtUtc}
        />
      )}

      <ActionButtons
        {...{
          addCompanyDoc: props.addCompanyDoc,
          disabled,
          editCompanyDoc: props.editCompanyDoc,
          inputProps,
          mode: props.mode as any,
          onClose,
          retry: props.retry,
          textAreaProps,
        }}
        {...(props.mode === 'EDIT' ? { id: props.documentGuid } : {})}
      />
      {isEditingOrDeletingDoc && <NoteSpinnerOverlay />}
    </div>
  );
};
export default CompanyDocInput;
