import classNames from 'classnames';
import { pathOr } from 'ramda';
import { CSSProperties, Fragment, MouseEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import External from 'styles/icons/external.svg';
import SmallRightArrow from 'styles/icons/small_right_arrow.svg';
import { getTravellersOrProfilePage, isNA } from 'utils/common';

import CopyButton from '../CopyButton/CopyButton';
import Chevron from '../Icons/Chevron';
import Vip from '../Vip/Vip';

import { useOverflowTooltip } from 'hooks/general';
import styles from './Table.module.scss';
import ElementWithTooltip from '../ElementWithTooltip';

interface IColumn {
  text?: string;
  field: string;
  width: string;
  style?: CSSProperties;
  headStyle?: CSSProperties;
}

interface ITableProps<Type> {
  columns: IColumn[];
  data: Type[];
}

const NameCell = <Type extends { TravelerGuid: string; VIP: string }>({
  row,
  text,
}: {
  row: Type;
  text: string;
}) => {
  return (
    <div className={styles.nameField}>
      <ElementWithTooltip value={text}>
        <span className="textWrap relative" />
      </ElementWithTooltip>
      {row.VIP && <Vip className="ml-1" value={row.VIP} />}
    </div>
  );
};

const LocatorCell = ({ text }: { text: string }) => {
  const locatorRef = useRef(null);
  const { withOverflowTooltip: nameElement } = useOverflowTooltip(
    text,
    <div className={styles.inlineContainer}>
      <span
        className="max-w-[calc(100%_-_20px)] overflow-hidden text-ellipsis whitespace-nowrap"
        ref={locatorRef}
      >
        {text}
      </span>
      {!isNA(text) && (
        <CopyButton
          className={classNames(styles.copyBtn, 'copy')}
          value={text}
        />
      )}
    </div>,
    false
  );
  return nameElement;
};

const Cell = <Type extends { TravelerGuid: string; VIP: string }>({
  column,
  row,
}: {
  row: Type;
  column: IColumn;
}) => {
  const text: string = pathOr('', [column.field], row);
  const { withOverflowTooltip } = useOverflowTooltip(
    text,
    <div className="textWrap">
      <span className=" whitespace-nowrap">{text}</span>
    </div>
  );
  switch (column.field) {
    case 'dates':
      return <>{pathOr([], [column.field], row).join(' - ')}</>;
    case 'route':
      return (
        <div className={styles.inlineContainer}>
          {pathOr([], [column.field], row).map((item, idx, arr) => (
            <Fragment key={idx}>
              <span>{item}</span>
              {idx + 1 < arr.length ? (
                <span>
                  <img src={SmallRightArrow} alt="SmallRightArrow" />
                </span>
              ) : null}
            </Fragment>
          ))}
        </div>
      );
    case 'sabre':
      return (
        <div className={styles.inlineContainer}>
          <span> {text}</span>
          <CopyButton value={text} />
        </div>
      );
    case 'action':
      return (
        <div className={styles.expander}>
          <Chevron />
        </div>
      );
    case 'sherpa':
      return <img src={External} alt="External" />;
    case 'locator': // TODO: review and remove later
      return <LocatorCell text={text} />;
    case 'name':
      return <NameCell row={row} text={text} />;
    default:
      return withOverflowTooltip;
  }
};

const Row = <Type extends { TravelerGuid: string; VIP: string }>({
  columns,
  goToProfile,
  idx,
  row,
}: {
  columns: IColumn[];
  row: Type;
  idx: number;
  goToProfile: (id: string) => (event: MouseEvent<HTMLTableRowElement>) => void;
}) => {
  return (
    <Fragment key={idx}>
      <tr
        onClick={goToProfile(row.TravelerGuid)}
        className={classNames(styles.row)}
        key={`${idx}-parent`}
      >
        {columns.map((column) => {
          return (
            <td key={`${idx}-${column.field}`} style={column.style || {}}>
              <Cell row={row} column={column} />
            </td>
          );
        })}
      </tr>
    </Fragment>
  );
};

const TableHead = ({ columns }: { columns: IColumn[] }) => {
  return (
    <thead className={styles.head}>
      <tr>
        {columns.map((column) => (
          <th key={column.field} style={column.headStyle || {}}>
            <span className={classNames(styles.headerText)}>{column.text}</span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const ColGroup = ({ columns }: { columns: IColumn[] }) => {
  return (
    <colgroup>
      {columns.map((column) => (
        <col style={{ width: column.width }} key={column.field}></col>
      ))}
    </colgroup>
  );
};

function Table<Type extends { TravelerGuid: string; VIP: string }>({
  columns,
  data,
}: ITableProps<Type>) {
  const navigate = useNavigate();

  const goToProfile =
    (id: string) => (event: MouseEvent<HTMLTableRowElement>) => {
      if (event.currentTarget.classList.contains('copy')) return; // TODO: remove later
      navigate(getTravellersOrProfilePage(id));
    };

  return (
    <table className={styles.table}>
      <ColGroup columns={columns} />
      <TableHead columns={columns} />
      <tbody className={styles.body}>
        {data.map((row, idx) => {
          return (
            <Row
              key={idx}
              columns={columns}
              row={row}
              idx={idx}
              goToProfile={goToProfile}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
