const palette = {
  'black-a30': 'rgba(0, 0, 0, 0.3)',
  'black-a70': 'rgba(0, 0, 0, 0.7)',
  'charcoal-grey': '#505050',
  'clarity-white': '#ffffff',
  'deep-green': '#17be17',
  'fcm-green': '#00de00',
  'green-highlight': '#e9f9e3',
  'grey-1': '#f5f5f5',
  'grey-2': '#e5e5e5',
  'grey-3': '#d9d9d9',
  'grey-4': '#c6c6c6',
  'grey-5': '#aaaaaa',
  'grey-6': '#818181',
  'midnight-black': '#000000',
  'ui-blue': '#1f8edf',
  'ui-blue-darken': '#0e7ac8',
  'ui-blue-lighten': '#e9f4fc',
  'ui-green': '#41bf83',
  'ui-green-darken': '#339968',
  'ui-green-lighten': '#ecf9f3',
  'ui-orange': '#f29036',
  'ui-orange-darken': '#e57610',
  'ui-orange-lighten': '#fef4eb',
  'ui-red': '#e73434',
  'ui-red-darken': '#cd1818',
  'ui-red-lighten': '#fdebeb',
  'white-a30': 'rgba(255, 255, 255, 0.3)',
  'white-a70': 'rgba(255, 255, 255, 0.7)',
} as const;
export default palette;
