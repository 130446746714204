import { FC } from 'react';
import { Company } from 'types/profile';
import Checkbox from '../Checkbox/Checkbox';
import ElementWithTooltip from '../ElementWithTooltip';

interface ICompanyFilterCheckbox {
  company: Company;
  companies: Company[];
  onChange: (value: boolean) => void;
}

const CompanyFilterCheckbox: FC<ICompanyFilterCheckbox> = ({
  companies,
  company,
  onChange,
}) => {
  const checked = companies.find(
    (v) => v.Name === company.Name && v.PseudoCity === company.PseudoCity
  );
  return (
    <div className="flex gap-1 items-center cursor-pointer">
      <Checkbox
        id={`${company.PseudoCity} - ${company.Name}`}
        checked={checked}
        setChecked={(checked: boolean) => {
          onChange(checked);
        }}
        className="h-4 w-4 shrink-0"
        labelClassName="before:h-4 before:w-4 after:left-[3.5px] after:top-[3px]"
      />
      <label
        htmlFor={`${company.PseudoCity} - ${company.Name}`}
        className="bodyMedium cursor-pointer w-full grow h-full textWrap flex-1"
      >
        {company.Name || 'N/A'}
      </label>
      <label
        htmlFor={`${company.PseudoCity} - ${company.Name}`}
        className="text-right bodyMedium cursor-pointer h-full textWrap max-w-[10ch]"
      >
        {company.PseudoCity || 'N/A'}
      </label>
    </div>
  );
};
export default CompanyFilterCheckbox;
