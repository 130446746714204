import classNames from 'classnames';
import { atom, useAtom, useAtomValue } from 'jotai';
import { FC, useEffect, useRef, useState } from 'react';

import Button from 'components/common/Button/Button';
import CirclePlus from 'components/common/Icons/CirclePlus';
import Typography from 'components/common/Typography/Typography';
import { isComponentPageAtom } from 'state/store';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import NoteSvg from 'styles/icons/note.svg';
import { INote } from 'types/notes';
import { IProfile } from 'types/profile';
import { isNA } from 'utils/common';

import ActionNotification from 'components/common/ActionNotification/ActionNotification';
import HelpTooltip from 'components/common/HelpTooltip';
import Loader from 'components/common/Loader';

import { AxiosError } from 'axios';
import Modal from 'components/common/Modal';
import {
  useAddCompanyNote,
  useDeleteCompanyNote,
  useEditCompanyNote,
  useFetchCompanyNotes,
} from 'hooks/company';
import { useProfile } from 'hooks/profile';
import { renderPlurals } from 'utils/format';
import CompanyNoteInput from './CompanyNoteInput';
import CompanyNoteItem from './CompanyNoteItem';
import styles from './InternalNotes.module.scss';
import NoteSpinnerOverlay from './NoteSpinnerOverlay';

enum Mode {
  Create = 'CREATE',
  Edit = 'EDIT',
  Nothing = 'NOTHING',
}

const NoteSkeleton = () => {
  return (
    <div className="flex flex-col gap-3 bg-clarity-white p-3">
      <div className="flex justify-between">
        <div className="h-3 w-[100px] bg-grey-2" />
        <div className="h-3 w-[120px] bg-grey-2" />
      </div>
      <div className="h-3 w-full bg-grey-2" />
      <div className="h-3 w-full bg-grey-2" />
    </div>
  );
};

export const confirmDeleteCompanyInternalNoteOpenAtom = atom<
  Pick<INote, 'id'> | undefined
>(undefined);

const CompanyInternalNotes: FC = () => {
  const [mode, setMode] = useState(Mode.Nothing);
  const profile = useProfile()[0].value;
  const retry = useRef<undefined | Function>(undefined);
  const {
    data: notes,
    error: fetchNotesError,
    isFetching: isFetchNotesFetching,
    isLoading: isFetchNotesLoading,
  } = useFetchCompanyNotes();
  const {
    addCompanyNote: addNote,
    error: addNoteError,
    isPending: isAddNoteLoading,
  } = useAddCompanyNote();
  const { editCompanyNote: editNote, error: editNoteError } =
    useEditCompanyNote();
  const { deleteCompanyNote: deleteNote, error: deleteNoteError } =
    useDeleteCompanyNote();
  const [
    confirmDeleteCompanyInternalNoteOpen,
    setConfirmDeleteCompanyInternalNoteOpen,
  ] = useAtom(confirmDeleteCompanyInternalNoteOpenAtom);
  const [error, setError] = useState<AxiosError | undefined>();

  useEffect(() => {
    setError(fetchNotesError as AxiosError | undefined);
  }, [fetchNotesError]);

  useEffect(() => {
    setError(addNoteError as AxiosError | undefined);
  }, [addNoteError]);

  useEffect(() => {
    setError(editNoteError as AxiosError | undefined);
  }, [editNoteError]);

  useEffect(() => {
    setError(deleteNoteError as AxiosError | undefined);
  }, [deleteNoteError]);

  const onAddMode = () => {
    setMode(Mode.Create);
  };

  const resetMode = () => {
    setMode(Mode.Nothing);
  };

  const onDeleteNote = (data: Pick<INote, 'id'>) => {
    setConfirmDeleteCompanyInternalNoteOpen(data);
  };

  const onEditNote = (data: Pick<INote, 'id' | 'priority' | 'text'>) => {
    editNote(data);
    retry.current = () => editNote(data);
  };

  const onAddNote = (data: Pick<INote, 'priority' | 'text'>) => {
    retry.current = () => onAddNote(data);
    addNote(data);
    setMode(Mode.Nothing);
  };
  return (
    <>
      <div
        className={classNames('min-h-[150px] grow bg-clarity-white p-4 lg:w-0')}
      >
        <Header onAdd={onAddMode} mode={mode} profile={profile} />
        {mode === Mode.Create && (
          <CompanyNoteInput
            className={styles.mt4}
            mode={mode}
            onSubmit={onAddNote}
            onCancel={resetMode}
            key={mode}
            priority={1}
          />
        )}

        {!!notes.length && (
          <NotesList
            notes={notes}
            onDeleteNote={onDeleteNote}
            onEditNote={onEditNote}
            isAddNoteLoading={isAddNoteLoading}
          />
        )}
        {isFetchNotesLoading && <Loader e2e="notes_loader" className="mt-2" />}
        {!isFetchNotesFetching && !notes.length && <EmptyNotes />}
        {error && !error?.request?.status && (
          <ActionNotification
            isOpen={!!error}
            onClose={() => setError(undefined)}
            variant="error"
            retry={() => {
              if (typeof retry.current === 'function') {
                setError(undefined);
                retry.current();
              }
            }}
            text={error.message}
          />
        )}
      </div>
      {!!confirmDeleteCompanyInternalNoteOpen && (
        <Modal
          isOpen={!!confirmDeleteCompanyInternalNoteOpen}
          setIsOpen={() => setConfirmDeleteCompanyInternalNoteOpen(undefined)}
          className="flex flex-col gap-5 p-4"
        >
          <div className="flex justify-between">
            <Typography variant="bodyLarge2">Delete Note</Typography>
            <Button
              variant="text"
              className="flex items-center justify-center"
              onClick={() => setConfirmDeleteCompanyInternalNoteOpen(undefined)}
            >
              <CloseSvg />
            </Button>
          </div>
          <Typography variant="bodyMedium">
            Are you sure you want to delete the Note?
          </Typography>
          <div className="flex justify-end gap-2">
            <Button
              variant="secondary"
              onClick={() => setConfirmDeleteCompanyInternalNoteOpen(undefined)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                deleteNote(confirmDeleteCompanyInternalNoteOpen);
                retry.current = () =>
                  deleteNote(confirmDeleteCompanyInternalNoteOpen);
                setConfirmDeleteCompanyInternalNoteOpen(undefined);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

interface IHeader {
  onAdd: Function;
  mode: Mode;
  profile: IProfile;
}

const Header: FC<IHeader> = ({ mode, onAdd, profile }) => {
  const isComponentPage = useAtomValue(isComponentPageAtom);
  return (
    <div className={classNames(styles.headerContainer, 'mb-2')}>
      {!isComponentPage && (
        <div className="flex items-center gap-1">
          <h3
            className={classNames(styles.title, 'w-fit')}
            id="internalNotesHeading"
          >
            Internal Notes
          </h3>
          <HelpTooltip
            heading="Internal Notes"
            description="Please leave here any characteristics of the Company that may help with processing traveller's cases. This information would be seen by any other travel consultant searching this Company."
          />
        </div>
      )}
      {!isNA(profile.guid) && (
        <Button
          variant="text"
          className={classNames(
            styles.noteBtn,
            mode === Mode.Create && styles.activeNoteBtn,
            'hover:text-deep-green active:text-fcm-green'
          )}
          customPadding={!isComponentPage}
          onClick={() => onAdd()}
          disabled={mode === Mode.Create}
        >
          <CirclePlus />
          <span>Add Note</span>
        </Button>
      )}
    </div>
  );
};

interface INotesList {
  notes: INote[];
  onDeleteNote: Function;
  onEditNote: (data: Pick<INote, 'id' | 'priority' | 'text'>) => void;
  isAddNoteLoading: boolean;
}

const NotesList: FC<INotesList> = ({
  isAddNoteLoading,
  notes,
  onDeleteNote,
  onEditNote,
}) => {
  return (
    <div className={styles.notesListContainer}>
      <Typography variant="bodySmall" color="charcoalGray">
        <span className={styles.halfbold}>{notes.length} </span>
        {renderPlurals({
          count: notes.length,
          entity: ['note', 'notes'],
          showAmount: false,
        })}{' '}
        displayed
      </Typography>
      <div className={styles.notesList}>
        {isAddNoteLoading && (
          <div className="relative">
            <NoteSkeleton />
            <NoteSpinnerOverlay />
          </div>
        )}
        {notes.map((note) => {
          return (
            <CompanyNoteItem
              onDeleteNote={onDeleteNote}
              onEditNote={onEditNote}
              key={note.id}
              {...note}
            />
          );
        })}
      </div>
    </div>
  );
};

interface IEmptyNotes {}

const EmptyNotes: FC<IEmptyNotes> = () => {
  return (
    <div className={styles.emptyNotes}>
      <img src={NoteSvg} alt="note" />
      <div className={styles.text}>
        <span className={styles.main}>Internal Notes</span>
        <span className={styles.sub}>have not been added yet</span>
      </div>
    </div>
  );
};

export default CompanyInternalNotes;
