import classNames from 'classnames';
import { format } from 'date-fns/esm';
import { FC, ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Typography from 'components/common/Typography/Typography';
import Trips from 'components/pages/Profile/components/Trips/Trips';
import { useFetchTrip } from 'hooks/trips';
import { ITrip } from 'types/trips';
import { sendGoogleEvent } from 'utils/googleTag';

import Line from 'components/common/Line/Line';
import Loader from 'components/common/Loader';
import Pnr from 'components/common/Pnr/Pnr';
import { isNA } from 'utils/common';
import styles from './DetailedTrips.module.scss';
import AirSection from './components/AirSection/AirSection';
import CarSection from './components/CarSection';
import HotelSection from './components/HotelSection';
import TrainSection from './components/TrainSection';

interface DetailedTripSectionProps {
  trip: ITrip;
}

export const renderOptions = <T extends number | string | null>({
  label,
  render,
  value,
}: {
  label?: string;
  value?: T;
  render?: (val?: T) => ReactElement;
}) => {
  const noValue = !value || isNA(value.toString().trim());

  return render ? (
    render(value)
  ) : (
    <Line key={label} className={styles.customLine} customMargin>
      <Typography color={!noValue ? 'black' : 'grey-4'} variant="bodyMedium">
        {label}
      </Typography>

      <Typography
        color={!noValue ? 'black' : 'grey-4'}
        variant="bodyMedium2"
        className="wrap text-right"
      >
        {typeof value === 'number' ? value.toFixed(2) : value || 'N/A'}
      </Typography>
    </Line>
  );
};
const useScrollToSection = (isLoading: boolean) => {
  useEffect(() => {
    if (isLoading) return;
    const hash = window.location.hash.slice(1);
    if (!hash) return;
    const element = document.getElementById(hash);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [isLoading]);
};
const DetailedTrips: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, trip } = useFetchTrip(id);
  useScrollToSection(isLoading);

  useEffect(() => {
    sendGoogleEvent('open_trip_details');
  }, []);
  return (
    <div className={styles.container}>
      <Trips
        className={styles.trips}
        currentTrip={trip}
        isTripLoading={isLoading}
      />
      {isLoading || !trip ? (
        <div
          className={classNames(
            styles.detailsContainer,
            'flex items-center justify-center'
          )}
        >
          <Loader e2e="detailed_trips_loader" />
        </div>
      ) : (
        <DetailedTripSection trip={trip} />
      )}
    </div>
  );
};

export const DetailedTripSection: FC<DetailedTripSectionProps> = ({ trip }) => {
  return (
    <div className={styles.detailsContainer}>
      <div className="flex gap-2">
        <Pnr value={trip.PnrRecordLocator} variant="bodyLarge2" iconSize={15} />
        <div className="flex gap-1">
          <Typography variant="bodyLarge2">
            {trip.startDate ? format(trip.startDate, 'd MMM yyyy') : 'N/A'}
          </Typography>
          <Typography variant="bodyLarge2">-</Typography>
          <Typography variant="bodyLarge2">
            {trip.endDate ? format(trip.endDate, 'd MMM yyyy') : 'N/A'}
          </Typography>
        </div>
      </div>
      {trip.firstTravellerTrip?.map((booking, idx) => {
        if ('Flight' in booking) {
          return <AirSection booking={booking} key={idx} id={idx} />;
        }
        if ('Hotel' in booking) {
          return <HotelSection booking={booking} key={idx} id={idx} />;
        }
        if ('Operator' in booking) {
          return <TrainSection booking={booking} key={idx} id={idx} />;
        }
        return <CarSection booking={booking} key={idx} id={idx} />;
      })}
    </div>
  );
};

export default DetailedTrips;
