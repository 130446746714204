const Plane = ({ fill = '#9CA5AE', transform = '', ...props }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83487 0.999473C6.99234 0.790711 7.23867 0.667969 7.50016 0.667969H10.0002C10.2992 0.667969 10.5753 0.828207 10.7237 1.08785L13.8171 6.5013H16.6668C17.3299 6.5013 17.9658 6.7647 18.4346 7.23354C18.9034 7.70238 19.1668 8.33826 19.1668 9.0013C19.1668 9.66434 18.9034 10.3002 18.4346 10.7691C17.9658 11.2379 17.3299 11.5013 16.6668 11.5013H13.8171L10.7237 16.9148C10.5753 17.1744 10.2992 17.3346 10.0002 17.3346H7.50016C7.23867 17.3346 6.99234 17.2119 6.83487 17.0031C6.6774 16.7944 6.62706 16.5238 6.69889 16.2724L8.06206 11.5013H6.17867L4.75609 12.9239C4.59981 13.0802 4.38784 13.168 4.16683 13.168H1.66683C1.37802 13.168 1.10979 13.0184 0.957955 12.7727C0.806116 12.5271 0.792312 12.2203 0.921474 11.962L2.4018 9.0013L0.921474 6.04065C0.792312 5.78232 0.806116 5.47554 0.957955 5.22986C1.10979 4.98418 1.37802 4.83464 1.66683 4.83464H4.16683C4.38784 4.83464 4.59981 4.92243 4.75609 5.07871L6.17867 6.5013H8.06206L6.69889 1.73024C6.62706 1.47881 6.6774 1.20823 6.83487 0.999473ZM8.60494 2.33464L9.9681 7.1057C10.0399 7.35713 9.98959 7.6277 9.83212 7.83647C9.67465 8.04523 9.42832 8.16797 9.16683 8.16797H5.8335C5.61248 8.16797 5.40052 8.08017 5.24424 7.92389L3.82165 6.5013H3.01519L4.07885 8.62862C4.19616 8.86323 4.19616 9.13937 4.07885 9.37398L3.01519 11.5013H3.82165L5.24424 10.0787C5.40052 9.92243 5.61248 9.83464 5.8335 9.83464H9.16683C9.42832 9.83464 9.67465 9.95738 9.83212 10.1661C9.98959 10.3749 10.0399 10.6455 9.9681 10.8969L8.60494 15.668H9.51656L12.61 10.2545C12.7583 9.99487 13.0344 9.83464 13.3335 9.83464H16.6668C16.8878 9.83464 17.0998 9.74684 17.2561 9.59056C17.4124 9.43428 17.5002 9.22232 17.5002 9.0013C17.5002 8.78029 17.4124 8.56833 17.2561 8.41205C17.0998 8.25577 16.8878 8.16797 16.6668 8.16797H13.3335C13.0344 8.16797 12.7583 8.00773 12.61 7.74809L9.51656 2.33464H8.60494Z"
        fill={fill}
      />
    </svg>
  );
};

export default Plane;
