import { QueryKey as TQueryKey } from '@tanstack/react-query';
import Axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { queryClient, router } from 'index';
import {
  TokensResponse,
  getAccessToken,
  getRefreshToken,
  setTokens,
} from 'utils/token';

import { getParentUrl } from 'components/pages/EjectedSearch/EjectedSearch';
import { atom, getDefaultStore } from 'jotai';
import { trackingSourceAtom } from 'state/store';
import { env } from '../env';
import { capitalize, relogin } from './common';

export enum QueryKey {
  InternalNotes = 'internalnotes',
  InternalCompanyNotes = 'internalcompanynotes',
  CompanyDocs = 'companydocuments',
  InternalNote = 'internalnote',
  Profile = 'profile',
  MultipleProfiles = 'multipleprofiles',
  Onboarding = 'onboarding',
  Search = 'search',
}

interface IApiService<RequestData = any> {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  headers?: AxiosRequestHeaders;
  data?: RequestData;
  key?: TQueryKey;
  signal?: AbortSignal;
}

const publicAreaNodes = ['/auth/token', '/auth/pkce'];

export const axios = Axios.create({
  baseURL: env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});
export function isAxiosError(
  err: any
): err is AxiosError & { config: { _retry: boolean } } {
  return err.config;
}
function isRefreshTokenRequest(data: IApiService['data']) {
  return data?.GrantType === 'refresh_token';
}
const MAX_AUTH_TRIES = Number(env.REACT_APP_MAX_AUTH_TRIES || 2);

const getXSource = () => {
  const trackingSource = jotaiStore.get(trackingSourceAtom);
  const parentUrl = getParentUrl();
  if (parentUrl) return 'Agent Workspace';
  if (!trackingSource) return 'Browser';
  return capitalize(trackingSource);
};
export const jotaiStore = getDefaultStore();
const maxAuthTriesAtom = atom(MAX_AUTH_TRIES);
export const apiService = async <T = any, RequestData = any>({
  data,
  headers,
  key,
  method,
  signal,
  url,
}: IApiService<RequestData>): Promise<T> => {
  let authHeaders = {};
  if (!publicAreaNodes.includes(url)) {
    const token = getAccessToken();
    authHeaders = { Authorization: `Bearer ${token}` };
  }
  const queryKey = key ?? [url, data];
  const queryData: AxiosResponse | undefined =
    queryClient.getQueryData(queryKey);
  if (method === 'get' && queryData) {
    return queryData?.data;
  }
  try {
    const response: AxiosResponse = await axios({
      data,
      headers: {
        ...headers,
        ...authHeaders,
        'X-Source': getXSource(),
      },
      method,
      signal,
      url,
    });
    if (method === 'get' && response) {
      queryClient.setQueryData(queryKey, response);
    }
    if (!isRefreshTokenRequest(data)) {
      jotaiStore.set(maxAuthTriesAtom, MAX_AUTH_TRIES);
    }
    return response?.data?.message || response?.data;
  } catch (error) {
    if (isRefreshTokenRequest(data)) {
      relogin();
      return;
    }
    if (!isAxiosError(error)) {
      throw error;
    }
    const originalRequest = error.config;
    if (!(error.request?.status === 401 && !originalRequest._retry)) {
      throw error;
    }
    originalRequest._retry = true;
    const isRefeshSuccessful = await refreshAccessToken();
    queryClient.removeQueries([queryKey]);
    if (!isRefeshSuccessful) return;
    return apiService(originalRequest as any);
  }
};

export const refreshAccessToken = async () => {
  const authTriesLeft = jotaiStore.get(maxAuthTriesAtom);
  if (authTriesLeft === 0) {
    router.navigate('/authorization-error?error=okta_access');
    return;
  }
  jotaiStore.set(maxAuthTriesAtom, authTriesLeft - 1);
  const response = await apiService<TokensResponse>({
    data: {
      GrantType: 'refresh_token',
      RefreshToken: getRefreshToken(),
    },
    method: 'post',
    url: '/auth/oauth/token',
  });
  if (!response) return;
  setTokens(response);
  return true;
};
