import { format, isAfter } from 'date-fns';
import { map, pathOr } from 'ramda';

import { inTS } from 'components/pages/Starting/Starting';
import {
  Alert,
  AlertLevel,
  IAPITrip,
  ICarRentalBooking,
  IFlightBooking,
  IFlightBookingNotPartial,
  IHotelBooking,
  IHotelBookingNotPartial,
  ITrainBooking,
  MappedAlert,
  Match,
  TBookings,
} from 'types/trips';

export enum RouteType {
  Single,
  Direct,
  Round,
  Multi,
}

const fieldMap = {
  Car: 'LocalPickupAt',
  Flight: 'LocalDepartureAt',
  Hotel: 'CheckinOn',
  Train: 'LocalDepartureAt', // TODO: fix later
};

const currencyMap = {
  EUR: '€',
  USD: '$',
};
export type TCurrency = keyof typeof currencyMap;
export const getCurrencyToShow = (currency: string) => {
  if (inTS(currency, currencyMap)) {
    return currencyMap[currency];
  }
  return currency;
};

export type TFieldMapKey = keyof typeof fieldMap;

export type Booking =
  | IHotelBooking
  | IFlightBooking
  | ICarRentalBooking
  | ITrainBooking;

export const getInBooking = (
  booking: Booking,
  flight: string[],
  hotel: string[],
  train: string[],
  car: string[],
  func: (a: string, b: string[], c: Booking) => any = pathOr
) => {
  if ('Flight' in booking) {
    return func('N/A', flight, booking);
  }
  if ('Hotel' in booking) {
    return func('N/A', hotel, booking);
  }
  if ('Operator' in booking) {
    return func('N/A', train, booking);
  }
  return func('N/A', car, booking);
};

export const getArrivalCity = (booking: Booking) => {
  return getInBooking(
    booking,
    ['FlightSummary', 'ArrivalAirport', 'MainCity', 'Name'],
    ['Hotel', 'CityName'],
    ['ArrivalStation', 'Name'],
    ['DropoffLocation', 'CityName'],
    (arg1, arg2, bkng) => {
      if ('Layovers' in bkng && bkng?.Layovers?.length) {
        return pathOr(
          '',
          ['Flight', 'ArrivalAirport', 'MainCity', 'Name'],
          bkng.Layovers[bkng.Layovers.length - 1].FlightBooking
        );
      } else {
        return pathOr(arg1, arg2, bkng);
      }
    }
  );
};

export const getDepartureCity = (booking: Booking) => {
  return getInBooking(
    booking,
    ['FlightSummary', 'DepartureAirport', 'MainCity', 'Name'],
    ['Hotel', 'CityName'],
    ['DepartureStation', 'Name'],
    ['PickupLocation', 'CityName']
  );
};

export function getLocationCode(booking: any) {
  const flight = booking?.FlightSummary;
  if (flight) {
    return [
      flight?.DepartureAirport.Iata ?? 'N/A',
      flight?.ArrivalAirport.Iata ?? 'N/A',
    ];
  }
  const { ArrivalStation, DepartureStation } = booking;
  return [
    (DepartureStation.CityName || DepartureStation?.CountryCode) ?? 'N/A',
    (ArrivalStation?.CityName || ArrivalStation?.CountryCode) ?? 'N/A',
  ];
}

export const getBookingType = (booking: TBookings[number]) => {
  // types map to icons 1:1
  if ('Flight' in booking) {
    return 'Flight';
  }
  if ('Hotel' in booking) {
    return 'Hotel';
  }
  if ('Operator' in booking) {
    return 'Train';
  }
  return 'Car';
};

export const mapPnr = <T extends string | undefined>(value: T): T => {
  if (typeof value === 'string') {
    return value.slice(0, 6) as T;
  }
  return value;
};

export const isFlightBooking = (
  booking: TBookings[number]
): booking is IFlightBookingNotPartial => {
  return 'Flight' in booking;
};

export const isHotelBooking = (
  booking: TBookings[number]
): booking is IHotelBookingNotPartial => {
  return 'Hotel' in booking;
};

export const mapTrips = map((trip: IAPITrip) => {
  const startDate = new Date(trip.StartOn || '');
  const endDate = new Date(trip.EndOn || '');
  const formattedStartDate = format(startDate, 'd MMM');
  const formattedEndDate = format(endDate, 'd MMM');
  const firstTravellerTrip = trip?.Travellers?.[0].Bookings;
  return {
    ...trip,
    EndOn: trip.EndOn || '',
    PnrRecordLocator: trip.PnrRecordLocator || 'N/A',
    StartOn: trip.StartOn || '',
    date: `${formattedStartDate} - ${formattedEndDate}`,
    endDate,
    firstTravellerTrip,
    isCanceled: false,
    isFuture: trip.EndOn ? isAfter(endDate, new Date()) : false,
    startDate,
  };
});

export const formatTime = (
  seconds: number,
  padHoursWithZero: boolean = false,
  withSeconds: boolean = false
) => {
  let hours: string | number = Math.floor(seconds / 3600); // get hours
  let minutes: string | number = Math.floor((seconds - hours * 3600) / 60); // get minutes
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10 && padHoursWithZero) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (withSeconds) {
    const secs = seconds - Number(hours) * 3600 - Number(minutes) * 60;
    return `${hours}h ${minutes}m ${secs}s`;
  }
  return `${hours}h ${minutes}m`;
};

export const getAlertsByLevel = <T extends Alert>(
  level: AlertLevel,
  alerts: T[]
) => alerts.filter((v) => v.Level === level);

export const sortMatchesByAlertDate = <T extends Match>(a: T, b: T) =>
  new Date(a.Alert.StartAt).getTime() - new Date(b.Alert.StartAt).getTime();

type TRepeating = [string, MappedAlert[]][];
const sortRepeatingAlertGroups = (alertsByBookingId: {
  [bookingId: string]: {
    repeating: TRepeating;
    unique: MappedAlert[];
  };
}) => {
  for (const val in alertsByBookingId) {
    alertsByBookingId[val].repeating.sort((a, b) => {
      const key1 = a[0];
      const key2 = b[0];
      if (key1[0] === key2[0]) {
        return a[0].length - a[0].length;
      }
      return key1[0] < key2[0] ? -1 : 1;
    });
  }
};

const addSameIdAlertsToRepeating = (
  key: string,
  repeating: TRepeating,
  resultArr: MappedAlert[]
) => {
  const index = repeating.findIndex((v) => v[0] === key);
  if (index !== -1) {
    repeating[index][1].push(...resultArr);
    return;
  }
  repeating.push([key, resultArr]);
};
const getSameIdAlertsAndKey = (
  alerts: MappedAlert[],
  { Id, bookingId }: MappedAlert
) => {
  const repeatingIds: number[] = [];

  const sameIdAlerts = alerts.filter((al) => {
    if (al.Id === Id && al.bookingId !== bookingId) {
      repeatingIds.push(al.bookingId);
      return true;
    }
    return false;
  });
  return [sameIdAlerts, repeatingIds.join(', ')] as const;
};
export const getAlertGroups = (alerts: MappedAlert[]) => {
  const alertsByBookingId: {
    [bookingId: string]: {
      repeating: TRepeating;
      unique: MappedAlert[];
    };
  } = {};
  const includedAlertsMap = new Map<string, boolean>();

  alerts.forEach((alert) => {
    const { Id, bookingId } = alert;
    if (!alertsByBookingId[bookingId]) {
      alertsByBookingId[bookingId] = {
        repeating: [],
        unique: [],
      };
    }
    if (includedAlertsMap.has(Id)) return;
    includedAlertsMap.set(Id, true);
    const [sameIdAlerts, key] = getSameIdAlertsAndKey(alerts, alert);
    if (sameIdAlerts.length) {
      addSameIdAlertsToRepeating(key, alertsByBookingId[bookingId].repeating, [
        alert,
        ...sameIdAlerts,
      ]);
      return;
    }
    alertsByBookingId[bookingId].unique.push(alert);
  });

  sortRepeatingAlertGroups(alertsByBookingId);

  return Object.values(alertsByBookingId);
};
