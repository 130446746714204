import classNames from 'classnames';
import { FC, Fragment, useEffect, useState } from 'react';

import UncontrolledPopper from 'components/common/Popper/Popper';
import Typography from 'components/common/Typography/Typography';
import { ReactComponent as DotsHorizontalSvg } from 'styles/icons/dots_horizontal.svg';
import { ReactComponent as RoundArrowSvg } from 'styles/icons/round_arrow.svg';
import { ReactComponent as RightArrowSvg } from 'styles/icons/small_right_arrow.svg';
import { ITrip } from 'types/trips';
import { RouteType } from 'utils/trips';

import { iconMap } from 'constants/trips';
import styles from './LocationBlock.module.scss';

const LocationTooltip: FC<{
  short: string;
  className?: string;
}> = ({ className, short }) => {
  return (
    <Typography className={className} variant="bodyMedium">
      {short || 'N/A'}
    </Typography>
  );
};

const LocationBlock: FC<{
  trip: ITrip;
}> = ({ trip }) => {
  const tripLocations = trip?.Locations?.map((v) => {
    return {
      ...v,
      From: v.From?.toUpperCase() || 'N/A',
      FromFull: v.FromFull?.toUpperCase() || 'N/A',
      To: v.To?.toUpperCase() || 'N/A',
      ToFull: v.ToFull?.toUpperCase() || 'N/A',
    };
  });
  const type = trip.RouteType;
  const isTripDetails = location.pathname.includes('detailed-trips');
  if (!tripLocations) {
    return (
      <Typography
        className={classNames('min-w-[70px]', isTripDetails && 'text-right')}
        variant="bodyMedium"
      >
        N/A
      </Typography>
    );
  }

  if (type === RouteType.Single) {
    const { From } = tripLocations[0];
    return <LocationTooltip short={From} />;
  }

  if (type === RouteType.Direct) {
    const { From: departureShort, To: arrivalShort } = tripLocations[0];

    return (
      <div
        className={classNames(
          'flex items-center gap-1',
          isTripDetails && 'justify-end text-right'
        )}
      >
        <LocationTooltip short={departureShort} />
        <RightArrowSvg data-e2e-type="Direct" className="w-3" />
        <LocationTooltip short={arrivalShort} />
      </div>
    );
  }
  if (type === RouteType.Round) {
    const { From: departureShort, To: arrivalShort } = tripLocations[0];

    return (
      <div
        className={classNames(
          'flex items-center gap-1',
          isTripDetails && 'justify-end text-right'
        )}
      >
        <LocationTooltip short={departureShort} />
        <RoundArrowSvg data-e2e-type="Round" className="w-3" />
        <LocationTooltip short={arrivalShort} />
      </div>
    );
  }
  if (type === RouteType.Multi) {
    return <Multi locations={tripLocations} />;
  }

  return null;
};

const Multi: FC<{ locations: ITrip['Locations'] }> = ({ locations }) => {
  const [open, setOpen] = useState(false);

  const MultiLocBlock: FC<any> = ({ ref }) => {
    useEffect(() => {
      setOpen(true);
      return () => setOpen(false);
    }, []);

    return (
      <div
        ref={ref}
        className="grid grid-cols-[auto_auto_auto] items-center gap-2"
      >
        {locations?.map((location, i) => (
          <Fragment key={i}>
            <div className="flex items-center gap-2">
              {iconMap[location.Booking]}
              <Typography variant="bodyMedium">{location.From}</Typography>
            </div>
            <RightArrowSvg />
            <Typography variant="bodyMedium">{location.To}</Typography>
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <UncontrolledPopper
      trigger="hover"
      placement="top"
      className="w-fit p-[12px]"
      customPadding
      content={<MultiLocBlock />}
      offsetOptions={10}
    >
      <div className="flex items-center gap-1">
        <Typography variant="bodyMedium">
          {locations?.[0].From || locations?.[0].FromFull}
        </Typography>
        <div
          className={classNames('relative bottom-1', {
            [styles.dotsOpen]: open,
          })}
        >
          <Typography variant="bodySmall2" className="w-3 text-center">
            {locations?.length}
          </Typography>
          <DotsHorizontalSvg
            width={12}
            height={12}
            className="absolute bottom-[-3px] left-[50%] translate-x-[-50%]"
            data-e2e-type="Multi"
          />
        </div>
        <Typography variant="bodyMedium">
          {locations?.[0].To || locations?.[0].ToFull}
        </Typography>
      </div>
    </UncontrolledPopper>
  );
};
export default LocationBlock;
