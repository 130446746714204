import { FC } from 'react';
import { ReactComponent as HelpSvg } from 'styles/icons/help.svg';
import palette from 'styles/palette';
import { ControlledPopper, useControlledPopper } from './Popper/Popper';
import Typography from './Typography/Typography';

interface HelpTooltipProps {
  heading: string;
  description: string;
}

const HelpTooltip: FC<HelpTooltipProps> = ({ description, heading }) => {
  const { isOpen, setIsOpen } = useControlledPopper();
  return (
    <ControlledPopper
      setOpen={setIsOpen}
      open={isOpen}
      placement="right"
      className="border-2 border-midnight-black [&:.popperArrow]:rotate-45"
      offsetOptions={{ crossAxis: -5, mainAxis: 10 }}
      content={
        <div className="flex w-[312px] flex-col ">
          <Typography variant="bodyMedium2">{heading}</Typography>
          <Typography variant="bodyMedium">{description}</Typography>
        </div>
      }
      trigger="hover"
      type="onboarding"
      strategy="fixed"
    >
      <div>
        <HelpSvg color={isOpen ? palette['fcm-green'] : palette['grey-5']} />
      </div>
    </ControlledPopper>
  );
};
export default HelpTooltip;
