import { CSSProperties, FC, useEffect, useRef } from 'react';

import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { queryClient } from 'index';
import { QueryKey, apiService } from 'utils/apiService';
import Bar from '../Bar';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import styles from './OnboardingTooltip.module.scss';

interface OnboardingTooltipProps {
  heading: string;
  description: string;
  step: number;
  className?: string;
  arrowClassName: string;
  style?: CSSProperties;
  id: string;
}
const TOTAL_ONBOARDING_STEPS = 6;
export const LAST_ONBOARDING_STEP_ID = 'INNT';
const OnboardingTooltip: FC<OnboardingTooltipProps> = ({
  arrowClassName,
  className,
  description,
  heading,
  id,
  step,
  style,
}) => {
  const { mutate } = useMutation({
    mutationFn: async (data: { Step: string }) => {
      await apiService({
        data,
        method: 'post',
        url: '/onboarding/steps',
      });
      queryClient.removeQueries({ queryKey: [QueryKey.Onboarding] });
      queryClient.invalidateQueries({
        queryKey: ['getOnboarding'],
      });
    },

    mutationKey: ['setOnboarding'],
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }, 150);
  }, [id]);

  return (
    <div
      className={classNames(
        'absolute z-10 transition-all',
        styles.shadow,
        className
      )}
      style={style}
      ref={containerRef}
    >
      <div
        className={classNames(
          arrowClassName,
          '-z-10 h-4 w-4 rotate-45 rounded-sm bg-midnight-black'
        )}
      />
      <div className="flex flex-col gap-2 border-2 border-midnight-black bg-clarity-white p-4">
        <div className="flex flex-col">
          <Typography variant="bodyMedium2">{heading}</Typography>
          <Typography variant="bodyMedium">{description}</Typography>
        </div>
        <Bar step={step} total={TOTAL_ONBOARDING_STEPS} />
        <div className="flex justify-between">
          <div className="flex flex-col">
            <Typography variant="bodySmall2">
              {step === TOTAL_ONBOARDING_STEPS ? 'Congratulations!' : 'Done?'}
            </Typography>
            {step === TOTAL_ONBOARDING_STEPS ? (
              <Typography variant="bodySmall">
                You have passed intro tour.
              </Typography>
            ) : (
              <Button
                variant="text"
                customPadding
                onClick={() => {
                  mutate({ Step: LAST_ONBOARDING_STEP_ID });
                }}
              >
                <Typography variant="bodySmall" className="underline">
                  Skip onboarding tips
                </Typography>
              </Button>
            )}
          </div>
          <Button
            variant="primary"
            onClick={() => {
              mutate({ Step: id });
            }}
          >
            {step === TOTAL_ONBOARDING_STEPS ? 'Done' : 'Continue'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OnboardingTooltip;
