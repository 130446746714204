import classNames from 'classnames';
import { format } from 'date-fns/esm';
import { FC, Fragment, ReactElement } from 'react';

import Typography, {
  TypographyProps,
} from 'components/common/Typography/Typography';
import { ReactComponent as HotelSvg } from 'styles/icons/amenities/hotel.svg';
import { ReactComponent as StarSvg } from 'styles/icons/star.svg';
import { IHotelBooking } from 'types/trips';
import { isNA } from 'utils/common';
import { removeSecondsAndConvertToAmPm, renderPlurals } from 'utils/format';
import { getBookingType, getCurrencyToShow } from 'utils/trips';

import ElementWithTooltip from 'components/common/ElementWithTooltip';
import { renderOptions } from '../DetailedTrips';
import styles from '../DetailedTrips.module.scss';

export const getHotelDisplayDataFromBooking = (booking: IHotelBooking) => {
  const departureDate = booking.CheckinOn || '';
  const arrivalDate = booking.CheckoutOn || '';
  const checkIn = booking.Hotel?.CheckIn;
  const checkOut = booking.Hotel?.CheckOut;

  const hotelName = booking.Hotel?.Name ?? 'Hotel name N/A';

  const duration = booking.NumberOfNights;
  const pnr = booking.PnrRecordLocator;
  const hotelChain = booking.Hotel?.Chain?.Name;
  const rating =
    booking.Hotel?.Awards.find(({ Provider }) => Provider === 'Star Rating')
      ?.Rating || 'N/A';
  const calculatedRate =
    booking.Rate?.Total && booking.NumberOfNights
      ? (Number(booking.Rate?.Total) / booking.NumberOfNights).toFixed(2)
      : 'N/A';
  const currency = booking.Rate?.Currency ?? '';

  const rate = booking.Rate?.Base || calculatedRate;
  const total = booking.Rate?.Total || 'N/A';
  const options = [
    {
      label: 'Rate per night',
      value: `${!isNA(rate) ? `${getCurrencyToShow(currency)} ` : ''}${rate}`,
    },
    { label: 'Total', value: `${getCurrencyToShow(currency)} ${total}` },
  ];
  return {
    arrivalDate,
    checkIn,
    checkOut,
    departureDate,
    duration,
    hotelChain,
    hotelName,
    options,
    pnr,
    rating,
  };
};

export const HotelNameWithStars: FC<{
  rating: string;
  hotelName: string;
  variant?: TypographyProps['variant'];
  tooltipOnOverflow: boolean;
}> = ({ hotelName, rating, tooltipOnOverflow, variant = 'bodyMedium2' }) => {
  const Wrapper = tooltipOnOverflow
    ? ({ children }: { children: ReactElement }) => {
        return (
          <ElementWithTooltip value={hotelName}>{children}</ElementWithTooltip>
        );
      }
    : Fragment;
  return (
    <div className="flex items-center gap-1">
      <Wrapper>
        <Typography variant={variant}>{hotelName}</Typography>
      </Wrapper>
      <div className={classNames('flex self-start pt-[4px]')}>
        {!isNA(rating) &&
          Array(+rating)
            .fill(StarSvg)
            .map((star, idx) => <StarSvg key={idx} />)}
      </div>
    </div>
  );
};

const HotelSection: FC<{ booking: IHotelBooking; id: number }> = ({
  booking,
  id,
}) => {
  const {
    arrivalDate,
    checkIn,
    checkOut,
    departureDate,
    duration,
    hotelChain,
    hotelName,
    options,
    rating,
  } = getHotelDisplayDataFromBooking(booking);

  return (
    <div
      className={styles.sectionContainer}
      id={`${getBookingType(booking)}_${id}`}
    >
      <div className="flex gap-[20px]">
        <HotelSvg
          width={24}
          height={24}
          className={classNames(styles.jet, 'shrink ')}
          data-e2e-icon="Hotel"
        />
        <div className="w-full">
          <div className={styles.segmentPrimaryInfo}>
            <div className={classNames(styles.fixWidth, styles.from)}>
              <Typography
                variant="bodyMedium"
                data-e2e-hotel_summary_stay_dates
              >
                {departureDate
                  ? format(new Date(departureDate), 'd MMM yyyy')
                  : 'N/A'}{' '}
                -{' '}
                {arrivalDate
                  ? format(new Date(arrivalDate), 'd MMM yyyy')
                  : 'N/A'}
              </Typography>
              <HotelNameWithStars
                hotelName={hotelName}
                rating={rating}
                tooltipOnOverflow={false}
              />
            </div>
            <div className={classNames(styles.fixWidth, styles.to)}></div>
            <div className={classNames('flex flex-col gap-1', styles.stops)}>
              <Typography variant="bodySmall" data-e2e-nights-stay>
                {duration
                  ? renderPlurals({
                      count: duration,
                      entity: ['night', 'nights'],
                    })
                  : 'N/A nights'}{' '}
                stay
              </Typography>
            </div>
          </div>
          <div className="mt-3 flex items-center bg-grey-1 lg:pr-2">
            <div className="flex items-center gap-2">
              <Typography
                variant="bodySmall2"
                className="mb-auto bg-green-highlight px-2 py-1 "
              >
                {booking.IsPassive ? 'Passive' : 'GDS'}
              </Typography>
              <Typography variant="bodySmall">
                Confirmation Number:{' '}
                <span className="font-bold">
                  {booking.ConfirmationNumber || 'N/A'}
                </span>
              </Typography>
            </div>
          </div>
          <div className="mt-3">
            {hotelChain && (
              <Typography variant="bodySmall2">
                Hotels by {hotelChain}
              </Typography>
            )}
            <div className="mt-3 flex flex-col gap-6 lg:flex-row lg:gap-0">
              <div className="flex grow justify-between gap-6 lg:justify-start">
                <div className={classNames(styles.fixWidth)}>
                  <Typography variant="bodySmall">Check In time</Typography>
                  <Typography variant="bodyMedium2">
                    {checkIn
                      ? `After ${removeSecondsAndConvertToAmPm(checkIn)}`
                      : 'N/A'}
                  </Typography>
                </div>
                <div className={classNames(styles.fixWidth)}>
                  <Typography
                    className="text-right lg:text-left"
                    variant="bodySmall"
                  >
                    Check Out time
                  </Typography>
                  <Typography variant="bodyMedium2">
                    {checkOut
                      ? `Before ${removeSecondsAndConvertToAmPm(checkOut)}`
                      : 'N/A'}
                  </Typography>
                </div>
              </div>
              <div
                className={classNames(
                  'flex grow flex-col gap-1',
                  styles.optionsBlock
                )}
              >
                {options.map(renderOptions)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelSection;
