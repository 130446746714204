import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './Badge.module.scss';

type BadgeProps = {
  className?: string;
};

const Badge: FC<PropsWithChildren<BadgeProps>> = ({ children, className }) => (
  <div className={classNames(styles.badge, className)}>{children}</div>
);

export default Badge;
