import { enc } from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import { v4 as uuidv4 } from 'uuid';

import { STORAGE_PREFIX } from 'constants/storage';

import { env } from '../env';

import { addUrlParameter } from './qs';
import { apiService } from './apiService';
import { router } from 'index';
import { relogin } from './common';

export type Pkce = {
  codeVerifier: string;
  codeChallenge: string;
};
export type TokensResponse = {
  access_token: string;
  refresh_token: string;
};
export const setTokens = (response: TokensResponse) => {
  setAccessToken(response.access_token);
  setRefreshToken(response.refresh_token);
  sendTokenToChromeExtension({
    extensionId: env.REACT_APP_CHROME_EXTENSION_ID,
    jwt: response.access_token,
  });
};

export const removeLocalData = () => {
  localStorage.removeItem(STORAGE_PREFIX + 'token');
  localStorage.removeItem(STORAGE_PREFIX + 'refreshToken');
  localStorage.removeItem(STORAGE_PREFIX + 'pkce');
  return sendTokenToChromeExtension({
    extensionId: env.REACT_APP_CHROME_EXTENSION_ID,
    jwt: '',
  });
};

export const setIsIdle = (value: boolean) => {
  localStorage.setItem(STORAGE_PREFIX + 'isIdle', `${value}`);
};

export const getIsIdle = () => {
  return String(localStorage.getItem(STORAGE_PREFIX + 'isIdle')) === 'true';
};

export const setGenesysAccessToken = (token: string) => {
  localStorage.setItem(STORAGE_PREFIX + 'genesysToken', token);
  return token;
};

export const getGenesysAccessToken = () => {
  return localStorage.getItem(STORAGE_PREFIX + 'genesysToken');
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(STORAGE_PREFIX + 'token', token);
  return token;
};

export const getAccessToken = () => {
  return localStorage.getItem(STORAGE_PREFIX + 'token');
};

export const getRefreshToken = () => {
  return localStorage.getItem(STORAGE_PREFIX + 'refreshToken');
};
export const setRefreshToken = (token: string) => {
  localStorage.setItem(STORAGE_PREFIX + 'refreshToken', token);
  return token;
};

export const generatePkce = () => {
  const id = uuidv4() + uuidv4();
  return setPkce(id);
};
export const setPkce = (uuid: string) => {
  const pkce = {
    codeChallenge: sha256(uuid)
      .toString(enc.Base64)
      .replaceAll('+', '-')
      .replaceAll('/', '_')
      .replace(/=+$/, ''),
    codeVerifier: uuid,
  };
  localStorage.setItem(STORAGE_PREFIX + 'pkce', JSON.stringify(pkce));
  return pkce;
};

export const hasPkce = () => {
  const pkce = localStorage.getItem(STORAGE_PREFIX + 'pkce');
  if (!pkce) return null;
  try {
    return JSON.parse(pkce ?? 'null') as Pkce;
  } catch (e) {
    return null;
  }
};

export const getPkce = () => {
  const pkce = hasPkce();
  if (!pkce) {
    return generatePkce();
  }
  return pkce;
};

export const loginWithPkce = (url = window.location.href) => {
  const pkce = getPkce();
  window.open(
    `${env.REACT_APP_OKTA_URL}?ReturnUrl=${encodeURIComponent(
      `${addUrlParameter(url, 'code_challenge', pkce.codeChallenge)}`
    )}`,
    '_self'
  );
};

export const sendTokenToChromeExtension = ({ extensionId, jwt }: any) => {
  if ('runtime' in chrome) {
    return new Promise((resolve, reject) => {
      let timeoutId = 0;
      try {
        timeoutId = window.setTimeout(() => {
          resolve('Took too long to receive a response');
        }, 5000);
        chrome.runtime.sendMessage(extensionId, { jwt }, (response) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          console.log(response);
          resolve(response);
        });
      } catch (error) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        console.log(error);
        resolve(error);
      }
    });
  }
};
const removeCodeChallengeAndCodeFromLocation = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('code_challenge');
  url.searchParams.delete('code');
  const searchParams = url.searchParams.toString();
  router.navigate(
    searchParams ? `${url.pathname}?${searchParams}` : url.pathname
  );
};

export const requestToken = async (code: string, codeVerifier: string) => {
  try {
    const result = await apiService<{
      access_token: string;
      refresh_token: string;
    }>({
      data: { code, code_verifier: codeVerifier },
      method: 'post',
      url: '/auth/pkce',
    });
    return result;
  } catch (err) {
    removeCodeChallengeAndCodeFromLocation();
    relogin();
  }
  throw new Error('API request failed');
};
