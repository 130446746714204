import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import TravellerCrumb from 'components/common/TravellerCrumb/TravellerCrumb';
import { useEffect } from 'react';
import {
  breadcrumbsAtom,
  isAuthedAtom,
  profileAtom,
  profileSourceAtom,
} from 'state/store';
import { IProfileAPI } from 'types/profile';
import { QueryKey, apiService, isAxiosError } from 'utils/apiService';
import { getTravellersOrProfilePage } from 'utils/common';
import { formatProfile } from 'utils/profile';
import { useRouterQuery } from './general';

const useFetchProfile = () => {
  const navigate = useNavigate();
  const routerQuery = useRouterQuery();
  const isAuthed = useAtomValue(isAuthedAtom);

  const { travelerGuid } = routerQuery;
  const dispatchProfile = useSetAtom(profileAtom);
  const dispatchBreadcrumbs = useSetAtom(breadcrumbsAtom);
  const dispatchSource = useSetAtom(profileSourceAtom);
  const queryClient = useQueryClient();
  const {
    data: profile,
    error,
    ...result
  } = useQuery({
    enabled: !!travelerGuid && isAuthed,
    queryFn: async ({ signal }) => {
      dispatchProfile({ type: 'LOADING' });
      return apiService<IProfileAPI>({
        key: [QueryKey.Profile, travelerGuid],
        method: 'get',
        signal,
        url: `/profile/${travelerGuid}`,
      });
    },
    queryKey: ['fetchProfile', travelerGuid],
    select: (profileAPI) => formatProfile(profileAPI),
  });

  useEffect(() => {
    if (!profile) return;
    dispatchProfile({ type: 'FULFILLED', value: profile });
    dispatchBreadcrumbs({
      type: 'ADD',
      value: {
        fullName: profile.fullName.trim(),
        id: profile.guid,
        phones: profile.phones,
        query: routerQuery,
        render: (isLastCrumb: boolean) => (
          <TravellerCrumb
            profile={profile}
            isLastCrumb={isLastCrumb}
            key={profile.fullName}
            path={getTravellersOrProfilePage(profile.guid)}
          />
        ),
      },
    });
  }, [profile]);

  useEffect(() => {
    if (!error) return;
    dispatchBreadcrumbs({ type: 'CLEAR' });
    dispatchSource({ type: 'CLEAR' });
    dispatchProfile({ error, type: 'ERROR' });
    if (isAxiosError(error) && error?.request?.status === 403) {
      navigate('/authorization-error', { replace: true });
      return;
    }
    navigate('/restricted-access', { replace: true });
  }, [error]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: ['profile'] });
    };
  }, []);
  return { ...result, data: profile, error };
};

export const useProfile = () => {
  useFetchProfile();
  const result = useAtom(profileAtom);
  return result;
};

export default useFetchProfile;
