import { MutableRefObject } from 'react';

interface IRenderPluralsParams {
  count: number;
  entity: Array<string>;
  showAmount?: boolean;
}

export const renderPlurals = ({
  count,
  entity,
  showAmount = true,
}: IRenderPluralsParams): string => {
  const countToDisplay = showAmount ? `${count} ` : '';
  if (count === 1) {
    return `${countToDisplay}${entity[0]}`;
  }
  return `${countToDisplay}${entity[1]}`;
};

export const removeSecondsAndConvertToAmPm = (time: String): String => {
  const hours = Number(time.split(':')[0]);
  const minutes = time.split(':')[1];
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const newHours = ((hours + 11) % 12) + 1;
  return `${newHours}:${minutes} ${suffix}`;
};

export const joinTruishStrings = (
  values: Array<string | undefined | null>,
  defaultValue: string
) => {
  return (
    values
      .filter((v) => !!v && !!v.trim())
      .join(', ')
      .trim() || defaultValue
  );
};

export const isBiggerThenContainer = (
  ref: MutableRefObject<HTMLElement | null>
) => {
  if (!ref?.current) return false;
  return ref.current.offsetWidth < ref.current.scrollWidth;
};
