import classNames from 'classnames';

import Typography from 'components/common/Typography/Typography';
import { ReactComponent as HighPrioritySvg } from 'styles/icons/notes_high.svg';
import { ReactComponent as LowPrioritySvg } from 'styles/icons/notes_low.svg';
import { ReactComponent as MediumPrioritySvg } from 'styles/icons/notes_medium.svg';
import { ReactComponent as SeverePrioritySvg } from 'styles/icons/notes_severe.svg';
import { NotePriorityKeys } from 'types/notes';

import styles from './InternalNotePriority.module.scss';

type InternalNotePriorityProps = {
  priority: NotePriorityKeys;
  withLabelPriority: boolean;
  isSelect?: boolean;
  onClick?: any;
  className?: string;
};

const InternalNotePriority = ({
  className,
  isSelect = false,
  onClick,
  priority,
  withLabelPriority,
}: InternalNotePriorityProps) => {
  const priorityText = withLabelPriority ? ' Priority' : '';
  const cn = classNames('flex gap-1 individualPriority', className);
  const textClassName = isSelect ? styles.robotoLabel : '';
  const variant = isSelect ? 'bodyMedium2' : 'bodySmall2';
  const priorityContent = {
    0: (
      <>
        <LowPrioritySvg />
        <Typography className={textClassName} variant={variant}>
          Low{priorityText}
        </Typography>
      </>
    ),
    1: (
      <>
        <MediumPrioritySvg />
        <Typography className={textClassName} variant={variant}>
          Medium{priorityText}
        </Typography>{' '}
      </>
    ),
    2: (
      <>
        <HighPrioritySvg />
        <Typography className={textClassName} variant={variant}>
          High{priorityText}
        </Typography>{' '}
      </>
    ),
    3: (
      <>
        <SeverePrioritySvg />
        <Typography className={textClassName} variant={variant}>
          Severe{priorityText}
        </Typography>{' '}
      </>
    ),
  };
  const e2ePriority = onClick ? `edit-${priority}` : `display-${priority}`;
  return (
    <div
      className={cn}
      data-e2e-priority={e2ePriority}
      onClick={() => onClick(priority)}
    >
      {priorityContent[priority]}
    </div>
  );
};

export default InternalNotePriority;
