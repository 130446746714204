const Train = ({ fill = '#9CA5AE', ...props }) => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 1.0013C0.666504 0.541065 1.0396 0.167969 1.49984 0.167969H8.1665C11.0505 0.167969 13.3292 0.847944 14.9083 2.04438C16.5058 3.25478 17.3332 4.95742 17.3332 6.83464C17.3332 7.29487 16.9601 7.66797 16.4998 7.66797C16.0396 7.66797 15.6665 7.29487 15.6665 6.83464C15.6665 5.48685 15.0897 4.27282 13.9018 3.37281C12.6955 2.45883 10.8075 1.83464 8.1665 1.83464H1.49984C1.0396 1.83464 0.666504 1.46154 0.666504 1.0013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4998 6.0013C16.9601 6.0013 17.3332 6.3744 17.3332 6.83464C17.3332 7.49768 17.0698 8.13356 16.6009 8.6024C16.1321 9.07124 15.4962 9.33463 14.8332 9.33463H1.49984C1.0396 9.33463 0.666504 8.96154 0.666504 8.5013C0.666504 8.04106 1.0396 7.66797 1.49984 7.66797H14.8332C15.0542 7.66797 15.2661 7.58017 15.4224 7.42389C15.5787 7.26761 15.6665 7.05565 15.6665 6.83464C15.6665 6.3744 16.0396 6.0013 16.4998 6.0013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49984 0.167969C1.96007 0.167969 2.33317 0.541065 2.33317 1.0013V4.33464H16.0832C16.5434 4.33464 16.9165 4.70773 16.9165 5.16797C16.9165 5.62821 16.5434 6.0013 16.0832 6.0013H1.49984C1.0396 6.0013 0.666504 5.62821 0.666504 5.16797V1.0013C0.666504 0.541065 1.0396 0.167969 1.49984 0.167969Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49984 3.5013C1.96007 3.5013 2.33317 3.8744 2.33317 4.33464V7.66797C2.33317 8.12821 1.96007 8.5013 1.49984 8.5013C1.0396 8.5013 0.666504 8.12821 0.666504 7.66797V4.33464C0.666504 3.8744 1.0396 3.5013 1.49984 3.5013Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 0.167969C6.12674 0.167969 6.49984 0.541065 6.49984 1.0013V5.16797C6.49984 5.62821 6.12674 6.0013 5.6665 6.0013C5.20627 6.0013 4.83317 5.62821 4.83317 5.16797V1.0013C4.83317 0.541065 5.20627 0.167969 5.6665 0.167969Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83317 0.584635C10.2934 0.584635 10.6665 0.957731 10.6665 1.41797V5.16797C10.6665 5.62821 10.2934 6.0013 9.83317 6.0013C9.37293 6.0013 8.99984 5.62821 8.99984 5.16797V1.41797C8.99984 0.957731 9.37293 0.584635 9.83317 0.584635Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 11.8346C0.666504 11.3744 1.0396 11.0013 1.49984 11.0013H16.4998C16.9601 11.0013 17.3332 11.3744 17.3332 11.8346C17.3332 12.2949 16.9601 12.668 16.4998 12.668H1.49984C1.0396 12.668 0.666504 12.2949 0.666504 11.8346Z"
        fill={fill}
      />
    </svg>
  );
};

export default Train;
