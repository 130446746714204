const Car = ({ fill = '#9CA5AE', transform = '', ...props }) => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83317 9.33463C4.37293 9.33463 3.99984 9.70773 3.99984 10.168C3.99984 10.6282 4.37293 11.0013 4.83317 11.0013C5.29341 11.0013 5.6665 10.6282 5.6665 10.168C5.6665 9.70773 5.29341 9.33463 4.83317 9.33463ZM2.33317 10.168C2.33317 8.78726 3.45246 7.66797 4.83317 7.66797C6.21388 7.66797 7.33317 8.78726 7.33317 10.168C7.33317 11.5487 6.21388 12.668 4.83317 12.668C3.45246 12.668 2.33317 11.5487 2.33317 10.168Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1665 9.33463C12.7063 9.33463 12.3332 9.70773 12.3332 10.168C12.3332 10.6282 12.7063 11.0013 13.1665 11.0013C13.6267 11.0013 13.9998 10.6282 13.9998 10.168C13.9998 9.70773 13.6267 9.33463 13.1665 9.33463ZM10.6665 10.168C10.6665 8.78726 11.7858 7.66797 13.1665 7.66797C14.5472 7.66797 15.6665 8.78726 15.6665 10.168C15.6665 11.5487 14.5472 12.668 13.1665 12.668C11.7858 12.668 10.6665 11.5487 10.6665 10.168Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1665 0.167969C2.82575 0.167969 2.51933 0.375428 2.39277 0.69181L0.733125 4.84093C0.69024 4.94134 0.666504 5.05188 0.666504 5.16797V10.168C0.666504 10.6282 1.0396 11.0013 1.49984 11.0013H3.38947C3.24767 10.7562 3.1665 10.4715 3.1665 10.168C3.1665 9.8644 3.24767 9.57978 3.38947 9.33463H2.33317V6.0013H14.8332C15.0542 6.0013 15.2661 6.0891 15.4224 6.24538C15.5787 6.40166 15.6665 6.61362 15.6665 6.83464V9.33463H14.6102C14.752 9.57978 14.8332 9.8644 14.8332 10.168C14.8332 10.4715 14.752 10.7562 14.6102 11.0013H16.4998C16.9601 11.0013 17.3332 10.6282 17.3332 10.168V6.83464C17.3332 6.17159 17.0698 5.53571 16.6009 5.06687C16.1321 4.59803 15.4962 4.33464 14.8332 4.33464H14.4004L11.3172 0.480723C11.1591 0.283044 10.9197 0.167969 10.6665 0.167969H3.1665ZM9.83317 4.33464H12.266L10.266 1.83464H9.83317V4.33464ZM3.7307 1.83464H8.1665V4.33464H2.7307L3.7307 1.83464Z"
        fill={fill}
      />
      <path
        d="M11.7228 11.0013C11.581 10.7562 11.4998 10.4715 11.4998 10.168C11.4998 9.8644 11.581 9.57978 11.7228 9.33463H6.27687C6.41868 9.57978 6.49984 9.8644 6.49984 10.168C6.49984 10.4715 6.41868 10.7562 6.27687 11.0013H11.7228Z"
        fill={fill}
      />
    </svg>
  );
};

export default Car;
