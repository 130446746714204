import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography/Typography';
import { useIsSmallScreen } from 'hooks/general';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  phoneAssignmentAtom,
  prevRouteAtom,
  profileSourceAtom,
} from 'state/store';
import { ReactComponent as SignInSvg } from 'styles/icons/signIn.svg';
import { useInterval } from 'usehooks-ts';
import { getSectionFromUrl, relogin } from 'utils/common';
import { getIsIdle } from 'utils/token';

const SignedOut = () => {
  const phoneAssignmentDispatch = useSetAtom(phoneAssignmentAtom);
  const dispatchSource = useSetAtom(profileSourceAtom);
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const prevRoute = useAtomValue(prevRouteAtom);
  useEffect(() => {
    phoneAssignmentDispatch({ type: 'CLEAR' });
    dispatchSource({ type: 'CLEAR' });
  }, []);

  useInterval(() => {
    const isIdle = getIsIdle();
    if (isIdle) return;
    navigate(
      getSectionFromUrl(prevRoute || '') === 'signed-out' || !prevRoute
        ? '/'
        : prevRoute,
      {
        replace: true,
      }
    );
  }, 1000);

  return (
    <div
      className="noMinWidth m-0 flex grow flex-col items-center justify-center gap-6 bg-clarity-white md:-m-4"
      data-e2e-id="restrictedAccess"
    >
      <SignInSvg height={isSmallScreen ? 120 : 320} width={'auto'} />
      <div className="flex max-w-[720px] flex-col items-center gap-[6px] text-center">
        <Typography variant="headingSmall" color="grey-6">
          You have been Signed Out
        </Typography>
        <Typography variant="bodyLarge" color="grey-6" className="px-10">
          Due to security reasons you have been signed out after 15 minutes of
          inactivity
        </Typography>
      </div>
      <Button
        variant="primary"
        onClick={() => relogin(`${window.location.origin}${prevRoute || '/'}`)}
      >
        Sign In
      </Button>
    </div>
  );
};

export default SignedOut;
