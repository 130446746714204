import { useSetAtom } from 'jotai';
import { FC, useEffect } from 'react';

import { ReactComponent as DetailsSvg } from 'styles/icons/details.svg';

import Bar from '../Profile/components/Bar/Bar';
import InternalNotes from '../Profile/components/InternalNotes/InternalNotes';
import Preferences from '../Profile/components/Preferences/Preferences';
import Trips from '../Profile/components/Trips/Trips';

import { useRouterQuery } from 'hooks/general';
import { isComponentPageAtom } from 'state/store';
import styles from './ComponentPage.module.scss';

const ComponentPage: FC = () => {
  const query = useRouterQuery();
  const setIsComponentPage = useSetAtom(isComponentPageAtom);
  // const { id } = useParams<{ id: string }>();
  // const { trip: currentTrip } = useFetchTrip(id);

  useEffect(() => {
    setIsComponentPage(true);
    return () => {
      setIsComponentPage(false);
    };
  }, []);

  switch (query.name) {
    case 'Trips':
      return <Trips />;
    case 'Profile':
      return (
        <div className="flex flex-col gap-3 px-3 py-2">
          <div className="flex items-center gap-3">
            <DetailsSvg />
            <p className={styles.header}>Details</p>
          </div>
          <Bar />
        </div>
      );
    case 'TrendsAndPreferences':
      return <Preferences />;
    case 'InternalNotes':
      return <InternalNotes />;
    // case 'DetailedTrips':
    //   return (
    //     <>
    //       <Button variant="text">Back to all trips</Button>
    //       <DetailedTripSection trip={currentTrip} />
    //     </>
    //   );
  }

  return null;
};
export default ComponentPage;
