import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';

import {
  useOverflowTooltip,
  useRouterQuery,
  useSearchPage,
  useSearchRowClick,
} from 'hooks/general';

import { ReactComponent as SearchIconSvg } from 'styles/icons/search_green.svg';
import { ReactComponent as SearchPageIconSvg } from 'styles/icons/search_page.svg';

import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';

import Button from 'components/common/Button/Button';
import SearchCompanyFilters, {
  UseSearchCompanyFiltersResult,
  useSearchCompanyFilters,
} from 'components/common/SearchCompanyFilters/SearchCompanyFilters';
import ShortcutHelpers from 'components/common/ShortcutHelpers';
import Vip from 'components/common/Vip/Vip';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { uniqBy } from 'ramda';
import {
  phoneAssignmentAtom,
  profileSourceAtom,
  searchAtom,
} from 'state/store';
import { ReactComponent as KeyboardShortcutSvg } from 'styles/icons/keyboardShortcut.svg';
import palette from 'styles/palette';
import { Company } from 'types/profile';
import { ISearchEntity } from 'types/search';
import { formatCompany, formatEmail, formatName } from 'utils/profile';
import styles from './Search.module.scss';
import ElementWithTooltip from 'components/common/ElementWithTooltip';

const useHeaderInfo = () => {
  const query = useRouterQuery();
  const [profilePhone] = useAtom(phoneAssignmentAtom);
  const source = useAtomValue(profileSourceAtom);
  const dispatchSearch = useSetAtom(searchAtom);
  const dispatchSource = useSetAtom(profileSourceAtom);
  const dispatchPhoneAssignment = useSetAtom(phoneAssignmentAtom);
  useEffect(() => {
    if (!query.phone) {
      if (source) {
        dispatchSource({ type: 'CLEAR' });
      } // we need to preserve source for cases when phone is present
      if (profilePhone) {
        dispatchPhoneAssignment({ type: 'CLEAR' });
      }
    } else {
      dispatchPhoneAssignment({ type: 'SET', value: query.phone });
    }

    return () => {
      dispatchSearch({ type: 'RESET' });
    };
  }, []);
};

const Search: FC = () => {
  const { handleClick } = useSearchRowClick();
  const { selectedCompanies, setSelectedCompanies } = useSearchCompanyFilters();
  const allCompanies = useRef<Company[]>([]);
  const {
    handleChange,
    handleSetSelectedItem,
    inputRef,
    message,
    results,
    searchIsPending,
    selectedItem,
    setSelectedItem,
    term,
  } = useSearchPage({
    mapResults: (results) => {
      allCompanies.current = uniqBy(
        (v) => v.CompanyGuid,
        results.map((v) => v.Company)
      ).sort((a, b) => (a.Name > b.Name ? 1 : -1));
      return !selectedCompanies.length
        ? results
        : results.filter((result) => {
            return !!selectedCompanies.find((selectedCompany) => {
              return (
                selectedCompany.Name === result.Company.Name &&
                selectedCompany.PseudoCity === result.Company.PseudoCity
              );
            });
          });
    },
    onEnter: (value) => handleClick(value.TravelerGuid),
  });

  useHeaderInfo();
  return (
    <div className={styles.page}>
      <div className={styles.searchItemBlock}>
        <div>
          <SearchPageIconSvg />
        </div>
        <div className={styles.backgroundText}>
          Search
          <div>Search by person name or email address</div>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputIcon}>
          {searchIsPending ? (
            <div className={styles.loader}></div>
          ) : (
            <SearchIconSvg />
          )}
        </div>
        <input
          ref={inputRef}
          className={classNames(styles.input, 'mainSearch pr-8')}
          placeholder="Start typing to search"
          value={term}
          onChange={(e) => {
            handleChange(e.target.value);
            setSelectedCompanies([]);
          }}
        />
        {message ? (
          <div className={styles.resultsContainer}>
            <div className={classNames(styles.error)}>{message}</div>
            <ShortcutHelpers isError />
          </div>
        ) : (
          <SearchResults
            {...{
              allCompanies: allCompanies.current,
              handleSetSelectedItem,
              results,
              selectedCompanies,
              selectedItem,
              setSelectedCompanies,
              setSelectedItem,
            }}
          />
        )}
        {term && (
          <Button
            variant="text"
            customPadding
            onClick={() => {
              handleChange('');
              inputRef.current?.focus();
            }}
            className="absolute right-2 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center"
          >
            <CloseSvg height={12} width={12} color={palette['grey-5']} />
          </Button>
        )}
      </div>
    </div>
  );
};

const ItemsRow = ({
  item,
  selected,
}: {
  item: ISearchEntity;
  selected: boolean;
}) => {
  const { handleClick } = useSearchRowClick();

  const formattedName = formatName(item);
  const formattedEmail = formatEmail(item);
  const formattedCompany = formatCompany(item);

  const { withOverflowTooltip: emailElement } = useOverflowTooltip(
    formattedEmail,
    <div className={styles.email} />
  );

  return (
    <div
      className={classNames(styles.searchRow, {
        'bg-grey-1': selected,
      })}
      onClick={() => handleClick(item.TravelerGuid)}
    >
      <div className={classNames('flex gap-1', styles.name)}>
        <ElementWithTooltip
          strategy="fixed"
          value={formattedName}
          className="whitespace-normal"
        >
          <div />
        </ElementWithTooltip>
        {item.VIP && <Vip value={item.VIP} />}
      </div>
      {emailElement}
      <ElementWithTooltip strategy="fixed" value={formattedCompany}>
        <div className={styles.company} />
      </ElementWithTooltip>
      <KeyboardShortcutSvg
        color={selected ? palette['midnight-black'] : palette['grey-6']}
        opacity={selected ? '1' : 0.3}
      />
    </div>
  );
};

export type UseSearchPageResult = ReturnType<typeof useSearchPage>;

const SearchResults: FC<{
  allCompanies: Company[];
  results: UseSearchPageResult['results'];
  setSelectedItem: UseSearchPageResult['setSelectedItem'];
  selectedItem: UseSearchPageResult['selectedItem'];
  handleSetSelectedItem: UseSearchPageResult['handleSetSelectedItem'];
  selectedCompanies: UseSearchCompanyFiltersResult['selectedCompanies'];
  setSelectedCompanies: UseSearchCompanyFiltersResult['setSelectedCompanies'];
}> = ({
  allCompanies,
  handleSetSelectedItem,
  results,
  selectedCompanies,
  selectedItem,
  setSelectedCompanies,
  setSelectedItem,
}) => {
  return (
    !!results.length && (
      <div className={styles.resultsContainer}>
        <SearchCompanyFilters
          {...{
            companies: allCompanies,
            selectedCompanies,
            setSelectedCompanies: (newCompanies) => {
              handleSetSelectedItem(0);
              setSelectedCompanies(newCompanies);
            },
          }}
        />
        <div
          className={classNames(
            styles.searchRow,
            styles.header,
            'pr-[52px]'
            // If no scrollbar then we'd use this style, if this ever comes as a bug 'pr-[36px]'
          )}
        >
          <div className={styles.name}>Name</div>
          <div className={styles.email}>Email</div>
          <div className={styles.company}>Company (PCC - Starbar)</div>
        </div>
        <div className="max-h-[calc(100vh_-_225px)] overflow-auto">
          {results.map((item, i) => (
            <div
              onMouseMove={() => setSelectedItem(i)}
              key={`${item.TravelerGuid} + ${i}`}
              data-search-row-index={i}
            >
              <ItemsRow item={item} selected={i === selectedItem} />
            </div>
          ))}
        </div>
        <ShortcutHelpers />
      </div>
    )
  );
};

export default Search;
