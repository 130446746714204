import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as DownShortcutSvg } from 'styles/icons/downShortcut.svg';
import { ReactComponent as EscShortcutSvg } from 'styles/icons/escShortcut.svg';
import { ReactComponent as KeyboardShortcutSvg } from 'styles/icons/keyboardShortcut.svg';
import palette from 'styles/palette';
import Typography from './Typography/Typography';

const ShortcutHelpers: FC<{
  isError?: boolean;
  isSearchComponent?: boolean;
}> = ({ isError, isSearchComponent }) => {
  return (
    <div className="flex justify-between bg-grey-1 p-2 shadow-[inset_0px_1px_0px_#E3E8EC]">
      <div
        className={classNames('flex items-center gap-2', {
          'opacity-30': isError,
        })}
      >
        <div className="flex gap-1">
          <DownShortcutSvg />
          <DownShortcutSvg transform="rotate(180)" />
        </div>
        <Typography
          variant="bodyMedium"
          className="uppercase"
          color="charcoal-grey"
        >
          to navigate
        </Typography>
      </div>
      <div className="flex items-center  gap-2 ">
        <EscShortcutSvg />
        <Typography
          variant="bodyMedium"
          className="uppercase"
          color="charcoal-grey"
        >
          to clear request
        </Typography>
      </div>
      <div
        className={classNames('flex items-center gap-2', {
          'opacity-30': isError,
        })}
      >
        <Typography
          variant="bodyMedium"
          className="uppercase"
          color="charcoal-grey"
        >
          to open profile
        </Typography>
        <div className="flex gap-1">
          <KeyboardShortcutSvg color={palette['grey-6']} />
        </div>
      </div>
    </div>
  );
};
export default ShortcutHelpers;
