import classNames from 'classnames';

import { format } from 'date-fns/esm';
import { pathOr } from 'ramda';
import { FC, ReactElement } from 'react';

import Typography from 'components/common/Typography/Typography';
import { ReactComponent as JetSvg } from 'styles/icons/amenities/jet.svg';
import { IFlightBooking } from 'types/trips';
import { renderPlurals } from 'utils/format';
import { formatTime, getBookingType } from 'utils/trips';

import FlightDate from 'components/common/FlightDate';
import Line from 'components/common/Line/Line';
import Sherpa from 'components/common/Sherpa/Sherpa';
import { getAllAffectingMatches } from 'components/pages/Profile/components/Trips/components/TripItem/TripItem';
import { BREAKPOINTS } from 'constants/general';
import { useWindowSize } from 'usehooks-ts';
import { isNA } from 'utils/common';
import { renderOptions } from '../../DetailedTrips';
import styles from '../../DetailedTrips.module.scss';
import AlertsList from './AlertsList';
import EstimatedDateInfo from './EstimatedDateInfo';
import Layover, { getLayoverMainCityOrIata } from './Layover';

type Options = (
  | {
      label: string;
      value: string | undefined;
      render?: undefined;
    }
  | {
      render: () => ReactElement;
      label?: undefined;
      value?: undefined;
    }
)[];

interface DisplayInfo {
  time?: string;
  estimatedTime?: string;
  place: string;
  terminal: string | undefined;
  gate: string | undefined;
  type: 'dep' | 'arr';
}

const renderDepArrInfo = ({
  estimatedTime,
  gate,
  place,
  terminal,
  time,
  type,
}: DisplayInfo) => {
  const timeToShow = estimatedTime ?? time;
  const isDeparture = type === 'dep';
  const isArrival = type === 'arr';
  return (
    <div className={classNames('flex flex-col', styles.fixWidth)}>
      {/* <div className="flex max-w-[150px] items-center gap-1 md:max-w-full">
        <ElementWithTooltip
          value={
            timeToShow
              ? format(new Date(timeToShow), 'd MMM yyyy, h:mm a')
              : 'N/A'
          }
        >
          <Typography
            variant="bodyMedium"
            className={classNames({
              'ml-auto text-right lg:ml-0 lg:text-left': isArrival,
            })}
            data-e2e-flight_details_departure_date={
              isDeparture ? true : undefined
            }
            data-e2e-flight_details_arrival_date={isArrival ? true : undefined}
          />
        </ElementWithTooltip> */}
      <div className="flex items-center gap-1">
        <Typography
          variant="bodyMedium"
          className={classNames({
            'ml-auto text-right lg:ml-0 lg:text-left': isArrival,
          })}
          data-e2e-flight_details_departure_date={
            isDeparture ? true : undefined
          }
          data-e2e-flight_details_arrival_date={isArrival ? true : undefined}
        >
          {timeToShow
            ? format(new Date(timeToShow), 'd MMM yyyy, h:mm a')
            : 'N/A'}
        </Typography>
        {estimatedTime && time && time !== estimatedTime && (
          <EstimatedDateInfo
            value={time}
            data-e2e-flight_details_departure_local_date={
              isDeparture ? true : undefined
            }
            className="self-center"
            data-e2e-flight_details_arrival_local_date={
              isArrival ? true : undefined
            }
          />
        )}
      </div>
      <Typography
        className={classNames({ 'text-right lg:text-left': isArrival })}
        variant="bodyMedium2"
      >
        {place}
      </Typography>
      <Typography
        variant="bodyMedium"
        className={classNames({ 'text-right lg:text-left': isArrival })}
      >
        Terminal {terminal || 'N/A'}, Gate {gate || 'N/A'}{' '}
      </Typography>
    </div>
  );
};

export const getCityNameFromFlight = (
  booking: IFlightBooking,
  isArrival: boolean
) => {
  const departureOrArrival = isArrival ? 'ArrivalAirport' : 'DepartureAirport';
  return pathOr(
    'N/A',
    ['Flight', departureOrArrival, 'MainCity', 'Name'],
    booking
  );
};
export const displayFlightInfo = ({
  airplaneInfo,
  arrInfo,
  classOfService,
  convertedDuration,
  depInfo,
  flightOperatorInfo,
  options,
  sherpa,
  windowWidth,
}: {
  convertedDuration: string;
  airplaneInfo?: string;
  classOfService?: string;
  flightOperatorInfo: string;
  depInfo: DisplayInfo;
  arrInfo: DisplayInfo;
  options: Options;
  sherpa?: string;
  windowWidth: number;
}) => {
  const flightInfo = [flightOperatorInfo, airplaneInfo, classOfService]
    .filter((v) => !!v && !!v.trim())
    .join(' / ');
  const mobileFlightInfo = (
    <div className="flex flex-col justify-between lg:hidden">
      {convertedDuration && (
        <div className="flex items-center justify-between">
          <Typography variant="bodySmall2">{convertedDuration}</Typography>
          {sherpa && <Sherpa data-e2e-sherpa="mobileFlight" value={sherpa} />}
        </div>
      )}
      {flightOperatorInfo && (
        <Typography variant="bodySmall">{flightOperatorInfo}</Typography>
      )}
      {airplaneInfo && (
        <Typography variant="bodySmall">{airplaneInfo}</Typography>
      )}
      {classOfService && (
        <>
          {windowWidth >= BREAKPOINTS.LG ? (
            <Typography variant="bodySmall">{classOfService}</Typography>
          ) : (
            <Typography variant="bodySmall">{classOfService} class</Typography>
          )}
        </>
      )}
    </div>
  );

  const desktopFlightInfo = (
    <div className="hidden justify-between lg:flex">
      <div className="flex">
        <Typography variant="bodySmall2">{convertedDuration}</Typography>
        {convertedDuration && flightInfo && (
          <Typography variant="bodySmall">&nbsp;/&nbsp;</Typography>
        )}
        <Typography variant="bodySmall">{flightInfo}</Typography>
      </div>
      {sherpa && <Sherpa data-e2e-sherpa="desktopFlight" value={sherpa} />}
    </div>
  );

  return (
    <div className="mt-3 flex-col ">
      {desktopFlightInfo}
      {mobileFlightInfo}

      <div className="mt-2  flex flex-col gap-6 lg:flex-row ">
        <div className="flex grow justify-between gap-6 lg:justify-start">
          {renderDepArrInfo(depInfo)}
          {renderDepArrInfo(arrInfo)}
        </div>
        <div className={styles.optionsBlock}>{options.map(renderOptions)}</div>
      </div>
    </div>
  );
};

export const getIataCityName = (
  iata: string,
  cityName: string,
  defaultString: string = 'Departure Airport N/A'
) => {
  if (isNA(iata) && isNA(cityName)) return defaultString;
  if (isNA(iata)) return cityName;
  if (isNA(cityName)) return iata;
  return `${iata}, ${cityName}`;
};
export const getBaggageInfo = (booking: IFlightBooking) => {
  if (!booking.BaggageAllowance) return 'N/A';

  const {
    MaximumWeight: maxWeight,
    Pieces: pieces,
    WeightUnit: weightUnit,
  } = booking.BaggageAllowance;
  if (maxWeight && weightUnit) {
    return `${maxWeight}${weightUnit}`;
  }
  if (pieces !== null) {
    return renderPlurals({
      count: pieces,
      entity: ['piece', 'pieces'],
    });
  }

  return 'N/A';
};
export const renderMeals = (booking: IFlightBooking) => {
  const value = booking?.RequestedMeals?.length
    ? booking.RequestedMeals?.map((v) => v?.Description || 'N/A')?.join(', ') ||
      'N/A'
    : 'N/A';
  const noValue = !value || isNA(value.toString().trim());
  return (
    <Line className={styles.customLine} customMargin key="random">
      <Typography color={!noValue ? 'black' : 'grey-4'} variant="bodyMedium">
        Meals
      </Typography>

      <Typography
        color={!noValue ? 'black' : 'grey-4'}
        variant="bodyMedium2"
        className="break-alt text-right"
      >
        {value}
      </Typography>
    </Line>
  );
};
export const getFlightOperatorInfo = (booking: IFlightBooking) => {
  const flightInfo = booking.Flight;

  return `${flightInfo?.AirLine} ${flightInfo?.Number} ${
    flightInfo?.Operator && 'operated by ' + flightInfo.Operator
  }`
    .replaceAll(/null|undefined/g, '')
    .trim();
};
export const getFlightDisplayDataFromBooking = (booking: IFlightBooking) => {
  const alerts = getAllAffectingMatches(booking);
  const arrivalCity = getCityNameFromFlight(booking, true);
  const flightInfo = booking.Flight;
  const arrivalIata = flightInfo?.ArrivalAirport?.Iata ?? 'N/A';
  const arrivalTerminal = flightInfo?.ArrivalTerminal;
  const arrivalGate = booking.Flight?.ArrivalGate || '';
  const departureCity = getCityNameFromFlight(booking, false);
  const departureIata = flightInfo?.DepartureAirport?.Iata ?? 'N/A';
  const departureTerminal = flightInfo?.DepartureTerminal || '';
  const departureGate = flightInfo?.DepartureGate || '';
  const departureDate = booking.LocalDepartureAt;
  const arrivalDate = booking.LocalArrivalAt;
  const pnr = booking.PnrRecordLocator;
  const layovers = booking.Layovers;
  const flightSummary = booking.FlightSummary;
  const flightDepartureAt = flightSummary?.LocalDepartureAt || '';
  const flightEstimatedDepartureAt =
    flightInfo?.EstimatedLocalDepartureAt || '';
  const departureToShow = flightEstimatedDepartureAt || flightDepartureAt;
  const flightArrivalAt = flightSummary?.LocalArrivalAt || '';
  const lastLayover =
    booking?.Layovers?.[booking?.Layovers?.length - 1]?.FlightBooking;

  const flightEstimatedArrivalAt =
    lastLayover?.Flight?.EstimatedLocalArrivalAt ||
    lastLayover?.LocalArrivalAt ||
    flightInfo?.EstimatedLocalArrivalAt ||
    '';
  const arrivalToShow = flightEstimatedArrivalAt || flightArrivalAt;
  const flightDepartureIata = flightSummary?.DepartureAirport.Iata ?? 'N/A';
  const flightDepartureCity =
    flightSummary?.DepartureAirport.MainCity?.Name ?? 'N/A';
  const departureIataCity = getIataCityName(
    flightDepartureIata,
    flightDepartureCity
  );
  const flightArrivalIata = flightSummary?.ArrivalAirport.Iata ?? 'N/A';
  const flightArrivalCity =
    flightSummary?.ArrivalAirport.MainCity?.Name ?? 'N/A';
  const arrivalIataCity = getIataCityName(
    flightArrivalIata,
    flightArrivalCity,
    'Arrival Airport N/A'
  );
  const layoversDuration = layovers
    ? layovers.reduce(
        (acc, el) =>
          acc + el.Duration + (el?.FlightBooking?.Flight?.Duration ?? 0),
        0
      )
    : 0;
  const totalDuration = (flightInfo?.Duration || 0) + layoversDuration;
  const mainFlightDuration = flightInfo?.Duration;

  const convertedMainFlightDuration = mainFlightDuration
    ? formatTime(mainFlightDuration)
    : 'Duration N/A';

  const airplaneInfo = flightInfo?.Equipment.Name;
  const classOfService = booking.ClassOfService;
  const options = [
    { label: 'Seat', value: booking.Seat || '' },
    {
      label: 'Baggage Info',
      value: getBaggageInfo(booking),
    },
    {
      render: () => renderMeals(booking),
    },
  ];
  const sherpa = booking.FlightSummary?.CovidInfo?.ExtraInfoUrl;
  const layoverAmount = layovers?.length || 0;

  const flightOperatorInfo = getFlightOperatorInfo(booking);

  const depInfo: DisplayInfo = {
    estimatedTime: flightEstimatedDepartureAt,
    gate: departureGate,
    place: getIataCityName(departureIata, departureCity),
    terminal: departureTerminal,
    time: departureDate,
    type: 'dep',
  };
  const arrInfo: DisplayInfo = {
    estimatedTime: flightInfo?.EstimatedLocalArrivalAt || '',
    gate: arrivalGate,
    place: getIataCityName(arrivalIata, arrivalCity, 'Arrival Airport N/A'),
    terminal: arrivalTerminal,
    time: arrivalDate,
    type: 'arr',
  };
  const layoverStops = layovers?.map((layover) => {
    const result = formatTime(layover.Duration);
    const layoverStop = getLayoverMainCityOrIata(layover.FlightBooking);
    if (layoverStop) {
      return `${result} in ${layoverStop}`;
    }
    return result;
  });
  return {
    airplaneInfo,
    alerts,
    arrInfo,
    arrivalIataCity,
    arrivalToShow,
    classOfService,
    convertedMainFlightDuration,
    depInfo,
    departureIataCity,
    departureToShow,
    flightArrivalAt,
    flightDepartureAt,
    flightEstimatedArrivalAt,
    flightEstimatedDepartureAt,
    flightOperatorInfo,
    layoverAmount,
    layoverStops,
    layovers,
    options,
    pnr,
    sherpa,
    totalDuration,
  };
};

const AirSection: FC<{ booking: IFlightBooking; id: number }> = ({
  booking,
  id,
}) => {
  const {
    airplaneInfo,
    alerts,
    arrInfo,
    arrivalIataCity,
    arrivalToShow,
    classOfService,
    convertedMainFlightDuration,
    depInfo,
    departureIataCity,
    departureToShow,
    flightArrivalAt,
    flightDepartureAt,
    flightEstimatedArrivalAt,
    flightEstimatedDepartureAt,
    flightOperatorInfo,
    layoverAmount,
    layoverStops,
    layovers,
    options,
    totalDuration,
  } = getFlightDisplayDataFromBooking(booking);
  const { width } = useWindowSize();

  return (
    <div
      className={styles.sectionContainer}
      id={`${getBookingType(booking)}_${id}`}
    >
      <AlertsList matches={alerts} />
      <div className="flex gap-[20px]">
        <JetSvg
          width={24}
          height={24}
          className={classNames(styles.jet, 'shrink ')}
          data-e2e-icon="Air"
        />
        <div className="w-full">
          <div className={styles.segmentPrimaryInfo}>
            <FlightDate
              type="detailedTrips"
              className={classNames(styles.from, styles.fixWidth)}
              cityCode={departureIataCity}
              date={
                departureToShow
                  ? format(new Date(departureToShow), 'd MMM yyyy, h:mm a')
                  : 'N/A'
              }
              preciseDate={flightDepartureAt}
              showPreciseDate={
                !!(
                  flightEstimatedDepartureAt &&
                  flightDepartureAt &&
                  flightEstimatedDepartureAt !== flightDepartureAt
                )
              }
            />
            <FlightDate
              type="detailedTrips"
              className={classNames(
                styles.to,
                styles.fixWidth,
                'text-right lg:text-left'
              )}
              cityCode={arrivalIataCity}
              date={
                arrivalToShow
                  ? format(new Date(arrivalToShow), 'd MMM yyyy, h:mm a')
                  : 'N/A'
              }
              preciseDate={flightArrivalAt}
              showPreciseDate={
                !!(
                  flightEstimatedArrivalAt &&
                  flightArrivalAt &&
                  flightEstimatedArrivalAt !== flightArrivalAt
                )
              }
            />

            <div
              className={classNames(
                'mr-auto flex flex-col gap-1 lg:mr-0',
                styles.stops
              )}
            >
              <Typography variant="bodySmall" data-e2e-travel_duration>
                {totalDuration ? formatTime(totalDuration) : 'Duration N/A'}{' '}
                {!!layoverAmount &&
                  `(${renderPlurals({
                    count: layoverAmount,
                    entity: ['stop', 'stops'],
                  })})`}
              </Typography>
              {layoverStops && (
                <div>
                  {layoverStops.map((stop, idx) => {
                    return (
                      <Typography key={idx} variant="bodySmall">
                        {stop}
                      </Typography>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="mt-3 flex items-start lg:items-center">
            <Typography
              variant="bodySmall2"
              className={classNames(
                'mb-auto bg-green-highlight px-2 py-1',
                styles.label
              )}
            >
              {booking.IsPassive ? 'Passive' : 'GDS'}
            </Typography>
            <div className="flex grow flex-col gap-1 bg-grey-1 px-2 py-1 lg:flex-row">
              <Typography className={styles.confirmation} variant="bodySmall">
                Confirmation Number:{' '}
                <span className="font-bold">
                  {booking.NativeRecordLocator || 'N/A'}
                </span>
              </Typography>
              <Typography
                variant="bodySmall"
                className={classNames(styles.status, 'lg:ml-auto')}
              >
                Status:
                <span className="font-bold">
                  {' '}
                  {booking.StatusCode ?? 'N/A'}
                </span>
              </Typography>
              <Typography variant="bodySmall" className={styles.ticket}>
                Ticket Number:
                <span className="font-bold">
                  {' '}
                  {booking.TicketNumber || 'N/A'}
                </span>
              </Typography>
            </div>
          </div>
          {displayFlightInfo({
            airplaneInfo,
            arrInfo,
            classOfService,
            convertedDuration: convertedMainFlightDuration,
            depInfo,
            flightOperatorInfo,
            options,
            sherpa: booking.CovidInfo?.ExtraInfoUrl,
            windowWidth: width,
          })}
          {layovers?.map((layover, idx) => {
            return <Layover layover={layover} key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AirSection;
