import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as AlertOctagonSvg } from 'styles/icons/alert_octagon.svg';
import { ReactComponent as SuccessSvg } from 'styles/icons/check.svg';
import { ReactComponent as CloseSvg } from 'styles/icons/close.svg';
import { ReactComponent as RetrySvg } from 'styles/icons/retry.svg';
import palette from 'styles/palette';
import Notification from '../Notification';
import Typography from '../Typography/Typography';
import styles from './ActionNotification.module.scss';

type ActionNotificationProps = {
  isOpen: boolean;
  onClose: () => void;
  variant: 'success' | 'error';
  retry: () => void;
  text: string;
};

const ActionNotification: FC<ActionNotificationProps> = ({
  isOpen,
  onClose,
  retry,
  text,
  variant,
}) => {
  const isError = variant === 'error';
  return (
    <Notification isOpen={isOpen} onClose={onClose}>
      <div className="flex min-h-[44px] items-stretch">
        <div
          className={classNames(
            'flex w-9 items-center justify-center',
            isError ? 'bg-ui-red-darken' : 'bg-ui-green-darken'
          )}
        >
          {isError ? (
            <AlertOctagonSvg className="h-[18.33px] w-[18.33px] text-clarity-white" />
          ) : (
            <SuccessSvg className="h-[18.33px] w-[18.33px] text-clarity-white" />
          )}
        </div>
        <div className="flex items-center justify-center">
          <Typography
            color="clarity-white"
            variant="bodyMedium2"
            className="py-2 pl-3"
          >
            {text}
          </Typography>
        </div>
        <div className="ml-auto flex items-center justify-center gap-2">
          {isError && (
            <div
              className="ml-auto flex cursor-pointer items-center"
              onClick={retry}
            >
              <RetrySvg className={styles.retrySvg} />
              <Typography variant="bodyMedium2" color="white-a70">
                Retry
              </Typography>
            </div>
          )}
          <div className="mr-3 flex w-5 items-center justify-center">
            <CloseSvg
              className={classNames('h-[11.67px] w-[11.67px] cursor-pointer')}
              color={palette['grey-5']}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Notification>
  );
};

export default ActionNotification;
