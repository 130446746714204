import Typography from 'components/common/Typography/Typography';
import { ReactComponent as RestrictedPageSvg } from 'styles/icons/restricted_access.svg';

const RestrictedAccess = () => {
  return (
    <div
      className="flex grow flex-col items-center justify-center bg-clarity-white"
      data-e2e-id="restrictedAccess"
    >
      <RestrictedPageSvg className="mb-[24px]" />
      <Typography variant="headingSmall" color="grey-6">
        Profile does not exist or you do not have Permission to view this
        Profile
      </Typography>
      {/* <Typography variant="bodyLarge" color="grey-6">
        If you think you are getting this message in error, please contact
        support.
      </Typography> */}
    </div>
  );
};

export default RestrictedAccess;
