import { useAtomValue } from 'jotai';
import {
  useIsMutating,
  useMutation,
  useMutationState,
  useQuery,
} from '@tanstack/react-query';
import { profileAtom } from 'state/store';
import { QueryKey, apiService } from 'utils/apiService';

import { queryClient } from 'index';
import { INote, NotePriorityKeys } from 'types/notes';
import { sendGoogleEvent } from 'utils/googleTag';
import { mapNote } from './internalNotes';
import { useProfile } from './profile';

// import { apiService, QueryKey } from 'utils/apiService';

export interface IApiCompanyNote {
  CreatedBy: string;
  CreatedAtUtc: string;
  NoteGuid: number;
  Note: string;
  CompanyGuid: string;
  UpdatedAtUtc: string;
  UpdatedBy: string;
  Priority: NotePriorityKeys;
}

export interface ICompanyDoc {
  documentGuid: string;
  companyGuid: string;
  title: string;
  url: string;
  createdAtUtc: string;
  updatedAtUtc?: string;
  createdBy: string;
  updatedBy?: string;
}

export interface IAPICompanyDoc {
  DocumentGuid: string;
  CompanyGuid: string;
  Title: string;
  Url: string;
  CreatedAtUtc: string;
  UpdatedAtUtc?: string;
  CreatedBy: string;
  UpdatedBy?: string;
}
type GenericObject = { [key: string]: any };

export function toCamelCase(str: string): string {
  return str.replace(/^[A-Z]/, (match) => match.toLowerCase());
}

export function convertKeysToCamelCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => convertKeysToCamelCase(value));
  }

  const result: GenericObject = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = toCamelCase(key);
      result[camelKey] = convertKeysToCamelCase(obj[key]);
    }
  }

  return result;
}

export const mapToCamelCase = <T>(doc: any) => {
  return convertKeysToCamelCase(doc) as T;
};
export const useFetchCompanyDocs = () => {
  const profile = useProfile()[0].value;
  return useQuery({
    queryFn: () => {
      return apiService<IAPICompanyDoc[]>({
        key: [QueryKey.CompanyDocs, profile.companyGuid],
        method: 'get',
        url: `/documents?travelerGuid=${profile.guid}`,
      }).then((docs) => docs.map((doc) => mapToCamelCase<ICompanyDoc>(doc)));
    },
    queryKey: ['fetchCompanyDocs', profile.companyGuid],
  });
};

export const useAddCompanyDoc = () => {
  const profile = useProfile()[0].value;
  return useMutation({
    mutationFn: async (data: { title: string; url: string }) => {
      try {
        const result = await apiService({
          data: {
            ...data,
            travelerGuid: profile.guid,
          },
          method: 'post',
          url: 'documents',
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.CompanyDocs, profile.companyGuid],
        });

        queryClient.invalidateQueries({
          queryKey: ['fetchCompanyDocs', profile.companyGuid],
        });
        sendGoogleEvent('company_document_create');

        return result;
      } catch (e) {
        throw new Error('Failed to add company document. Company not found.');
      }
    },

    mutationKey: ['addCompanyDoc'],
  });
};

export const useEditCompanyDoc = () => {
  const profile = useProfile()[0].value;
  return useMutation({
    mutationFn: async (data: {
      documentGuid: string;
      title: string;
      url: string;
    }) => {
      try {
        const result = await apiService({
          data: {
            ...data,
            travelerGuid: profile.guid,
          },
          method: 'post',
          url: 'documents',
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.CompanyDocs, profile.companyGuid],
        });
        queryClient.invalidateQueries({
          queryKey: ['fetchCompanyDocs', profile.companyGuid],
        });
        sendGoogleEvent('company_document_edit');
        return result;
      } catch (e) {
        throw new Error('Failed to edit company document. Company not found.');
      }
    },

    mutationKey: ['editCompanyDoc'],
  });
};

export const useDeleteCompanyDoc = () => {
  const profile = useProfile()[0].value;
  return useMutation({
    mutationFn: async (id: ICompanyDoc['documentGuid']) => {
      try {
        await apiService({
          method: 'delete',
          url: `documents/?travelerGuid=${profile.guid}&documentGuid=${id}`,
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.CompanyDocs, profile.companyGuid],
        });
        queryClient.invalidateQueries({
          queryKey: ['fetchCompanyDocs', profile.companyGuid],
        });
        sendGoogleEvent('company_document_delete');
      } catch (e) {
        throw new Error(
          'Failed to delete company document. Company not found.'
        );
      }
    },

    mutationKey: ['deleteCompanyDoc'],
  });
};
export const useFetchCompanyNotes = () => {
  const profile = useProfile()[0].value;
  const { data, ...rest } = useQuery({
    queryFn: () => {
      return apiService<IApiCompanyNote[]>({
        key: [QueryKey.InternalCompanyNotes, profile.companyGuid],
        method: 'get',
        url: `/companyNotes?travelerGuid=${profile.guid}`,
      });
    },
    queryKey: ['fetchCompanyNotes', profile.companyGuid],
    select: (docs) => docs.map((doc) => mapNote(doc)),
  });
  return { ...rest, data: data || [] };
};

export const useAddCompanyNote = () => {
  const { refetch: refetchCompanyNotes } = useFetchCompanyNotes();
  const profile = useProfile()[0].value;

  const { mutate: addCompanyNote, ...rest } = useMutation({
    mutationFn: async ({ text, ...data }: Pick<INote, 'text' | 'priority'>) => {
      try {
        await apiService({
          data: {
            note: text,
            travelerGuid: profile.guid,
            ...data,
          },
          method: 'post',
          url: '/companyNotes',
        });
        queryClient.removeQueries({
          queryKey: [
            QueryKey.InternalCompanyNotes,
            profile?.companyGuid?.toUpperCase(),
          ],
        });
        refetchCompanyNotes();
        sendGoogleEvent('company_note_create');
      } catch (e) {
        throw new Error('Failed to add company note. Company not found.');
      }
    },

    mutationKey: ['addCompanyNote'],
  });

  return { addCompanyNote, ...rest };
};
export const useEditCompanyNote = () => {
  const { refetch: refetchCompanyNotes } = useFetchCompanyNotes();
  const profile = useProfile()[0].value;
  const { mutate: editCompanyNote, ...rest } = useMutation({
    mutationFn: async ({
      id,
      text,
      ...data
    }: Pick<INote, 'id' | 'text' | 'priority'>) => {
      try {
        await apiService({
          data: {
            note: text,
            noteGuid: id,
            travelerGuid: profile.guid,
            ...data,
          },
          method: 'post',
          url: `/companyNotes`,
        });
        queryClient.removeQueries({
          queryKey: [
            QueryKey.InternalCompanyNotes,
            profile?.companyGuid?.toUpperCase(),
          ],
        });

        refetchCompanyNotes();
        sendGoogleEvent('company_note_edit');
      } catch (e) {
        throw new Error('Failed to edit company note. Company not found.');
      }
    },

    mutationKey: ['editCompanyNote'],
  });
  return {
    editCompanyNote,
    ...rest,
  };
};

export const useDeleteCompanyNote = () => {
  const { refetch: refetchCompanyNotes } = useFetchCompanyNotes();
  const profile = useProfile()[0].value;

  const { mutate: deleteCompanyNote, ...rest } = useMutation({
    mutationFn: async ({ id }: { id: INote['id'] }) => {
      try {
        await apiService({
          method: 'delete',
          url: `/companyNotes?noteGuid=${id}&travelerGuid=${profile.guid}`,
        });
        queryClient.removeQueries({
          queryKey: [QueryKey.InternalCompanyNotes, profile?.companyGuid],
        });
        refetchCompanyNotes();
        sendGoogleEvent('company_note_delete');
      } catch (e) {
        throw new Error('Failed to delete company note. Company not found.');
      }
    },

    mutationKey: ['deleteCompanyNote'],
  });
  return { deleteCompanyNote, ...rest };
};
export const useIsEditingOrDeletingCompanyNote = (id?: INote['id']) => {
  if (!id) {
    return false;
  }
  const isEditingCompanyNote = useMutationState({
    filters: { mutationKey: ['editCompanyNote'], status: 'pending' },
    select: (mutation) =>
      (mutation?.state?.variables as { id: string }).id === id,
  }).filter(Boolean);
  const isDeletingCompanyNote = useMutationState({
    filters: { mutationKey: ['deleteCompanyNote'], status: 'pending' },
    select: (mutation) => {
      return (mutation?.state?.variables as { id: string }).id === id;
    },
  }).filter(Boolean);

  return !!(isEditingCompanyNote.length || isDeletingCompanyNote.length);
};
