import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { isEmpty, length } from 'ramda';
import { FC, HTMLProps, useEffect, useState } from 'react';

import Badge from 'components/common/Badge/Badge';
import CopyButton from 'components/common/CopyButton/CopyButton';
import UncontrolledPopper, {
  ControlledPopper,
  useControlledPopper,
} from 'components/common/Popper/Popper';
import {
  breadcrumbsAtom,
  isComponentPageAtom,
  isFromArrangersOrApproversBlockAtom,
  requireCallerAuthAtom,
} from 'state/store';
import { ReactComponent as InfoSvg } from 'styles/icons/info.svg';

import { ReactComponent as UserSvg } from 'styles/icons/user.svg';

import { ReactComponent as ChevronDownSvg } from 'styles/icons/chevron_down.svg';
import palette from 'styles/palette';
import { IAdditionalItem, IProfile } from 'types/profile';
import { QueryKey, apiService } from 'utils/apiService';
import {
  getFirstAndLast,
  isNA,
  pushTravellersOrProfilePage,
} from 'utils/common';

import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography/Typography';
import { useProfile } from 'hooks/profile';
import { useNavigate } from 'react-router-dom';
import { formatProfile } from 'utils/profile';
import styles from './Bar.module.scss';

// import { ReactComponent as EditSvg } from 'styles/icons/edit.svg';

const PhoneContainer: FC<{ profile: IProfile }> = ({ profile }) => {
  const value = (
    <span data-e2e-bar="phone-value" className={styles.phone}>
      {profile.phone}
    </span>
  );
  return isEmpty(profile.additionalPhones) ? (
    <div className={classNames(styles.value, styles.gap8)}>{value}</div>
  ) : (
    <UncontrolledPopper
      trigger="hover"
      content={
        <AdditionalInfoList type="phone" items={profile.additionalPhones} />
      }
      offsetOptions={10}
      className="overflow-auto"
    >
      <div className={classNames(styles.value, styles.gap8)}>
        {value}
        <Badge>+{length(profile.additionalPhones)}</Badge>
      </div>
    </UncontrolledPopper>
  );
};

const EmailContainer: FC<{ profile: IProfile }> = ({ profile }) => {
  const value =
    profile.email === 'N/A' ? (
      <span data-e2e-bar="email-value">{profile.email}</span>
    ) : (
      <a
        data-e2e-bar="email-value"
        className={styles.emailLink}
        href={`mailto:${profile.email}`}
        onBlur={(e) => {
          setIsOpen(false);
          e.stopPropagation();
        }}
      >
        {profile.email}
      </a>
    );
  const { isOpen, setIsOpen } = useControlledPopper();
  return isEmpty(profile.additionalEmails) ? (
    <div className={classNames(styles.value, styles.gap8)}>{value}</div>
  ) : (
    <ControlledPopper
      trigger="hover"
      open={isOpen}
      setOpen={setIsOpen}
      content={
        <AdditionalInfoList type="email" items={profile.additionalEmails} />
      }
      offsetOptions={10}
      className="overflow-auto"
    >
      <div className={classNames(styles.value, styles.gap8)}>
        {value}
        <Badge className="min-w-fit">+{length(profile.additionalEmails)}</Badge>
      </div>
    </ControlledPopper>
  );
};

const Bar: FC = () => {
  const profile = useProfile()[0].value;
  const isComponentPage = useAtomValue(isComponentPageAtom);
  if (profile.guid === 'N/A') return null;

  return (
    <div
      className={classNames(styles.bar, !isComponentPage && 'max-w-[240px]')}
    >
      <div>
        <div className={styles.field}>Nickname</div>
        <div className={styles.value}>
          <span data-e2e-bar="nickName-value">{profile.nickName}</span>
          {/* <EditSvg color='#818181'/> */}
        </div>
      </div>
      <div>
        <div className={styles.field}>Position</div>
        <div className={styles.value}>
          <span data-e2e-bar="position-value" className="break-all">
            {profile.position}
          </span>
        </div>
      </div>
      <div>
        <div className={styles.field}>PCC / OID</div>
        <div className={styles.value}>
          <div className="flex gap-1">
            <Typography variant="bodyMedium">
              {profile.rawCompany.GDSName === 'None'
                ? 'N/A'
                : profile.rawCompany.GDSName}
              :
            </Typography>
            <span data-e2e-bar="pcc-value">{profile.pcc}</span>
          </div>
          {!isNA(profile.pcc) && (
            <CopyButton className={styles.copy} value={profile.pcc} />
          )}
        </div>
      </div>
      <div>
        <div className={styles.field}>GDS Profile Locator</div>
        <div className={styles.value}>
          <span data-e2e-bar="gdsLocator-value">{profile.GdsLocator}</span>
          {!isNA(profile.GdsLocator) && (
            <CopyButton className={styles.copy} value={profile.GdsLocator} />
          )}
        </div>
      </div>
      <div>
        <div className={styles.field}>Company</div>
        <div className={styles.value}>
          <span data-e2e-bar="company-value">
            {profile.company.trim() || 'N/A'}
          </span>
        </div>
      </div>
      <div>
        <div className={styles.field}>Employee ID</div>
        <div className={styles.value}>
          <span data-e2e-bar="employeeId-value">{profile.employeeId}</span>
        </div>
      </div>
      <div>
        <div className={styles.field}>SAP Code</div>
        <div className={styles.value}>
          <span data-e2e-bar="sap-value">{profile.sapCode}</span>
          {!isNA(profile.sapCode) && (
            <CopyButton className={styles.copy} value={profile.sapCode} />
          )}
        </div>
      </div>
      <div
        className={classNames(
          styles.fitContent,
          !isComponentPage && 'max-w-[224px]'
        )}
      >
        <div className={styles.field}>{profile.phoneTypeName || 'Phone'}</div>
        <PhoneContainer profile={profile} />
      </div>
      <div
        className={classNames(
          styles.fitContent,
          !isComponentPage && 'max-w-[224px]'
        )}
      >
        <div className={styles.field}>E-mail</div>
        <EmailContainer profile={profile} />
      </div>
      {(!!profile.travelArrangers.length || !!profile.Manager.length) && (
        <div className={styles.hr} />
      )}
      <ArrangersBlock profile={profile} />
      <ApproversBlock profile={profile} />
    </div>
  );
};
const useProfilesFromGuids = (
  guids: { TravelerGuid: string }[],
  profile: IProfile
) => {
  const [profiles, setProfiles] = useState<IProfile[]>([]);

  const fetchApproversInfo = async () => {
    const promises: Promise<IProfile | undefined>[] = [];
    guids.forEach((manager) => {
      const url = `/profile/${manager.TravelerGuid}`;
      promises.push(
        apiService<IProfile>({
          key: [QueryKey.Profile, manager.TravelerGuid],
          method: 'get',
          url,
        })
      );
    });
    const responses = await Promise.all(promises);
    const values = responses.map((v) => formatProfile(v));
    setProfiles(values);
  };
  useEffect(() => {
    if (profile) {
      fetchApproversInfo();
    }
  }, [profile]);

  return { values: profiles };
};
const ApproversBlock: FC<{ profile: IProfile }> = ({ profile }) => {
  const { values } = useProfilesFromGuids(profile.Manager, profile);
  if (!values.length) return null;

  return (
    <div data-e2e-section="travelApprovers">
      <Typography
        variant="bodySmall"
        color="charcoalGray"
        className="text-left"
      >
        Travel Manager/Approver
      </Typography>
      {values.map((approver, idx) => {
        return (
          <TravellerInfoBlock
            value={approver}
            key={idx}
            profile={profile}
            data-e2e-bar="arrangerName-value"
          />
        );
      })}
    </div>
  );
};
const ArrangersBlock: FC<{ profile: IProfile }> = ({ profile }) => {
  const { values } = useProfilesFromGuids(profile.travelArrangers, profile);
  if (!values.length) return null;

  return (
    <div data-e2e-section="travelArrangers" className="mt-5">
      <Typography
        variant="bodySmall"
        color="charcoalGray"
        className="text-left"
      >
        Travel Arranger (s)
      </Typography>
      {values.map((approver, idx) => {
        return (
          <TravellerInfoBlock
            value={approver}
            key={idx}
            profile={profile}
            data-e2e-bar="approverName-value"
          />
        );
      })}
    </div>
  );
};

const AdditionalInfoList: FC<{
  items: IAdditionalItem[];
  type: 'email' | 'phone';
}> = ({ items, type }) => {
  return (
    <div className={styles.addInfoList}>
      {items.map((item, idx: number) => {
        const value =
          type === 'email' ? (
            <a
              className={classNames(styles.addInfoValue, styles.emailLink)}
              data-e2e-bar={`additional-${type}-${idx}`}
              href={`mailto:${item.value}`}
            >
              {item.value}
            </a>
          ) : (
            <div
              className={styles.addInfoValue}
              data-e2e-bar={`additional-${type}-${idx}`}
            >
              {item.value}
            </div>
          );
        return (
          <div className={styles.addInfoItem} key={idx}>
            <div className={styles.addInfoLabel}>{item.label}</div>
            {value}
          </div>
        );
      })}
    </div>
  );
};

const ContactList: FC<{
  label: string;
  mainValue: string;
  additionalValues: {
    value: string;
    label: string;
  }[];
  type: 'phone' | 'email';
}> = ({ additionalValues, label, mainValue, type }) => {
  const [open, setOpen] = useState(false);
  const ValueWrapper =
    type === 'phone'
      ? ({ children }: { children: string }) => (
          <Typography variant="bodyMedium2">{children}</Typography>
        )
      : ({ children }: { children: string }) => {
          if (children === 'N/A') {
            return <Typography variant="bodyMedium2">N/A</Typography>;
          }
          return (
            <a
              data-e2e-bar="email-value"
              className={classNames(styles.emailLink, 'text-sm font-medium')}
              href={`mailto:${children}`}
              onBlur={(e) => {
                e.stopPropagation();
              }}
            >
              {children}
            </a>
          );
        };

  return (
    <div>
      <div className="textWrap">
        <Typography variant="bodySmall" color="charcoalGray">
          {label}
        </Typography>
        <ValueWrapper>{mainValue}</ValueWrapper>
      </div>
      {open && (
        <div className="mt-1 flex flex-col gap-1">
          {additionalValues.map((v) => (
            <div key={v.value} className="textWrap">
              <Typography variant="bodySmall" color="charcoalGray">
                {v.label}
              </Typography>
              <ValueWrapper>{v.value}</ValueWrapper>
            </div>
          ))}
        </div>
      )}
      {!!additionalValues.length && !open && (
        <Button
          variant="text"
          onClick={() => setOpen(!open)}
          className="mt-1 flex gap-1"
          customPadding
        >
          <ChevronDownSvg />
          <Typography variant="bodySmall" className="underline">
            Show {additionalValues.length} more
          </Typography>
        </Button>
      )}
    </div>
  );
};
const TravellerInfoBlock: FC<
  { value: IProfile; profile: IProfile } & Omit<
    HTMLProps<HTMLDivElement>,
    'value'
  >
> = ({ profile, value, ...props }) => {
  const navigate = useNavigate();
  const nameToDisplay = getFirstAndLast(value);
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const { isOpen, setIsOpen } = useControlledPopper();
  const [isFromArrangersOrApproversBlock, setIsFromArrangersOrApproversBlock] =
    useAtom(isFromArrangersOrApproversBlockAtom);
  const breadcrumbs = useAtomValue(breadcrumbsAtom).value;
  const dispatchBreadcrumbs = useSetAtom(breadcrumbsAtom);

  const handleOpenProfile = () => {
    setIsFromArrangersOrApproversBlock({
      profiles: [
        ...isFromArrangersOrApproversBlock.profiles,
        { prevCrumbs: breadcrumbs, profile },
      ],
    });
    dispatchBreadcrumbs({ type: 'CLEAR' });

    pushTravellersOrProfilePage({
      id: value.guid,
      navigate,
      requireCallerAuth,
    });
  };

  return (
    <ControlledPopper
      placement="right"
      trigger="hover"
      className="h-[324px] p-4"
      open={isOpen}
      setOpen={setIsOpen}
      customPadding
      controlledArrow
      content={
        <div className="relative flex h-full flex-col gap-1 overflow-auto">
          <Typography variant="bodyMedium2">Profile Details</Typography>
          <div className="grid w-[416px] grid-cols-2  justify-between gap-2">
            <div>
              <div className={styles.field}>Nickname</div>
              <div className={classNames(styles.value)}>
                <span data-e2e-bar="nickName-value" className="textWrap">
                  {value.nickName}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.field}>Position</div>
              <div className={styles.value}>
                <span data-e2e-bar="position-value" className="textWrap">
                  {value.position}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.field}>PCC / OID</div>
              <div className={styles.value}>
                <div className="flex gap-1">
                  <Typography variant="bodyMedium">
                    {profile.rawCompany.GDSName === 'None'
                      ? 'N/A'
                      : profile.rawCompany.GDSName}
                    :
                  </Typography>
                  <span data-e2e-bar="pcc-value" className="textWrap">
                    {value.pcc}
                  </span>
                </div>
                {!isNA(value.pcc) && (
                  <CopyButton className={styles.copy} value={value.pcc} />
                )}
              </div>
            </div>
            <div>
              <div className={styles.field}>GDS Profile Locator</div>
              <div className={styles.value}>
                <span data-e2e-bar="gdsLocator-value" className="textWrap">
                  {value.GdsLocator}
                </span>
                {!isNA(value.GdsLocator) && (
                  <CopyButton
                    className={styles.copy}
                    value={value.GdsLocator}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.field}>Company</div>
              <div className={styles.value}>
                <span data-e2e-bar="company-value" className="textWrap">
                  {value.company.trim() || 'N/A'}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.field}>Employee ID</div>
              <div className={styles.value}>
                <span data-e2e-bar="employeeId-value" className="textWrap">
                  {value.employeeId}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.field}>SAP Code</div>
              <div className={styles.value}>
                <span data-e2e-bar="sap-value" className="textWrap">
                  {value.sapCode}
                </span>
                {!isNA(value.sapCode) && (
                  <CopyButton className={styles.copy} value={value.sapCode} />
                )}
              </div>
            </div>
            <div />
            <ContactList
              label={value.phoneTypeName || 'Phone'}
              mainValue={value.phone}
              additionalValues={value.additionalPhones}
              type="phone"
            />
            <ContactList
              label="Email"
              mainValue={value.email}
              additionalValues={value.additionalEmails}
              type="email"
            />
          </div>
          <Button
            variant="text"
            className="mt-auto flex w-fit gap-1 pt-3"
            customPadding
            onClick={handleOpenProfile}
          >
            <UserSvg />
            Open Profile
          </Button>
        </div>
      }
      strategy="fixed"
    >
      <div
        className={classNames(styles.additionalInfoItem, 'cursor-pointer')}
        {...props}
        onClick={handleOpenProfile}
      >
        <span className={styles.approverName} data-e2e-bar="approverName-value">
          {nameToDisplay}
        </span>
        <InfoSvg
          className="shrink-0"
          color={isOpen ? palette['fcm-green'] : palette['grey-5']}
        />
      </div>
    </ControlledPopper>
  );
};
export default Bar;
