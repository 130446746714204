import AuthorizationError from 'components/pages/AuthorizationError/AuthorizationError';
import Company from 'components/pages/Company/Company';
import ComponentPage from 'components/pages/ComponentPage/ComponentPage';
import DetailedTrips from 'components/pages/DetailedTrips/DetailedTrips';
import EjectedSearch from 'components/pages/EjectedSearch/EjectedSearch';
import Feedback from 'components/pages/Feedback/Feedback';
import MultipleProfiles from 'components/pages/MultipleProfiles/MultipleProfiles';
import ProfilePage from 'components/pages/Profile/Profile';
import ProfileOrTravellers from 'components/pages/ProfileOrTravellers/ProfileOrTravellers';
import RestrictedAccess from 'components/pages/RestrictedAccess/RestrictedAccess';
import Search from 'components/pages/Search/Search';
import SignedOut from 'components/pages/SignedOut/SignedOut';
import Menu from 'components/pages/Starting/components/Menu/Menu';
import Travellers from 'components/pages/Travellers/Travellers';

export const oldRouteMap = {
  '/': {
    component: <Search />,
    exact: true,
    name: 'Search',
  },
  '/authorization-error': {
    component: <AuthorizationError />,
    exact: true,
    name: 'Authorization Error',
  },
  '/company/:id': {
    component: (
      <>
        <Menu />
        <Company />
      </>
    ),
    exact: false,
    name: 'Company Profile',
  },
  '/componentPage': {
    component: <ComponentPage />,
    exact: false,
    name: 'Component Page',
  },
  '/detailed-trips/:id': {
    component: (
      <>
        <Menu />
        <DetailedTrips />
      </>
    ),
    exact: false,
    name: 'Detailed Trips',
  },
  '/feedback': {
    component: <Feedback />,
    exact: false,
    name: 'Feedback',
  },

  '/multiple-profiles': {
    component: <MultipleProfiles />,
    exact: false,
    name: 'Multiple Profiles',
  },

  '/profile': {
    component: <ProfilePage />,
    exact: false,
    name: 'Profile',
  },
  '/profileOrTravellers': {
    component: <ProfileOrTravellers />,
    exact: false,
    name: 'Profile',
  },

  '/restricted-access': {
    component: <RestrictedAccess />,
    exact: true,
    name: 'Profile',
  },
  '/searchComponent': {
    component: <EjectedSearch />,
    exact: false,
    name: '',
  },
  '/signed-out': {
    component: <SignedOut />,
    exact: true,
    name: 'Signed Out',
  },
  '/travellers': {
    component: (
      <>
        <Menu />
        <Travellers />
      </>
    ),
    exact: false,
    name: 'Travellers',
  },
  // '/pickPnr': {
  //   component: <PickPnr />,
  //   exact: false,
  //   name: '',
  // },
  // '/pickTrip': {
  //   component: <PickTrip />,
  //   exact: false,
  //   name: '',
  // },
  // '/linkedPnrs': {
  //   component: <LinkedPnrs />,
  //   exact: false,
  //   name: '',
  // },
};
export const BREAKPOINTS = {
  LG: 1120,
  MD: 820,
  SM: 640,
  XL: 1280,
  XXL: 1536,
};
export const PAGES_WITHOUT_HEADER_AND_WRAPPER = [
  'searchComponent',
  // 'linkedPnrs',
];

export const PAGES_WITHOUT_HEADER_ON_SMALL_SCREEN = [
  'authorization-error',
  'signed-out',
];
export const PAGES_WITHOUT_FEEDBACK = [
  'feedback',
  // 'pickPnr',
  // 'pickTrip',
  'authorization-error',
  'signed-out',
];

export const PAGES_WITHOUT_SHORTCUTS = [
  'search',
  // 'pickPnr',
  // 'pickTrip',
  'searchComponent',
  'authorization-error',
  'signed-out',
];
export const PAGES_WITHOUT_TOP_RIGHT_BLOCK = [
  // 'pickPnr',
  // 'pickTrip',
  'authorization-error',
  'signed-out',
];
export const PAGES_WITH_BACK_BUTTON = ['feedback', 'search'];
export const PAGES_WITH_BREADCRUMBS = [
  'multiple-profiles',
  'travellers',
  'profile',
  'profileOrTravellers',
  // 'pickPnr',
  // 'pickTrip',
  'detailed-trips',
  'company',
];
export const PAGES_TO_SHOW_ASSIGN = [
  'profile',
  'travellers',
  'detailed-trips',
  'company',
];

export const PAGES_WITH_PROFILE = [
  'detailed-trips',
  'travellers',
  'profile',
  'profileOrTravellers',
  'componentPage',
  'company',
];
export const PAGES_WITHOUT_TOP_GREEN_BAR = [
  'feedback',
  //  'pickPnr', 'pickTrip'
];
export const PAGES_WITH_SEARCH = ['search', 'searchComponent'];
export const PAGES_WITHOUT_AGENT_DATA = ['signed-out'];
export const PAGES_WITHOUT_IDLE_TIMER = ['signed-out'];
export const PAGES_WITHOUT_PHONE_VALIDATION = ['multiple-profiles'];

export const routeMap = Object.entries(oldRouteMap).map(([k, v]) => {
  return {
    element: v.component,
    path: k,
  };
});
