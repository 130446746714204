import { redirect } from 'react-router-dom';
import axios from 'axios';
import { env } from 'env';

import { getGenesysAccessToken, setGenesysAccessToken } from './token';
// Implicit grant credentials

const CLIENT_ID = env.REACT_APP_GENESYS_CLIENT_ID;

// Genesys Cloud environment

export const fetchConversationDetails = (conversationId: any) => {
  return axios.get(
    `https://api.mypurecloud.com.au/api/v2/conversations/${conversationId}`,
    {
      headers: {
        Authorization: `bearer ${getGenesysAccessToken()}`,
      },
    }
  );
};

//      xhr.setRequestHeader('Authorization', 'bearer ' + token);
export async function authenticateGenesys() {
  if (!getGenesysAccessToken()) {
    if (location.hash?.includes('access_token')) {
      const searchParams = new URLSearchParams(location.hash.slice(1));
      const tokenFromHash = searchParams.get('access_token');

      setGenesysAccessToken(tokenFromHash ?? '');
      redirect(`/?conversationId=${searchParams.get('state')}`);
    } else {
      const queryStringData = new URLSearchParams({
        client_id: CLIENT_ID,
        redirect_uri: window.location.origin,
        response_type: 'token',
        state:
          new URLSearchParams(window.location.search.slice(1)).get(
            'conversationId'
          ) || '',
      });

      window.location.replace(
        `https://login.mypurecloud.com.au/oauth/authorize?${queryStringData}` // TODO: think about ways to refresh genesys token
      );
    }
  }
}
