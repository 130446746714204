import classNames from 'classnames';
import { FC, useState } from 'react';

import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography/Typography';
import { ReactComponent as DeleteSvg } from 'styles/icons/delete.svg';
import { ReactComponent as EditSvg } from 'styles/icons/edit.svg';
import palette from 'styles/palette';
import { INote, NotePriorityKeys } from 'types/notes';

import InternalNotePriority from './InternalNotePriority';

import EditedInfoPopover from 'components/common/EditedInfoPopover';
import OptionsPopover from 'components/common/OptionsPopover';
import { useOverflowTooltip } from 'hooks/general';
import { useIsEditingOrDeletingNote } from 'hooks/internalNotes';
import { IOptionsRenderProps } from './CompanyNoteItem';
import styles from './InternalNotes.module.scss';
import NoteInput from './NoteInput';
import NoteSpinnerOverlay from './NoteSpinnerOverlay';

enum Mode {
  Create = 'CREATE',
  Edit = 'EDIT',
  Nothing = 'NOTHING',
}

interface INoteItemProps extends INote {
  onDeleteNote: Function;
  id: INote['id'];
  onEditNote: (
    id: INote['id'],
    newPriority: NotePriorityKeys,
    newText: string
  ) => void;
}
const NoteItem: FC<INoteItemProps> = ({
  author,
  id,
  onDeleteNote,
  onEditNote,
  priority,
  text,
  time,
  updatedAt,
  updatedBy,
}) => {
  const [noteMode, setNoteMode] = useState<Mode>(Mode.Nothing);
  const isEditingOrDeletingNote = useIsEditingOrDeletingNote(id);
  return noteMode === Mode.Edit ? (
    <NoteInput
      mode={Mode.Edit}
      onSubmit={async ({
        newPriority,
        newText,
      }: {
        newText: string;
        newPriority: NotePriorityKeys;
      }) => {
        await onEditNote(id, newPriority, newText);
        setNoteMode(Mode.Nothing);
      }}
      onCancel={() => {
        setNoteMode(Mode.Nothing);
      }}
      key={noteMode}
      text={text}
      author={author}
      priority={priority}
      time={time}
      id={id}
      onDeleteNote={() => onDeleteNote(id)}
    />
  ) : (
    <div className={classNames(styles.noteContainer, 'relative')}>
      <NoteBody
        text={text}
        author={author}
        updatedAt={updatedAt}
        updatedBy={updatedBy}
        time={time}
        priority={priority}
        onOpenEditMode={() => {
          setNoteMode(Mode.Edit);
        }}
        onDeleteNote={onDeleteNote}
        id={id}
      />
      {isEditingOrDeletingNote && <NoteSpinnerOverlay />}
    </div>
  );
};

interface NoteBodyProps {
  text: string;
  id: INote['id'];
  author: string;
  time: string;
  updatedBy: string;
  updatedAt: string;
  priority: NotePriorityKeys;
  onOpenEditMode: Function;
  onDeleteNote: Function;
}

const NoteBody: FC<NoteBodyProps> = ({
  author,
  id,
  onDeleteNote,
  onOpenEditMode,
  priority,
  text,
  time,
  updatedAt,
  updatedBy,
}) => {
  const { withOverflowTooltip: authorElement } = useOverflowTooltip(
    author,
    <div
      className={classNames(
        styles.author,
        updatedBy ? 'max-w-[79%]' : 'max-w-[95%]'
      )}
    />
  );

  return (
    <div className={classNames(styles.note)}>
      <div className="flex justify-between">
        <InternalNotePriority priority={priority} withLabelPriority />
        <div className={styles.time}>{time}</div>
      </div>
      <p className={styles.text}>{text}</p>
      <div className={styles.subRow}>
        {authorElement}
        <div
          className={classNames(
            styles.actionsContainer,
            updatedBy && styles.withUpdated
          )}
        >
          <div className="flex gap-2">
            {updatedBy && (
              <EditedInfoPopover updatedAt={updatedAt} updatedBy={updatedBy} />
            )}
            <OptionsPopover className="flex w-5 items-center justify-center">
              {({ close }: IOptionsRenderProps) => (
                <div className={styles.popover}>
                  <Button
                    variant="text"
                    className={styles.popoverItem}
                    onClick={() => {
                      onOpenEditMode();
                      close();
                    }}
                  >
                    <EditSvg color={palette['grey-6']} />
                    <Typography variant="bodyMedium" color="charcoalGray">
                      Edit Note
                    </Typography>
                  </Button>
                  <Button
                    variant="text"
                    className={styles.popoverItem}
                    onClick={() => {
                      onDeleteNote(id);
                      close();
                    }}
                  >
                    <DeleteSvg color={palette['grey-6']} />
                    <Typography variant="bodyMedium" color="charcoalGray">
                      Delete Note
                    </Typography>
                  </Button>
                </div>
              )}
            </OptionsPopover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
