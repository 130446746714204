import classNames from 'classnames';
import { Ref, forwardRef, useEffect, useState } from 'react';

import Typography from 'components/common/Typography/Typography';
import { ReactComponent as InfoOctagonSvg } from 'styles/icons/info-octagon.svg';

export type useInputArgs = {
  placeholder?: string;
  initialValue?: string;
  errorRules?: ((v: string) => boolean | string)[];
};

export const useInput = ({
  errorRules = [],
  initialValue,
  placeholder,
}: useInputArgs) => {
  const [value, setValue] = useState('');

  return {
    errors: errorRules.map((isValid) => isValid(value)).filter((v) => !!v),
    initialValue,
    placeholder,
    setValue,
    value,
  };
};
const Input = (
  {
    className,
    errors,
    initialValue,
    placeholder,
    setValue,
    value,
  }: {
    className?: string;
  } & ReturnType<typeof useInput>,
  ref: Ref<HTMLInputElement>
) => {
  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <input
        ref={ref}
        className={classNames(
          className,
          'gap-2 border border-solid border-grey-5 p-2 focus:border-deep-green',
          errors.length &&
            'border border-solid border-ui-red-darken outline-1 outline-ui-red-darken'
        )}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          const text = e.target.value;
          setValue(text);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      {!!errors.length && (
        <div className="mt-2 flex flex-col gap-2">
          {errors.map((errorString, i) => (
            <div className="flex items-center gap-[5px]" key={i}>
              <InfoOctagonSvg />
              <Typography variant="bodySmall" color="uiRedDarken">
                {errorString}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default forwardRef(Input);
