import { atom } from 'jotai';

import { atomWithReducer } from 'jotai/utils';
import { IProfile } from 'types/profile';

import { AxiosError } from 'axios';
import { Location } from 'react-router-dom';
import { IBreadcrumb } from 'types/breadcrumbs';
import { ISearchAPI } from 'types/search';
import { formatProfile } from 'utils/profile';

const baseAsyncReducer = <
  Response extends unknown = unknown,
  Error extends unknown = unknown
>(
  initialValue: any // TODO: fix this later
  //   {
  //   loading: boolean;
  //   value: Response | undefined;
  //   error: undefined;
  // }
) => {
  return (
    prev: { loading: boolean; value: Response; error: Error | undefined },
    action:
      | { type: 'LOADING' }
      | { type: 'FULFILLED'; value: Response }
      | { type: 'ERROR'; error: Error }
      | { type: 'RESET' }
  ) => {
    switch (action.type) {
      case 'LOADING':
        return { ...prev, error: undefined, loading: true };
      case 'FULFILLED':
        return {
          ...prev,
          error: undefined,
          loading: false,
          value: action.value,
        };
      case 'ERROR':
        return { ...prev, error: action.error, loading: false };
      case 'RESET':
        return initialValue;
    }
  };
};
const baseInitialState = { error: undefined, loading: false, value: undefined };
const authInitialState = { ...baseInitialState };
const authReducer = baseAsyncReducer(authInitialState);
export const authAtom = atomWithReducer(authInitialState, authReducer);
authAtom.debugLabel = 'authAtom';

export const profileInitialState = {
  error: undefined,
  loading: false,
  value: formatProfile({}), // to get an empty profile
};
const profileReducer = baseAsyncReducer<IProfile>(profileInitialState);
export const profileAtom = atomWithReducer(profileInitialState, profileReducer); // shouldn't be an atom;
profileAtom.debugLabel = 'profileAtom';

const collectionReducer = <Response extends { id: string }>() => {
  return (
    prev: { value: Response[] },
    action:
      | { type: 'CLEAR' }
      | { type: 'SET'; value: Response[] }
      | { type: 'ADD'; value: Response }
      | { type: 'POP' }
  ) => {
    switch (action.type) {
      case 'CLEAR':
        return { ...prev, value: [] };
      case 'SET':
        return { ...prev, value: action.value };
      case 'ADD':
        if (!prev.value?.length) {
          return { ...prev, value: [action.value] };
        }
        if (prev.value.find((v) => v.id === action.value.id)) {
          return prev;
        }
        return { ...prev, value: [...prev.value, action.value] };
      case 'POP': {
        if (!prev.value) {
          throw new Error('ERROR WHEN TRYING TO POP EMPTY BREADCRUMBS');
        }
        const newValue = [...prev.value];
        newValue?.pop();
        return { ...prev, value: newValue };
      }
    }
  };
};

export const breadcrumbsAtom = atomWithReducer(
  { value: [] },
  collectionReducer<IBreadcrumb>()
);
breadcrumbsAtom.debugLabel = 'breadcrumbsAtom';

const searchInitialState = {
  error: undefined,
  loading: false,
  value: undefined,
};

export const searchAtom = atomWithReducer(
  searchInitialState,
  baseAsyncReducer<ISearchAPI | undefined, AxiosError>(searchInitialState)
); // shouldn't be an atom;
searchAtom.debugLabel = 'searchAtom';
export const requireCallerAuthAtom = atom(false);
requireCallerAuthAtom.debugLabel = 'requireCallerAuthAtom';
export const isComponentPageAtom = atom(false);
isComponentPageAtom.debugLabel = 'isComponentPageAtom';

export const isAuthedAtom = atom(false);
isAuthedAtom.debugLabel = 'isAuthedAtom';

const baseReducer =
  <T>() =>
  (_: T | undefined, action: { type: 'SET'; value: T } | { type: 'CLEAR' }) => {
    if (action.type === 'SET') {
      return action.value;
    }
    if (action.type === 'CLEAR') {
      return undefined;
    }
  };

export const profileSourceAtom = atomWithReducer(
  undefined,
  baseReducer<string>()
);
profileSourceAtom.debugLabel = 'profileSourceAtom';

export const trackingSourceAtom = atomWithReducer(
  undefined,
  baseReducer<string>()
);
trackingSourceAtom.debugLabel = 'trackingSourceAtom';

export const phoneAssignmentAtom = atomWithReducer(
  undefined,
  baseReducer<string>()
);
phoneAssignmentAtom.debugLabel = 'phoneAssignmentAtom';
export const prevRouteAtom = atom<string | undefined>(undefined);
prevRouteAtom.debugLabel = 'prevRouteAtom';

export const prevLocationsAtom = atom<Location[]>([]);
prevLocationsAtom.debugLabel = 'prevLocationsAtom';

export const emptyIsArrangersOrApprovers = { profiles: [] };
export const isFromArrangersOrApproversBlockAtom = atom<{
  profiles: { profile: IProfile; prevCrumbs: IBreadcrumb[] }[];
}>(emptyIsArrangersOrApprovers);
isFromArrangersOrApproversBlockAtom.debugLabel =
  'isFromArrangersOrApproversBlockAtom';
