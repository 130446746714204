import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { FC, useEffect } from 'react';

import Menu from 'components/pages/Starting/components/Menu/Menu';
import { useProfile } from 'hooks/profile';
import { QueryKey } from 'utils/apiService';

import Bar from './components/Bar/Bar';
import InternalNotes from './components/InternalNotes/InternalNotes';
import Preferences from './components/Preferences/Preferences';
import RequireCallerAuth from './components/RequireCallerAuth';
import Trips from './components/Trips/Trips';

import Loader from 'components/common/Loader';
import Onboarding from 'components/common/Onboarding';
import { queryClient } from 'index';

import { requireCallerAuthAtom } from 'state/store';
import styles from './Profile.module.scss';

const InnerProfile: FC = () => {
  return (
    <div className={classNames(styles.container, 'pb-10')}>
      <Bar />
      <Trips className="min-w-[420px]" />
      <div className={styles.preferencesContainer}>
        <Preferences />
        <InternalNotes />
      </div>
    </div>
  );
};

const ProfilePage = () => {
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const { loading: profileLoading, value: profile } = useProfile()[0];
  useEffect(() => {
    queryClient.removeQueries({ queryKey: [QueryKey.MultipleProfiles] });
  }, []);

  if (profile.guid === 'N/A' || profileLoading) {
    return <Loader e2e="profile_spinner" />;
  }
  if (requireCallerAuth) return <RequireCallerAuth />;
  return (
    <>
      <Menu />
      <InnerProfile />
      <Onboarding />
    </>
  );
};

export default ProfilePage;
