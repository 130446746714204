import {
  always,
  compose,
  converge,
  curry,
  equals,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  last,
  or,
  path,
} from 'ramda';
import { loginWithPkce, removeLocalData } from './token';

// Takes first existing value from the list of paths

export const isNilOrEmpty: any = converge(
  (...args) => or(head(args), last(args)),
  [isEmpty, isNil]
);

export const pickFullString = curry((_default: string, _path: string[]) =>
  compose(ifElse(isNilOrEmpty, always(_default), identity), path(_path))
);

export const isNA = equals('N/A');

export const resizeTextArea = (textArea: HTMLElement) => {
  textArea.style.height = 'auto';
  textArea.style.height = textArea.scrollHeight + 'px';
};

export const getFirstAndLast = <
  T extends { firstName: string; lastName: string }
>(
  value: T
) => {
  return (
    [value.firstName.trim(), value.lastName.trim()].filter(Boolean).join(' ') ||
    'N/A'
  );
};

export const getTravellersOrProfilePage = (id: string) => {
  return `/profileOrTravellers?travelerGuid=${id}`;
};

export const pushTravellersOrProfilePage = ({
  id,
  navigate,
  requireCallerAuth,
}: {
  navigate: any;
  id: string;
  requireCallerAuth: boolean;
}) => {
  if (requireCallerAuth) {
    return navigate(`/profile?travelerGuid=${id}`);
  }
  return navigate(getTravellersOrProfilePage(id));
};

export const relogin = (url?: string) => {
  removeLocalData();
  loginWithPkce(url);
};

export const getSectionFromUrl = (url: string) => {
  const routes = [
    ['travellers', 'travellers'],
    ['feedback', 'feedback'],
    ['authorization-error', 'authorization-error'],
    ['signed-out', 'signed-out'],
    ['restricted-access', 'restricted-access'],
    ['multiple-profiles', 'multiple-profiles'],
    ['profile', 'profile'],
    ['profileOrTravellers', 'profileOrTravellers'],
    ['detailed-trips', 'detailed-trips'],
    ['company', 'company'],
    ['componentPage', 'componentPage'],
    ['searchComponent', 'searchComponent'],
    // ['pickPnr', 'pickPnr'],
    // ['pickTrip', 'pickTrip'],
    // ['linkedPnrs', 'linkedPnrs'],
  ];
  return routes.find((v) => url.includes(v[0]))?.[1] || 'search';
};
export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
interface AnyObject {
  [key: string]: any;
}
export function isEqual(obj1: AnyObject, obj2: AnyObject): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!isEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}
export function arraysAreEqual<T extends AnyObject>(
  arr1: T[],
  arr2: T[]
): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!isEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}
