export const ROOM_TYPES = {
  DBLB: 'Double',
  KING: 'King',
  'N/A': 'N/A',
  QUEEN: 'Queen',
} as any;

export const AIR_PREFERENCES: any = {
  Aisle: {
    A: 'Aisle',
    C: 'Center',
    W: 'Window',
  },
  Bulkhead: {
    B: 'Bulkhead',
    E: 'Exit',
  },
  Forward: {
    F: 'Forward',
    T: 'Tail',
  },
  Left: {
    L: 'Left',
    R: 'Right',
  },
  Meal: {
    AVML: 'Asian Vegetarian',
    BLML: 'Bland',
    DBML: 'Diabetic',
    FPML: 'Fruit Platter',
    GFML: 'Gluten Free',
    HFML: 'High Fiber',
    HNML: 'Hindu',
    IVML: 'Indian Vegetarian',
    KSML: 'Kosher',
    LCML: 'Low Calorie',
    LCRB: 'Low Carbohydrate',
    LFML: 'Low Cholesterol/Fat',
    LPML: 'Low Protein',
    LSML: 'Low Sodium',
    MOML: 'Muslim',
    NFML: 'No Fish',
    NLML: 'Non-lactose',
    NONE: 'None',
    NSML: 'No Salt',
    ORML: 'Oriental',
    PFML: 'Peanut-free',
    RVML: 'Vegetarian (Raw)',
    SFML: 'Seafood',
    VGML: 'Vegetarian (Vegan)',
    VLML: 'Vegetarian (Lacto-Ovo)',
  },
};

export const CAR_PREFERENCES: any = {
  Equipment: {
    ADL: 'Automatic Door Locks',
    BBS: 'Baby Stroller',
    BST: 'Child Seat/Booster',
    BYC: 'Bicycle Rack',
    CBR: 'Citizens Band Radio',
    CCL: 'Cruise Control',
    CDP: 'CD Player',
    CSI: 'Child Seat/Infant',
    CSR: 'Satellite Radio',
    CST: 'Child Sat/Toddler',
    DVD: 'DVD Player (Rear Seat)',
    FMR: 'FM Radio',
    HCH: 'Hatchback',
    HCL: 'Hand Control/Left',
    HCR: 'Hand Control/Right',
    LUG: 'Luggage Rack',
    NAV: 'Navigational System',
    NSK: 'No Smoking',
    PHN: 'Mobile Phone',
    SBE: 'Seat Belt Extension',
    SIX: 'Six Passenger Car',
    SKI: 'Ski Rack Only',
    SKR: 'Ski Rack or Box',
    SKV: 'Ski Equipped Vehicle',
    SNO: 'Snow Chains',
    STR: 'Snow/Winter Tires',
    TAP: 'Cassette Tape Player',
    TRH: 'Trailer Hitch',
    TVI: 'Television',
    VCR: 'VCR',
  },
  Size: {
    CCAR: 'Compact',
    ECAR: 'Economy',
    FCAR: 'Full-size',
    ICAR: 'Intermediate',
    LCAR: 'Luxury',
    MVAR: 'Minivan',
    PCAR: 'Premium',
    SCAR: 'Standard',
  },
};

export const TRAIN_PREFERENCES: any = {
  BerthCompartment: {
    1: false,
    2: 'Female',
    3: 'Male',
    4: 'Mixed',
    5: 'Solo',
  },
  BerthLevel: {
    1: false,
    2: 'Inferior (Lower)',
    3: 'Median (Middle)',
    4: 'Superior (Upper)',
  },
  CarType: {
    1: 'Compartment Coach',
    2: false,
    3: 'Saloon Coach',
    4: 'Table',
  },
  Class: {
    1: 'First',
    2: false,
    3: 'Premium',
    4: 'Second',
  },
  SeatOption: {
    1: 'Pets Allowed',
    2: 'Table',
    3: 'Wheelchair',
    4: false,
  },
  SeatPosition: {
    1: false,
    2: 'Facing Forward',
  },
  SeatType: {
    1: false,
    2: 'Business',
    3: 'Compartment',
    4: 'Open Coach',
    5: 'Family',
    6: 'Quiet',
  },
  WagonCode: {
    1: 'Aisle',
    2: false,
    3: 'Window',
    4: 'Window Isolated Seat',
  },
  ZoneCode: {
    1: 'Mobile Phone Zone',
    2: false,
    3: 'Quiet Zone',
  },
};

export type TRoomTypes = typeof ROOM_TYPES;
export type TAirPreferences = typeof AIR_PREFERENCES;
export type TCarPreferences = typeof CAR_PREFERENCES;
export type TTrainPreference = typeof TRAIN_PREFERENCES;
