import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import Button from 'components/common/Button/Button';
import {
  ControlledPopper,
  useControlledPopper,
} from 'components/common/Popper/Popper';
import { ReactComponent as DotsVerticalSvg } from 'styles/icons/dots_vertical.svg';
import palette from 'styles/palette';
import { NotePriorityKeys } from 'types/notes';

import styles from './InternalNotes.module.scss';

export const popoverShadow = 'shadow-[0px_4px_12px_rgba(99,111,122,0.16)]';
export const popoverClassName = `w-fit ${popoverShadow}`;

export interface IOptionsRenderProps {
  close: () => void;
}
const OptionsPopover: FC<{
  className?: string;
  children: (props: IOptionsRenderProps) => ReactNode;
}> = ({ children, className }) => {
  const { isOpen: optionsIsOpen, setIsOpen: setOptionsIsOpen } =
    useControlledPopper();
  return (
    <ControlledPopper
      trigger="click"
      customPadding
      className={popoverClassName}
      content={children({ close: () => setOptionsIsOpen(false) })}
      open={optionsIsOpen}
      setOpen={setOptionsIsOpen}
      placement="bottom"
      offsetOptions={{ crossAxis: -55, mainAxis: -30 }}
      noArrow
    >
      <Button
        variant="text"
        className={classNames(className)}
        customPadding
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DotsVerticalSvg color={palette['grey-5']} />
      </Button>
    </ControlledPopper>
  );
};
export default OptionsPopover;
