/* eslint no-use-before-define:0 */

import {
  TCarCategory,
  TCarTypes,
  TFuel,
  TTransmission,
} from 'constants/tripsCar';
import { RouteType, mapTrips } from 'utils/trips';

interface FlightSegmentBaggageAllowance {
  IsIncluded: boolean | null;
  MaximumWeight: number | null;
  Pieces: number;
  WeightUnit: string | null;
}

export type AlertLevel = 'CRITICAL' | 'WARNING' | 'INFO';

export interface Alert {
  Category: string;
  StartAt: string;
  EndAt: string;
  Description: string;
  Title: string;
  Level: AlertLevel;
  Id: string;
}

interface CovidInfo {
  ExtraInfoUrl: string;
}

interface CarRentalCompany {
  Name: string;
}

interface CarRentalSegmentMileageAllowance {
  MaxDistance?: number;
  MaxDistanceUnit: string;
  Unlimited: boolean;
}

interface CarRentalSegmentCarSpecification {
  CarType: keyof TCarTypes;
  Category: keyof TCarCategory;
  Fuel: keyof TFuel;
  Transmission: keyof TTransmission;
}

interface CarRentalSegmentRate {
  Base: string;
  Total: string;
  Currency: string;
}

interface Location {
  Address: string;
  CityName: string;
  CountryCode: string;
}

interface HotelSegmentRate {
  Base: string;
  Total: string;
  Currency: string;
  BaseType: string;
}

interface HotelAward {
  Provider: string;
  Rating: string;
  RatingMeta: string;
}

interface HotelChain {
  Name: string;
}

interface TrainOperator {
  Name: string;
}

interface Station {
  Name: string;
  CityName: string;
  CountryCode: string;
}

interface MainCity {
  Name: string;
}

interface ArrivalAirportOrDepartureAirport {
  Iata: string;
  MainCity?: MainCity;
}

interface Flight {
  AirLine: string;
  AirLineLogo?: string;
  Operator: string | null;
  Number: number;
  ArrivalAirport: ArrivalAirportOrDepartureAirport;
  ArrivalGate: string | null;
  ArrivalTerminal?: string;
  DepartureAirport: ArrivalAirportOrDepartureAirport;
  DepartureGate: string | null;
  DepartureTerminal: string | null;
  Duration: number;
  Equipment: { Name?: string };
  EstimatedLocalArrivalAt: string | null;
  EstimatedLocalDepartureAt: string | null;
}

interface IShortLocation {
  short: string;
  cityName: string;
  showTooltip: boolean;
  type?: string;
}

export interface ITripLocations {
  type: RouteType;
  locations: IShortLocation[];
}

export interface Match {
  Alert: Alert;
}

interface RequestedMeal {
  Description: string;
}
interface IFlightStop {
  CityName: string;
  Duration: string;
}
interface FlightSummary {
  LocalArrivalAt: string;
  LocalDepartureAt: string;
  ArrivalAirport: ArrivalAirportOrDepartureAirport;
  DepartureAirport: ArrivalAirportOrDepartureAirport;
  Duration: string | null;
  Stops: IFlightStop[];
  CovidInfo: CovidInfo;
}

export interface IFlightBookingNotPartial {
  Id: string;
  NativeRecordLocator: string;
  Seat: string | null;
  LocalArrivalAt: string;
  LocalDepartureAt: string;
  Flight: Flight;
  ClassOfService: string;
  PnrRecordLocator: string;
  RequestedMeals: RequestedMeal[];
  BaggageAllowance: FlightSegmentBaggageAllowance;
  Layovers: ILayover[];
  AffectingMatches: Match[];
  CovidInfo: CovidInfo;
  TicketNumber: string | null;
  IsPassive: boolean;
  IsOpen: boolean;
  StatusCode: string;
  FlightSummary: FlightSummary;
  UtcDepartureAt: string | null;
}

export interface ILayover {
  Duration: number;
  FlightBooking: IFlightBooking;
}

export type IFlightBooking = Partial<IFlightBookingNotPartial>;

export interface ICarRentalBookingNotPartial {
  LocalDropoffAt: string;
  LocalPickupAt: string;
  NumberOfDays: number;
  PickupLocation: Location;
  DropoffLocation: Location;
  NativeRecordLocator: string;
  IsPassive: boolean;
  ConfirmationNumber: string;
  Company: CarRentalCompany;
  MileageAllowance: CarRentalSegmentMileageAllowance;
  CarSpecification: CarRentalSegmentCarSpecification;
  Rate: CarRentalSegmentRate;
  PnrRecordLocator: string;
}

export type ICarRentalBooking = Partial<ICarRentalBookingNotPartial>;

export interface IHotel {
  Name: string;
  Awards: HotelAward[];
  CheckIn: string;
  CheckOut: string;
  Chain: HotelChain;
  CityName: string;
}

export interface IHotelBookingNotPartial {
  CheckinOn: string;
  CheckoutOn: string;
  ConfirmationNumber: string;
  NumberOfNights: number;
  Hotel: IHotel;
  IsPassive: boolean;
  Rate: HotelSegmentRate;
  PnrRecordLocator: string;
}

export type IHotelBooking = Partial<IHotelBookingNotPartial>;

export interface ITrainBookingNotPartial {
  LocalArrivalAt: string;
  LocalDepartureAt: string;
  Duration: number;
  NativeRecordLocator: string;
  IsPassive: boolean;
  TicketNumber: string | null;
  Number: string;
  Coach: string;
  Seat: string | null;
  Operator: TrainOperator;
  DepartureStation: Station;
  ArrivalStation: Station;
  PnrRecordLocator: string;
}

export type ITrainBooking = Partial<ITrainBookingNotPartial>;

export type TBookings = (
  | IFlightBooking
  | ICarRentalBooking
  | IHotelBooking
  | ITrainBooking
)[];
export enum BookingType {
  Flight,
  Train,
  Car,
  Hotel,
}
export interface TripTraveller {
  TravelerGuid: string;
  Bookings?: TBookings;
}
export interface TripLocation {
  From?: string;
  FromFull?: string;
  To?: string;
  ToFull?: string;
  Booking: BookingType;
}
export interface IAPITripNotPartial {
  Id: string;
  EndOn: string;
  StartOn: string;
  Locations: TripLocation[];
  From: string;
  To: string;
  PnrRecordLocator: string;
  Travellers: TripTraveller[];
  RouteType: RouteType;
  TravelerGuid: string;
  TripName: string[];
  UserTripName: string | null;
}

export type IAPITrip = Partial<IAPITripNotPartial>;

export interface ITripNotPartial extends IAPITrip {
  id: string;
  date: string;
  startDate: Date;
  endDate: Date;
  tripLocations?: ITripLocations;
  tripName: string;
  pnrs: string[];
  sherpa: string;
  isCanceled: boolean;
  isFuture: boolean;
}

export type ITrip = ReturnType<typeof mapTrips>[number];

export type MappedAlert = Alert & {
  flightName: string;
  bookingId: number;
};
