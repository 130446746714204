import { PropsWithChildren, useState } from 'react';

import { ReactPortal } from './ReactPortal';
import classNames from 'classnames';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  className?: string;
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  return { isOpen, setIsOpen };
};
export default function Modal({
  children,
  className,
  isOpen,
  setIsOpen,
}: PropsWithChildren<ModalProps>) {
  if (!isOpen) return null;
  return (
    <ReactPortal>
      <div
        className={'fixed left-0 top-0 z-40 h-full w-full bg-black-a30'}
        onClick={() => setIsOpen(false)}
      />
      <div
        className={classNames(
          className,
          'fixed left-1/2 top-1/2 z-50 w-[480px] translate-x-[-50%] translate-y-[-50%] bg-clarity-white p-4'
        )}
      >
        {children}
      </div>
    </ReactPortal>
  );
}
