import classNames from 'classnames';
import { FC } from 'react';

interface BarProps {
  step: number;
  total: number;
}
const Bar: FC<BarProps> = ({ step, total }) => {
  return (
    <div className="h-1 rounded-full bg-grey-2">
      <div
        style={{ width: (step / total) * 100 + '%' }}
        className={classNames(
          'h-full rounded-full bg-deep-green transition-all'
        )}
      />
    </div>
  );
};
export default Bar;
