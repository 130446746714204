import classNames from 'classnames';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import Chevron from 'components/common/Icons/Chevron';
import { useOnClickOutside } from 'hooks/general';

import styles from './Select.module.scss';

type Value = any;

export interface ISelectOption<T = Value> {
  label: string;
  value: T;
  render?: (
    value: Omit<ISelectOption, 'render'> & {
      onClick: (value: ISelectOption) => void;
    }
  ) => ReactNode;
}

interface ISelectProps<T = Value> {
  defaultText: string;
  optionsList: ISelectOption[];
  onSelect: (value: T) => void;
  listClassName?: string;
  wrapperClassName?: string;
}

const Select: FC<ISelectProps> = ({
  defaultText,
  listClassName,
  onSelect,
  optionsList,
  wrapperClassName,
}) => {
  const [selectText, setSelectText] = useState('');
  const [showOptionList, setShowOptionList] = useState(false);
  const selectRef = useRef(null);

  useOnClickOutside(selectRef, () => setShowOptionList(false));
  useEffect(() => {
    setSelectText(defaultText);
  }, []);

  const handleListDisplay = () => {
    setShowOptionList((prevState) => !prevState);
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = ({ label, value }: ISelectOption) => {
    setSelectText(label);
    setShowOptionList(false);
    onSelect(value);
  };

  return (
    <div
      className={classNames(styles.customSelectContainer, wrapperClassName)}
      ref={selectRef}
    >
      <div
        className={classNames(styles.selectedText, {
          [styles.active]: showOptionList,
          [styles.initialText]: selectText === defaultText,
        })}
        onClick={handleListDisplay}
      >
        <Chevron
          className={classNames(
            styles.chevron,
            showOptionList && styles.activeChevron
          )}
          transform={showOptionList ? 'rotate(-90)' : 'rotate(90)'}
        />
        <span>{selectText}</span>
      </div>
      {showOptionList && (
        <ul className={classNames(styles.selectOptions, listClassName)}>
          {optionsList.map(({ label, render, value }) => {
            if (render)
              return render({
                label,
                onClick: handleOptionClick,
                value,
              });
            return (
              <li
                data-e2e-option={label}
                className={styles.selectOption}
                key={value}
                onClick={() =>
                  handleOptionClick({
                    label,
                    value,
                  })
                }
              >
                {label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Select;
