import classNames from 'classnames';
import { forwardRef, HTMLProps, MouseEvent, Ref } from 'react';

import styles from './Button.module.scss';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'text';
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  customPadding?: boolean;
}

const Button = (
  {
    children,
    className,
    customPadding = false,
    disabled,
    id,
    onClick,
    variant = 'primary',
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.padding]: !customPadding,
          [styles[variant]]: variant,
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      id={id}
    >
      {children}
    </button>
  );
};

export default forwardRef(Button);
