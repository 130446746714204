import classNames from 'classnames';
import { FC, useState } from 'react';
import { ReactComponent as AdjustmentsSvg } from 'styles/icons/adjustments.svg';
import { ReactComponent as FilterSvg } from 'styles/icons/filter.svg';
import { ReactComponent as RotateSvg } from 'styles/icons/rotate.svg';
import palette from 'styles/palette';
import { Company } from 'types/profile';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import FilterDialog from './FilterDialog';

export const useSearchCompanyFilters = () => {
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  return { selectedCompanies, setSelectedCompanies };
};

export type UseSearchCompanyFiltersResult = ReturnType<
  typeof useSearchCompanyFilters
>;
export interface SearchCompanyFiltersProps
  extends UseSearchCompanyFiltersResult {
  companies: Company[];
}

const SearchCompanyFilters: FC<SearchCompanyFiltersProps> = ({
  companies,
  selectedCompanies,
  setSelectedCompanies,
}) => {
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const filterEnabled = !!selectedCompanies.length;
  const filterText = filterEnabled ? (
    // TRUNCATE STEP 1: add "textWrap flex-1" on element that you want to truncate
    <Typography variant="bodySmall2" color="black" className="textWrap flex-1">
      {selectedCompanies
        .map((v) => `${v.Name || 'N/A'} (${v.PseudoCity || 'N/A'})`)
        .join(', ')}
    </Typography>
  ) : (
    <Typography variant="bodySmall" color="charcoalGray">
      Not applied
    </Typography>
  );
  return (
    <>
      <div className="flex gap-2 justify-between p-2 border-b-grey-2 border-b">
        <div className="flex gap-1 items-center overflow-hidden">
          <FilterSvg
            color={filterEnabled ? palette['deep-green'] : palette['grey-5']}
            className="shrink-0"
          />
          {/* TRUNCATE STEP 2 (SOMETIMES OPTIONAL): add "overflow-hidden" on a parent element */}
          <div className="flex gap-1 overflow-hidden">
            <Typography
              variant="bodySmall"
              color="charcoalGray"
              className="whitespace-nowrap"
            >
              Filter by company:
            </Typography>
            {filterText}
          </div>
        </div>
        <div className="flex gap-3">
          {filterEnabled && (
            <Button
              variant="text"
              className="flex gap-1"
              customPadding
              onClick={() => {
                setSelectedCompanies([]);
                setOpenFilterDialog(false);
              }}
            >
              <RotateSvg />
              Clear
            </Button>
          )}
          <Button
            id="manageFilterButton"
            onClick={() => {
              setOpenFilterDialog(!openFilterDialog);
            }}
            variant="text"
            className={classNames(
              'flex gap-1 whitespace-nowrap',
              openFilterDialog && 'text-fcm-green [&_path]:fill-fcm-green'
            )}
            customPadding
          >
            <AdjustmentsSvg className="shrink-0" />
            Manage Filter
          </Button>
        </div>
      </div>
      {openFilterDialog && (
        <FilterDialog
          {...{
            companies,
            filterEnabled,
            selectedCompanies,
            setSelectedCompanies,
          }}
          closeFilterDialog={() => setOpenFilterDialog(false)}
        />
      )}
    </>
  );
};

export default SearchCompanyFilters;
