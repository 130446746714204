import classNames from 'classnames';
import { FC, useRef, useState } from 'react';

import { ReactComponent as StarSvg } from 'styles/icons/star.svg';

import { ControlledPopper } from '../Popper/Popper';
import Typography, { TypographyProps } from '../Typography/Typography';

interface VipProps
  extends Omit<TypographyProps, 'variant' | 'color' | 'children'> {
  className?: string;
  value: string;
}

const Vip: FC<VipProps> = ({ className, value }) => {
  const showPopup = value.length > 10;
  const text = showPopup ? `${value.substring(0, 10)}...` : value;
  const [isOpen, setIsOpen] = useState(false);
  const timeoutId = useRef<number>();
  const openWithTimeout = () => {
    timeoutId.current = window.setTimeout(() => {
      setIsOpen(true);
    }, 500);
  };

  const displayText = () => {
    return (
      <Typography
        variant="bodySmall2"
        color="ui-orange-lighten"
        className={classNames('tracking-[0.08em]', className)}
      >
        {text}
      </Typography>
    );
  };

  return (
    <div
      className={classNames(
        className,
        'flex items-center gap-1 rounded-full bg-[#5622AA] px-2 py-[2px]'
      )}
    >
      <StarSvg />
      {showPopup ? (
        <ControlledPopper
          onMouseLeave={() => {
            clearInterval(timeoutId.current);
            setIsOpen(false);
          }}
          onMouseEnter={openWithTimeout}
          setOpen={() => {}}
          open={isOpen}
          controlledArrow
          placement="bottom"
          trigger="hover"
          className="min-w-max"
          content={<Typography variant="bodySmall2">{value}</Typography>}
          strategy="fixed"
        >
          {displayText()}
        </ControlledPopper>
      ) : (
        displayText()
      )}
      <StarSvg />
    </div>
  );
};

export default Vip;
