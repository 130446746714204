import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import UncontrolledPopper, {
  ControlledPopper,
  useControlledPopper,
} from 'components/common/Popper/Popper';
import Typography from 'components/common/Typography/Typography';
import Wrapper from 'components/common/Wrapper/Wrapper';
import {
  PAGES_WITH_BACK_BUTTON,
  PAGES_WITH_BREADCRUMBS,
  PAGES_WITHOUT_TOP_GREEN_BAR,
  PAGES_WITHOUT_TOP_RIGHT_BLOCK,
} from 'constants/general';

import Bag from 'styles/icons/bag.svg';
import { ReactComponent as Close2Svg } from 'styles/icons/close2.svg';
import Email from 'styles/icons/email.svg';
import { ReactComponent as ExternalLinkSvg } from 'styles/icons/externalLink.svg';
import { ReactComponent as ArrowLeftSvg } from 'styles/icons/feedback/arrow-left.svg';
import { ReactComponent as InfoSvg } from 'styles/icons/info.svg';
import Phone from 'styles/icons/phone.svg';
import { ReactComponent as PrivacySvg } from 'styles/icons/privacy.svg';
import { SearchIcon } from 'styles/icons/Search';
import palette from 'styles/palette';
import { IBreadcrumb } from 'types/breadcrumbs';
import { getSectionFromUrl, isNA } from 'utils/common';
import { sendGoogleEvent } from 'utils/googleTag';

import AssignInfo from 'components/common/AssignInfo/AssignInfo';
import UnrecognisedPhone from 'components/common/UnrecognisedPhone/UnrecognisedPhone';
import { useBackButton } from 'components/pages/Feedback/Feedback';
import {
  useAgentData,
  useCurrentSection,
  useOverflowTooltip,
  useRouterQuery,
} from 'hooks/general';
import { useProfile } from 'hooks/profile';
import {
  breadcrumbsAtom,
  emptyIsArrangersOrApprovers,
  isFromArrangersOrApproversBlockAtom,
  phoneAssignmentAtom,
  prevRouteAtom,
  profileSourceAtom,
  requireCallerAuthAtom,
} from 'state/store';
import styles from './Header.module.scss';
import ElementWithTooltip from 'components/common/ElementWithTooltip';
// import { STORAGE_PREFIX } from 'constants/storage';

interface MultipleProfilesHeaderProps {
  isBreadcrumb?: boolean;
}

export const MultipleProfilesHeader: FC<MultipleProfilesHeaderProps> = ({
  isBreadcrumb = false,
}) => {
  const query = useRouterQuery();
  const emailRef = useRef<HTMLParagraphElement | null>(null);
  const breadcrumbs = useAtomValue(breadcrumbsAtom).value;
  const color = isBreadcrumb ? 'charcoalGray' : 'black';
  const isOneOfManyCrumb = !!breadcrumbs.length;
  const email = query.email || breadcrumbs[0]?.query?.email;
  const phone = query.phone || breadcrumbs[0]?.query?.phone;

  const { withOverflowTooltip: forElement } = useOverflowTooltip(
    email || phone || '',
    <Typography
      variant="bodySmall"
      color={color}
      className="overflow-hidden text-ellipsis whitespace-nowrap"
      ref={emailRef}
    >
      For {email || phone}
    </Typography>,
    false
  );

  return (
    <div
      className={classNames(
        styles.multipleProfiles,
        isOneOfManyCrumb && 'overflow-hidden'
      )}
    >
      <Typography
        className="overflow-hidden text-ellipsis whitespace-nowrap"
        variant="bodyLarge2"
        color={color}
      >
        Found profiles
      </Typography>
      {forElement}
    </div>
  );
};
const PAGES_WITH_SECTION_NAME: any = {
  'authorization-error': () => (
    <div className={classNames(styles.section, 'h-9')}>Authorization Error</div>
  ),
  feedback: () => <div className={styles.section}>Feedback</div>,
  'multiple-profiles': () => <MultipleProfilesHeader />,
  'restricted-access': () => (
    <div className={styles.section}>Restricted Access</div>
  ),
  search: () => <div className={styles.section}>Search</div>,
  'signed-out': () => (
    <div className={classNames(styles.section, 'h-9')}>Signed Out</div>
  ),
};

interface ISearchButtonProps {
  active: boolean;
  handleClick: () => void;
}

const SearchButton: FC<ISearchButtonProps> = ({ active, handleClick }) => (
  <div
    className={classNames(styles.search, { [styles.activeSearch]: active })}
    onClick={handleClick}
  >
    <SearchIcon active={active} />
    <Button
      className={classNames(styles.expand, { [styles.active]: active })}
      variant="primary"
    >
      CTRL + F
    </Button>
  </div>
);

interface HeaderContainerProps {
  currentSection: string;
}

const HeaderContainer: FC<HeaderContainerProps> = ({ currentSection }) => {
  const source = useAtomValue(profileSourceAtom);

  const query = useRouterQuery();
  const profile = useProfile()[0].value;
  const getEmailAndPhone = () => {
    if (currentSection === 'multiple-profiles') {
      return { email: query.email || '', phone: query.phone || '' };
    }
    return { email: profile.email, phone: profile.phone };
  };

  const { email, phone } = getEmailAndPhone();
  const isPhonePresent = !isNA(phone) && phone;
  const isEmailPresent = !isNA(email) && email;
  const isCompanyPresent = !isNA(profile.company) && profile.company.trim();

  const { withOverflowTooltip: companyElement } = useOverflowTooltip(
    profile.company,
    <span className={styles.companyName} />
  );
  if (
    (!isEmailPresent && !source && !isPhonePresent && !isCompanyPresent) ||
    PAGES_WITHOUT_TOP_GREEN_BAR.includes(currentSection)
  ) {
    return null;
  }
  return (
    <div className={styles.headerContainer}>
      <Wrapper className={styles.header}>
        {source && (
          <div className="whitespace-pre">{`Details obtained from ${source}`}</div>
        )}
        {isPhonePresent && (
          <div
            className={classNames(styles.contactItem, styles.phoneEmail)}
            id="phone"
          >
            <img src={Phone} alt="Phone" />
            <span>{phone}</span>
          </div>
        )}
        {isEmailPresent && (
          <div
            className={classNames(styles.contactItem, styles.phoneEmail)}
            id="email"
          >
            <img src={Email} alt="Email" />
            <span>{email}</span>
          </div>
        )}
        {isCompanyPresent && (
          <div
            className={classNames(styles.contactItem, 'overflow-hidden')}
            id="company"
          >
            <img src={Bag} alt="Bag" />

            {companyElement}
          </div>
        )}
      </Wrapper>
    </div>
  );
};
const Header: FC = () => {
  const currentSection = useCurrentSection();
  const [phoneAssignment] = useAtom(phoneAssignmentAtom);

  const isSearchPage = currentSection === 'search';
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const prevRoute = useAtomValue(prevRouteAtom);
  const navigate = useNavigate();
  const setIsFromArrangersOrApproversBlock = useSetAtom(
    isFromArrangersOrApproversBlockAtom
  );

  const handleNavigateToSearch = () => {
    sendGoogleEvent('open_search');
    setIsFromArrangersOrApproversBlock(emptyIsArrangersOrApprovers);
    navigate('/');
  };

  return (
    <div className={classNames(requireCallerAuth && 'sticky top-0 z-50')}>
      <div className={styles.line} />
      {requireCallerAuth ? null : phoneAssignment ? (
        <UnrecognisedPhone />
      ) : (
        <HeaderContainer currentSection={currentSection} />
      )}
      <div className={styles.menuContainer}>
        <Wrapper>
          <div className={styles.menu}>
            <div className="relative flex overflow-hidden grow">
              {requireCallerAuth &&
                prevRoute &&
                currentSection === 'profile' && (
                  <BackButton
                    prevRoute={prevRoute}
                    value={''}
                    className="mr-4"
                  />
                )}
              <TopSection />
            </div>
            {!PAGES_WITHOUT_TOP_RIGHT_BLOCK.includes(currentSection) && (
              <div className={classNames(styles.searchContainer, ' gap-4')}>
                <SearchButton
                  handleClick={handleNavigateToSearch}
                  active={isSearchPage}
                />
                {!requireCallerAuth ? (
                  <PrivacyNoticeBlock />
                ) : (
                  <RequireCallerAuthInfo />
                )}
              </div>
            )}
          </div>
        </Wrapper>
        <AssignInfo />
      </div>
    </div>
  );
};

const CallerAuthHeader: FC = () => {
  const {
    loading: isProfileLoading,
    value: { company, email, fullName },
  } = useProfile()[0];
  const titleText = [fullName, email, company]
    .filter((v) => !!v && !!v.trim())
    .join(' / ');

  const textRef = useRef(null);
  // const { withOverflowTooltip: titleElement } = useOverflowTooltip(
  //   titleText,
  //   <Typography ref={textRef} variant="bodyMedium" data-e2e-id="callerInfo">
  //     {isProfileLoading ? '...' : fullName}{' '}
  //     <span className="text-charcoal-grey">/</span>{' '}
  //     {isProfileLoading ? '...' : email}{' '}
  //     <span className="text-charcoal-grey">/</span>{' '}
  //     {isProfileLoading ? '...' : company}
  //   </Typography>,
  //   false,
  //   'fixed',
  //   'bodyMed',
  //   'whitespace-normal'
  // );
  return (
    <div className=" flex textWrap flex-col">
      <Typography variant="bodyLarge2" data-e2e-id="title">
        Caller Authentication
      </Typography>
      <ElementWithTooltip
        value={titleText}
        strategy="fixed"
        className="whitespace-normal"
      >
        <Typography ref={textRef} variant="bodyMedium" data-e2e-id="callerInfo">
          {isProfileLoading ? '...' : fullName}{' '}
          <span className="text-charcoal-grey">/</span>{' '}
          {isProfileLoading ? '...' : email}{' '}
          <span className="text-charcoal-grey">/</span>{' '}
          {isProfileLoading ? '...' : company}
        </Typography>
      </ElementWithTooltip>
    </div>
  );
};

const TopSection: FC = () => {
  const prevRoute = useAtomValue(prevRouteAtom);

  const currentSection = useCurrentSection();
  const query = useRouterQuery();
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const [isFromArrangersOrApproversBlock, setIsFromArrangersOrApproversBlock] =
    useAtom(isFromArrangersOrApproversBlockAtom);
  const displayPagesWithSection = PAGES_WITH_SECTION_NAME[currentSection];
  const [{ value: breadcrumbs }, dispatchBreadcrumbs] =
    useAtom(breadcrumbsAtom);
  const navigate = useNavigate();
  const isFromSignedOut = getSectionFromUrl(prevRoute || '') === 'signed-out';
  const backButtonProps = useBackButton();

  const isSearchTermChange =
    currentSection === 'search' &&
    getSectionFromUrl(prevRoute || '') === 'search';

  // If we requireCallerAuth === 'true' and we are on a profile page
  // we show CallerAuthHeader
  if (requireCallerAuth && currentSection === 'profile') {
    return <CallerAuthHeader />;
  }

  // If we came from Signed Out screen
  // we show section name if we have it
  if (isFromSignedOut && displayPagesWithSection) {
    return displayPagesWithSection?.(query);
  }
  // If we don't have previous route or
  // we have it, but the location change was because of searchTerm or
  // page shouldn't have a back button
  // we show section name if we have it
  if (
    (!prevRoute ||
      isSearchTermChange ||
      !PAGES_WITH_BACK_BUTTON.includes(currentSection)) &&
    displayPagesWithSection
  ) {
    return displayPagesWithSection?.(query);
  }
  // If we have previous route or
  // we are using arrangers or approvers block
  // we show back button and breadcrumbs
  if (prevRoute && isFromArrangersOrApproversBlock.profiles.length) {
    const { profiles } = isFromArrangersOrApproversBlock;
    const { prevCrumbs, profile: lastProfile } = profiles[profiles.length - 1];
    return (
      <div className="flex w-full items-center gap-4">
        <UncontrolledPopper
          trigger="hover"
          type="tooltip"
          content={
            <Typography variant="bodySmall" color="white">
              Back to {lastProfile.fullName} Profile
            </Typography>
          }
          strategy="fixed"
          placement="bottom"
          controlledArrow
        >
          <div className="flex">
            <BackButton
              prevRoute={''}
              value={''}
              onClick={() => {
                profiles.pop();
                dispatchBreadcrumbs({ type: 'SET', value: prevCrumbs });
                setIsFromArrangersOrApproversBlock({ profiles: [...profiles] });
                navigate(`/profile?travelerGuid=${lastProfile.guid}`);
              }}
            />
          </div>
        </UncontrolledPopper>
        <Breadcrumbs crumbPages={breadcrumbs} />
      </div>
    );
  }
  // If we have previous route and
  // page should have a back button and
  // location change wasn't because of searchTerm and
  // it's not a profile page with require caller auth
  // we show back button
  if (
    prevRoute &&
    PAGES_WITH_BACK_BUTTON.includes(currentSection) &&
    !isSearchTermChange
  ) {
    return <BackButton prevRoute={prevRoute} value={backButtonProps?.name} />;
  }
  // If page should have breadcrumbs and
  // previous matches didn't trigger
  // show breadcrumbs

  if (PAGES_WITH_BREADCRUMBS.includes(currentSection)) {
    return <Breadcrumbs crumbPages={breadcrumbs} />;
  }
  return null;
};

const RequireCallerAuthInfo = () => {
  const { isOpen, setIsOpen } = useControlledPopper();
  return (
    <ControlledPopper
      open={isOpen}
      setOpen={setIsOpen}
      trigger="hover"
      content={
        <div className="w-[240px] p-4">
          <Typography variant="bodyMedium">
            Use control questions to authenticate the caller. If for some reason
            you are unable to receive responses, please use the alternative
            authentication option below.
          </Typography>
        </div>
      }
      offsetOptions={10}
      customPadding
      placement="bottom"
      controlledArrow
    >
      <div className={classNames(isOpen && '[&>svg]:text-deep-green')}>
        <InfoSvg width={24} height={24} color={palette['grey-6']} />
      </div>
    </ControlledPopper>
  );
};

const PrivacyNoticeBlock = () => {
  const { isOpen, setIsOpen } = useControlledPopper();
  const { data: agentData } = useAgentData();

  return (
    <>
      <ControlledPopper
        trigger="click"
        type="popper"
        className={classNames('w-[248px]', styles.privacyShadow)}
        content={
          <div className="relative flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <Typography color="black" variant="bodyMedium2">
                Contact Privacy Team
              </Typography>
              <Button
                variant="text"
                customPadding
                onClick={() => setIsOpen(false)}
              >
                <Close2Svg width={16} height={16} className="mr-1" />
              </Button>
            </div>
            <Typography color="black" variant="bodySmall">
              In case of a suspected privacy/data breach incident please submit
              a request to the data protection and privacy team via{' '}
              <a
                className="inline text-deep-green"
                href={`mailto:${agentData?.ContactPrivacyTeamEmail}`}
              >
                {agentData?.ContactPrivacyTeamEmail}
              </a>{' '}
              email and they’ll assist you.
            </Typography>
            <div className="flex h-4 items-center justify-center">
              <div className="h-[1px] w-full bg-grey-3" />
            </div>
            <Button
              variant="text"
              customPadding
              onClick={() => setIsOpen(false)}
              className="absolute right-0 h-5"
            >
              <Close2Svg width={16} height={16} className="mr-1" />
            </Button>
            <Typography variant="bodyMedium2">
              <a
                className="flex items-center gap-1 hover:text-deep-green active:text-fcm-green"
                href="https://www.fcmtravel.com/en/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => setIsOpen(false)}
              >
                Privacy Policy
                <ExternalLinkSvg />
              </a>
            </Typography>
          </div>
        }
        open={isOpen}
        setOpen={setIsOpen}
        placement="bottom"
        offsetOptions={{ mainAxis: 10 }}
        controlledArrow
      >
        <Button
          variant="text"
          customPadding
          className={classNames('text-grey-6 hover:text-deep-green', {
            'text-fcm-green hover:text-fcm-green': isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <PrivacySvg />
        </Button>
      </ControlledPopper>
    </>
  );
};

interface IBackButtonProps {
  prevRoute: string;
  value?: string;
  className?: string;
  onClick?: ({
    prevRoute,
    value,
  }: {
    prevRoute: string;
    value?: string;
  }) => void;
  customMargin?: boolean;
}

const BackButton: FC<IBackButtonProps> = ({
  className,
  onClick,
  prevRoute,
  value,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (!onClick) {
          navigate(prevRoute);
          return;
        }
        onClick({ prevRoute, value });
      }}
      className={classNames(styles.backButton, className, 'h-[44px] gap-4')}
    >
      <ArrowLeftSvg className="h-4 w-4" />
      {value}
    </div>
  );
};

interface IBreadcrumbProps {
  crumbPages: IBreadcrumb[];
}

const Breadcrumbs: FC<IBreadcrumbProps> = ({ crumbPages }) => {
  const { loading: profileLoading } = useProfile()[0];
  if (profileLoading) {
    return <div className="h-6 w-[240px] bg-grey-2"></div>;
  }

  return (
    <div className={styles.breadcrumbs}>
      {crumbPages.map((crumb, idx) => {
        const isLastCrumb = idx === crumbPages.length - 1;
        const multipleCrumbs = crumbPages.length > 1;
        return (
          <Fragment key={idx}>
            {crumb.render(isLastCrumb, multipleCrumbs)}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Header;
