import { uniqBy } from 'ramda';
import { FC, useState } from 'react';

import Badge from 'components/common/Badge/Badge';
import Chevron from 'components/common/Icons/Chevron';
import Typography from 'components/common/Typography/Typography';
import { AlertLevel, Match } from 'types/trips';
import { renderPlurals } from 'utils/format';
import { getAlertsByLevel, sortMatchesByAlertDate } from 'utils/trips';

import styles from '../../DetailedTrips.module.scss';
import ExpandableAlert from './ExpandableAlert';

const AlertsList: FC<{ matches?: Match[] }> = ({ matches }) => {
  const [showInfoAlerts, setShowInfoAlerts] = useState(false);
  if (!matches?.length) return null;
  const uniqueAlerts = uniqBy((match) => match.Alert.Id, matches)
    .sort(sortMatchesByAlertDate)
    .map((match) => match.Alert);

  const [criticalAlerts, warningAlerts, infoAlerts] = (
    ['CRITICAL', 'WARNING', 'INFO'] as AlertLevel[]
  ).map((level) => {
    return getAlertsByLevel(level, uniqueAlerts);
  });
  const hasImportantAlerts = !!criticalAlerts.length || !!warningAlerts.length;

  return (
    <div className="flex flex-col gap-2 pl-[40px]">
      {[...criticalAlerts, ...warningAlerts]?.map((alert) => {
        return <ExpandableAlert alert={alert} key={alert.Id} />;
      })}
      {!!infoAlerts.length && (
        <>
          {!showInfoAlerts && (
            <div
              className="cursor-pointer"
              onClick={() => setShowInfoAlerts(true)}
            >
              <Badge className={styles.infoAlertBadge}>
                <div className={styles.infoCircle} />
                <Typography variant="bodySmall2" color="ui-blue-darken">
                  {infoAlerts.length} {hasImportantAlerts && 'more'} Info{' '}
                  {renderPlurals({
                    count: infoAlerts.length,
                    entity: ['Alert', 'Alerts'],
                    showAmount: false,
                  })}
                </Typography>
              </Badge>
            </div>
          )}
          {showInfoAlerts && (
            <>
              {infoAlerts?.map((alert, i) => {
                return <ExpandableAlert alert={alert} key={i} />;
              })}
              <div
                className="flex cursor-pointer items-center gap-2 px-3"
                onClick={() => setShowInfoAlerts(false)}
              >
                <div className="flex h-4 w-4 items-center justify-center ">
                  <Chevron transform="rotate(-90)" fill="black" />
                </div>
                <Typography variant="bodyMedium2">Hide Info Alerts</Typography>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AlertsList;
