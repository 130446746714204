import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { IAPITrip, ITrip } from 'types/trips';
import { apiService } from 'utils/apiService';
import { mapTrips } from 'utils/trips';

interface IFetchTripsPayload {
  guid: string;
  cursor: string | null | undefined;
  isFutureTrips: boolean | undefined;
}

export const fetchTrips = async ({
  cursor,
  guid,
  isFutureTrips,
}: IFetchTripsPayload) => {
  const query = new URLSearchParams();
  query.append('Size', '5');
  if (cursor) {
    query.append('Cursor', cursor);
  }
  if (isFutureTrips !== undefined) {
    // false value is also valid
    query.append('IsFutureTrips', isFutureTrips.toString());
  }
  const response = (await apiService({
    method: 'get',
    url: `/traveler/${guid}/trips?${query.toString()}`,
  })) as { Items: IAPITrip[]; Pagination: { Next: string | null } };
  return {
    cursor: response.Pagination.Next,
    items: mapTrips(response.Items),
  };
};

export const useFetchTrips = (guid: string, isFutureTrips: boolean) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    enabled: guid !== 'N/A',
    getNextPageParam: (lastPage) => {
      return lastPage.cursor;
    },

    queryFn: ({ pageParam: cursor }) =>
      fetchTrips({ cursor, guid, isFutureTrips }),
    queryKey: ['trips', guid, isFutureTrips],
  });

  if (!data) {
    return {
      fetchNextPage,
      hasNextPage,
      isFetched,
      isLoading: isLoading || isFetchingNextPage,
      trips: [],
    };
  }

  const trips = data.pages.map((val) => val.items).flat();

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isLoading: isLoading || isFetchingNextPage,
    trips,
  };
};

export const useFutureAndPastTrips = (guid: string) => {
  const { isFetched: futureTripsFetched, trips: futureTrips } = useFetchTrips(
    guid,
    true
  );
  const { isFetched: pastTripsFetched, trips: pastTrips } = useFetchTrips(
    guid,
    false
  );

  return {
    futureTrips,
    isFetched: futureTripsFetched && pastTripsFetched,
    pastTrips,
  };
};

export const fetchTrip = async (tripId: string | undefined) => {
  const response = (await apiService({
    method: 'get',
    url: `/trips/${tripId}`,
  })) as IAPITrip;
  return mapTrips([response])[0];
};

export const useFetchTrip = (tripId: string | undefined) => {
  const { data, isFetching } = useQuery({
    enabled: !!tripId,
    queryFn: () => fetchTrip(tripId),
    queryKey: ['trip', tripId],
    retry: 1,
    staleTime: Infinity,
  }) as { data: ITrip; isFetching: boolean };
  return { isLoading: isFetching, trip: data };
};
