import classNames from 'classnames';
import { forwardRef, ReactNode, Ref } from 'react';

import palette from 'styles/palette';

import styles from './Typography.module.scss';

const colorMap = {
  ...palette,
  black: palette['midnight-black'],
  charcoalGray: palette['charcoal-grey'],
  'grey-4': palette['grey-4'],
  'ui-orange-lighten': palette['ui-orange-lighten'],
  uiRedDarken: palette['ui-red-darken'],
  white: palette['clarity-white'],
};

export interface TypographyProps {
  component?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div';
  variant:
    | 'bodyXs'
    | 'bodySmall'
    | 'bodySmall2'
    | 'bodyMedium'
    | 'bodyMedium2'
    | 'bodyLarge'
    | 'bodyLarge2'
    | 'headingSmall';
  color?: keyof typeof colorMap;
  className?: string;
  children?: ReactNode | ReactNode[];
  title?: string;
}

const Typography = (
  {
    children,
    className,
    color = 'black',
    component = 'p',
    title,
    variant,
    ...props
  }: TypographyProps,
  ref: Ref<HTMLParagraphElement>
) => {
  const Component = component;
  return (
    <Component
      title={title}
      className={classNames(className, { [styles[variant]]: variant })}
      style={{ color: colorMap[color] }}
      ref={ref}
      {...props}
    >
      {children}
    </Component>
  );
};

export default forwardRef(Typography);
