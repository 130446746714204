import classNames from 'classnames';
import Button from 'components/common/Button/Button';
import ElementWithTooltip from 'components/common/ElementWithTooltip';
import FlightDate from 'components/common/FlightDate';
import Car from 'components/common/Icons/Car';
import Hotel from 'components/common/Icons/Hotel';
import Plane from 'components/common/Icons/Plane';
import Train from 'components/common/Icons/Train';
import Pnr from 'components/common/Pnr/Pnr';
import UncontrolledPopper from 'components/common/Popper/Popper';
import Sherpa from 'components/common/Sherpa/Sherpa';
import Typography from 'components/common/Typography/Typography';
import { getFlightDisplayDataFromBooking } from 'components/pages/DetailedTrips/components/AirSection/AirSection';
import { getCarDisplayDataFromBooking } from 'components/pages/DetailedTrips/components/CarSection';
import {
  getHotelDisplayDataFromBooking,
  HotelNameWithStars,
} from 'components/pages/DetailedTrips/components/HotelSection';
import { getTrainDisplayDataFromBooking } from 'components/pages/DetailedTrips/components/TrainSection';
import { iconMap2 } from 'constants/trips';
import { format } from 'date-fns';
import { useCurrentSection } from 'hooks/general';
import { useAtomValue } from 'jotai';
import { prop, uniqBy } from 'ramda';
import { FC, Fragment, ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isComponentPageAtom } from 'state/store';
import { ReactComponent as ChevronRightSvg } from 'styles/icons/chevron_right.svg';
import palette from 'styles/palette';

import {
  AlertLevel,
  ICarRentalBookingNotPartial,
  IFlightBooking,
  IFlightBookingNotPartial,
  IHotelBookingNotPartial,
  ITrainBookingNotPartial,
  ITrip,
  MappedAlert,
  Match,
} from 'types/trips';
import { useElementSize } from 'usehooks-ts';
import { renderPlurals } from 'utils/format';
import {
  formatTime,
  getAlertGroups,
  getAlertsByLevel,
  getBookingType,
  getLocationCode,
  sortMatchesByAlertDate,
} from 'utils/trips';
import LocationBlock from '../LocationBlock/LocationBlock';

import styles from './TripItem.module.scss';
interface ITripItemProps {
  currentTrip?: ITrip;
  trip: ITrip;
  className?: string;
  areNext: boolean;
  travellerName?: string; // used for Linked Pnrs page
}

const TRIPS_ICON_COUNT = 3;

export const getAllAffectingMatches = (booking: IFlightBooking) =>
  [
    booking.AffectingMatches,
    booking.Layovers?.map((v) => v.FlightBooking.AffectingMatches),
  ]
    .flat(2)
    .filter(Boolean)
    .sort(sortMatchesByAlertDate);

const getAlertsForTrip = (trip: ITrip) => {
  return (
    trip?.Travellers?.[0]?.Bookings?.map((booking, bookingId) => {
      // TODO: alerts for each traveller? Need to define what to do with it
      if (!('Flight' in booking)) {
        return undefined;
      }
      return getAllAffectingMatches(booking)
        .map((val) => {
          const locations = getLocationCode(booking);
          return {
            ...val,
            bookingId,
            flightName: `${locations[0]} - ${locations[1]}`,
          };
        })
        .sort(sortMatchesByAlertDate);
    })

      .flat()
      .filter(
        (
          v
        ): v is Match & {
          flightName: string;
          bookingId: number;
        } => !!v
      )
      .map(({ Alert, bookingId, flightName }) => {
        return {
          ...Alert,
          bookingId,
          flightName,
        };
      }) ?? []
  );
};
const TripIcons: FC<{ trip: ITrip; count: number }> = ({
  count,
  trip: { firstTravellerTrip },
}) => {
  const areIconsWithPopover =
    firstTravellerTrip && firstTravellerTrip.length > count;
  if (!areIconsWithPopover) {
    return (
      <>
        {firstTravellerTrip?.map((val, idx) => (
          <Fragment key={idx}>{iconMap2[getBookingType(val as any)]}</Fragment>
        ))}
      </>
    );
  }

  const firstIcons = firstTravellerTrip
    ?.slice(0, count - 1)
    .map(getBookingType);
  const badgeIcons = firstTravellerTrip?.slice(count - 1).map(getBookingType);

  return (
    <div className="flex gap-2">
      <div className="flex items-center gap-2">
        {firstIcons?.map((val) => iconMap2[val])}
      </div>
      <UncontrolledPopper
        trigger="hover"
        content={
          <div className="flex flex-col gap-2">
            {badgeIcons?.map((iconName, idx) => (
              <div key={idx} className="flex items-center gap-2">
                {iconMap2[iconName]} {iconName}
              </div>
            ))}
          </div>
        }
        placement="top"
        className="w-[102px] p-2"
        customPadding
      >
        <div className={styles.badge}>+{badgeIcons?.length}</div>
      </UncontrolledPopper>
    </div>
  );
};

const BREAKING_SIZE = 680;

const TripItem: FC<ITripItemProps> = ({
  areNext,
  className,
  currentTrip,
  trip,
}) => {
  const location = useLocation();

  const isComponentPage = useAtomValue(isComponentPageAtom);
  const [open, setOpen] = useState(false);
  const isTripDetails = location.pathname.includes('detailed-trips');
  const destinationPathname = `/detailed-trips/${trip.Id}`;
  const alerts = getAlertsForTrip(trip);
  const currentSection = useCurrentSection();
  const navigate = useNavigate();

  const startYear = new Date(trip.StartOn);
  const endYear = new Date(trip.EndOn);
  const isCurrentTrip = trip.Id === currentTrip?.Id;
  const dateValue = `${format(startYear, 'd MMM yyyy')} - ${format(
    endYear,
    'd MMM yyyy'
  )}`;

  const goToTripDetails = () => {
    if (isComponentPage || currentSection === 'linkedPnrs') return;
    const searchString = new URLSearchParams(window.location.search);
    if (searchString.has('areNext')) {
      searchString.delete('areNext');
    }
    searchString.append('areNext', areNext.toString());
    if (location.pathname === destinationPathname) return;
    navigate({
      pathname: destinationPathname,
      search: searchString.toString(),
    });
  };

  const [squareRef, { width }] = useElementSize();
  const isWide = width >= BREAKING_SIZE;

  return (
    <div
      className={classNames(
        styles.tripItem,
        'flex w-full flex-col items-stretch gap-1 overflow-hidden p-3',
        {
          [styles.canceled]: trip.isCanceled,
          [styles.currentTrip]: isCurrentTrip,
        },
        className
      )}
      ref={squareRef}
    >
      <div
        className={classNames(
          'flex items-center',
          !isComponentPage && 'cursor-pointer'
        )}
      >
        {!isTripDetails && (
          <Button
            variant="text"
            className="-ml-2 p-2"
            customPadding
            onClick={() => setOpen((v) => !v)}
          >
            <ChevronRightSvg
              className={classNames(
                'w-4 transition-all',
                open ? 'rotate-90' : 'rotate-0'
              )}
            />
          </Button>
        )}

        <div
          className={classNames('flex grow justify-between overflow-hidden', {
            'gap-4': !isTripDetails,
          })}
          onClick={goToTripDetails}
        >
          <div
            className={classNames('flex ', {
              'flex-col gap-1': !isWide && !isTripDetails,
              'flex-col justify-between': isTripDetails,
              'flex-row gap-4': isWide && !isTripDetails,
            })}
          >
            <div
              className={classNames(styles.titleBlock, {
                'w-[20ch]': !isTripDetails,
              })}
            >
              {trip.isCanceled && (
                <div className={styles.canceledBlock}>Cancelled</div>
              )}
              <Pnr value={trip.PnrRecordLocator} variant="bodyMedium2" />

              {!!alerts.length && <TripAlerts alerts={alerts} />}
            </div>
            <ElementWithTooltip value={dateValue}>
              <Typography
                variant={!isWide && isTripDetails ? 'bodySmall' : 'bodyMedium'}
                className={classNames('textWrap', {
                  'w-[24ch]': !isTripDetails,
                })}
              />
            </ElementWithTooltip>
          </div>
          <div
            className={classNames('flex  ', {
              'flex-col-reverse items-end justify-between': !isWide,
              'grow items-center gap-4': !isTripDetails && isWide,
              'max-w-[50%] flex-col-reverse items-end gap-1': isTripDetails,
            })}
          >
            <LocationBlock trip={trip} />
            <div
              className={classNames(
                'ml-auto flex items-center justify-end gap-[6px]',
                isCurrentTrip && '[&_path]:fill-deep-green'
              )}
            >
              <TripIcons trip={trip} count={TRIPS_ICON_COUNT} />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div
          className={classNames('relative grid w-full gap-2 py-2', {
            [styles.gridColumns]: isWide,
            [styles.gridColumnsSmall]: !isWide,
          })}
        >
          {trip.firstTravellerTrip?.map((section, i, arr) => {
            const detailsMap = {
              Car: CarSectionDetails,
              Flight: FlightSectionDetails,
              Hotel: HotelSectionDetails,
              Train: TrainSectionDetails,
            };
            const Details = detailsMap[getBookingType(section as any)];
            const isLast = i === arr.length - 1;
            return (
              <Details
                key={i}
                section={section as any}
                isLast={isLast}
                isCurrentTrip={isCurrentTrip}
                isWide={isWide}
                detailsUrl={`/detailed-trips/${trip.Id}#${getBookingType(
                  section
                )}_${i}`}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

interface IDetails<T> {
  section: T;
  isLast: boolean;
  isCurrentTrip: boolean;
  isWide: boolean;
  detailsUrl: string;
}

const MoreDetails: FC<{
  isWide: boolean;
  children?: ReactElement | false;
  url: string;
}> = ({ children, isWide, url }) => {
  const navigate = useNavigate();
  return (
    <div className={classNames('flex flex-col ', isWide && 'items-end')}>
      <Button
        variant="text"
        className="underline"
        customPadding
        onClick={() =>
          navigate({
            hash: url.split('#')[1],
            pathname: url.split('#')[0],
            search: window.location.search,
          })
        }
      >
        <Typography variant="bodySmall" color="midnight-black">
          More Details
        </Typography>
      </Button>
      {children}
    </div>
  );
};
const formatDate = (date: string, stringFormat: string) => {
  return date ? format(new Date(date), stringFormat) : 'N/A';
};
const HotelSectionDetails: FC<IDetails<IHotelBookingNotPartial>> = ({
  detailsUrl,
  isCurrentTrip,
  isLast,
  isWide,
  section,
}) => {
  const { arrivalDate, departureDate, duration, hotelName, rating } =
    getHotelDisplayDataFromBooking(section);
  return (
    <>
      <Icon
        Svg={Hotel}
        isCurrentTrip={isCurrentTrip}
        showLine={isLast}
        isWide={isWide}
      />

      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {formatDate(departureDate, 'd MMM')} -{' '}
          {formatDate(arrivalDate, 'd MMM')}
        </Typography>
        <HotelNameWithStars
          hotelName={hotelName}
          rating={rating}
          tooltipOnOverflow
          variant="bodySmall"
        />
      </div>
      <div />
      {!isWide && <div />}

      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {duration
            ? renderPlurals({
                count: duration,
                entity: ['Night', 'Nights'],
              })
            : 'N/A Nights'}{' '}
          Stay
        </Typography>
      </div>
      <MoreDetails isWide={isWide} url={detailsUrl} />
    </>
  );
};

const CarSectionDetails: FC<IDetails<ICarRentalBookingNotPartial>> = ({
  detailsUrl,
  isCurrentTrip,
  isLast,
  isWide,
  section,
}) => {
  const {
    arrivalDate,
    departureDate,
    dropoffLocationName,
    duration,
    pickupLocationName,
  } = getCarDisplayDataFromBooking(section);
  return (
    <>
      <Icon
        Svg={Car}
        isCurrentTrip={isCurrentTrip}
        showLine={isLast}
        isWide={isWide}
      />
      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {formatDate(departureDate, 'd MMM, h:mm a')}
        </Typography>
        <ElementWithTooltip value={pickupLocationName}>
          <Typography variant="bodySmall" />
        </ElementWithTooltip>
      </div>
      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {formatDate(arrivalDate, 'd MMM, h:mm a')}
        </Typography>
        <ElementWithTooltip value={dropoffLocationName}>
          <Typography variant="bodySmall" />
        </ElementWithTooltip>
      </div>
      {!isWide && <div />}
      <div className="flex flex-col">
        <Typography variant="bodySmall">
          {duration
            ? renderPlurals({
                count: duration,
                entity: ['day', 'days'],
              })
            : 'N/A days'}
        </Typography>{' '}
      </div>
      <MoreDetails isWide={isWide} url={detailsUrl} />
    </>
  );
};

const TrainSectionDetails: FC<IDetails<ITrainBookingNotPartial>> = ({
  detailsUrl,
  isCurrentTrip,
  isLast,
  isWide,
  section,
}) => {
  const {
    arrivalDate,
    convertedDuration,
    departureDate,
    dropoffLocationName,
    duration,
    pickupLocationName,
  } = getTrainDisplayDataFromBooking(section);
  return (
    <>
      <Icon
        Svg={Train}
        isCurrentTrip={isCurrentTrip}
        showLine={isLast}
        isWide={isWide}
      />
      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {formatDate(departureDate, 'd MMM, h:mm a')}
        </Typography>
        <ElementWithTooltip value={pickupLocationName}>
          <Typography variant="bodySmall" />
        </ElementWithTooltip>
      </div>
      <div className="flex flex-col">
        <Typography variant="bodySmall" color="charcoal-grey">
          {formatDate(arrivalDate, 'd MMM, h:mm a')}
        </Typography>
        <ElementWithTooltip value={dropoffLocationName}>
          <Typography variant="bodySmall" />
        </ElementWithTooltip>
      </div>
      {!isWide && <div />}
      <div className="flex flex-col">
        <Typography variant="bodySmall" data-e2e-travel_duration>
          {duration ? convertedDuration : 'Duration N/A'}
        </Typography>
      </div>
      <MoreDetails isWide={isWide} url={detailsUrl} />
    </>
  );
};
const Icon: FC<{
  Svg: any;
  isCurrentTrip: boolean;
  showLine: boolean;
  isWide: boolean;
}> = ({ Svg, isCurrentTrip, isWide, showLine }) => {
  return (
    <div className="relative flex">
      <Svg
        fill={palette['deep-green']}
        className={classNames('z-10 h-6 w-4 shrink-0 ', {
          'bg-clarity-white': !isCurrentTrip,
          'bg-green-highlight': isCurrentTrip,
        })}
      />
      {!showLine && (
        <div
          className={classNames(
            {
              'h-[calc(100%_+_8px)]': isWide,
              'h-[calc(200%_+_8px)]': !isWide,
            },
            'absolute left-2 top-2 w-[1px] bg-grey-3'
          )}
        />
      )}
    </div>
  );
};
const FlightSectionDetails: FC<IDetails<IFlightBookingNotPartial>> = ({
  detailsUrl,
  isCurrentTrip,
  isLast,
  isWide,
  section,
}) => {
  const {
    arrivalIataCity,
    arrivalToShow,
    convertedMainFlightDuration,
    departureIataCity,
    departureToShow,
    flightArrivalAt,
    flightDepartureAt,
    flightEstimatedArrivalAt,
    flightEstimatedDepartureAt,
    sherpa,
  } = getFlightDisplayDataFromBooking(section);
  const { Layovers } = section;
  const getLayversText = () => {
    if (!Layovers?.length) {
      return '(Nonstop)';
    }
    let result =
      '(' +
      renderPlurals({
        count: Layovers.length,
        entity: ['Stop', 'Stops'],
        showAmount: true,
      }) +
      ': ';
    result += Layovers.map((v) => formatTime(v.Duration)).join(', ') + ')';
    return result;
  };

  return (
    <>
      <Icon
        Svg={Plane}
        isCurrentTrip={isCurrentTrip}
        showLine={isLast}
        isWide={isWide}
      />

      <FlightDate
        type="profileTrips"
        className={classNames(styles.from)}
        cityCode={departureIataCity}
        date={formatDate(departureToShow, 'd MMM, h:mm a')}
        preciseDate={flightDepartureAt}
        showPreciseDate={
          !!(
            flightEstimatedDepartureAt &&
            flightDepartureAt &&
            flightEstimatedDepartureAt !== flightDepartureAt
          )
        }
      />

      <FlightDate
        type="profileTrips"
        className={classNames(styles.to)}
        cityCode={arrivalIataCity}
        date={formatDate(arrivalToShow, 'd MMM, h:mm a')}
        preciseDate={flightArrivalAt}
        showPreciseDate={
          !!(
            flightEstimatedArrivalAt &&
            flightArrivalAt &&
            flightEstimatedArrivalAt !== flightArrivalAt
          )
        }
      />
      {!isWide && <div />}
      <div className="flex  flex-col">
        <ElementWithTooltip
          value={`${convertedMainFlightDuration} ${getLayversText()}`}
        >
          <Typography variant="bodySmall" color="charcoal-grey" />
        </ElementWithTooltip>
        {section?.Flight?.AirLine && section?.Flight?.Number && (
          <div className="flex items-center gap-1">
            {section.Flight.AirLineLogo && (
              <img src={section.Flight.AirLineLogo} width={12} height={12} />
            )}
            <ElementWithTooltip
              value={`${section.Flight.AirLine} ${section.Flight.Number}`}
              shouldReplaceChildren={false}
            >
              <Typography variant="bodySmall" color="midnight-black">
                {section.Flight.AirLine} {section.Flight.Number}
              </Typography>
            </ElementWithTooltip>
          </div>
        )}
      </div>
      <MoreDetails isWide={isWide} url={detailsUrl}>
        {!!sherpa && <Sherpa value={sherpa} iconSize={12} />}
      </MoreDetails>
    </>
  );
};

interface TripAlertsProps {
  alerts: MappedAlert[];
}

interface AlertIndicatorProps {
  level: AlertLevel;
  alerts: MappedAlert[];
}

const AlertIndicator: FC<AlertIndicatorProps> = ({ alerts, level }) => {
  const groupedByFlightsAlerts = getAlertGroups(alerts);
  const uniqAlertsCount = uniqBy(prop('Id'), alerts);
  return (
    <UncontrolledPopper
      placement="top"
      trigger="hover"
      type="tooltip"
      offsetOptions={{ mainAxis: 10 }}
      controlledArrow
      content={
        <div className="flex max-w-[360px] flex-col">
          {groupedByFlightsAlerts.map((group) => {
            return (
              <>
                {group.repeating.map(([, alerts]) => {
                  return (
                    <>
                      <Typography variant="bodySmall" color="white">
                        <span className="font-bold">
                          {uniqBy(prop('bookingId'), alerts)
                            .map((v: MappedAlert) => `Flight: ${v.flightName}`)
                            .join(', ')}
                        </span>
                      </Typography>
                      {uniqBy(prop('Id'), alerts).map((alert) => {
                        return (
                          <div key={alert.Id}>
                            <Typography variant="bodySmall2" color="white">
                              {alert.Category}: {alert.Title}
                            </Typography>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
                {!!group.unique.length && (
                  <>
                    <Typography variant="bodySmall" color="white">
                      <span className="font-bold">
                        Flight: {group.unique[0].flightName}
                      </span>
                    </Typography>
                    {group.unique.map((alert) => {
                      return (
                        <div key={alert.Id}>
                          <Typography variant="bodySmall2" color="white">
                            {alert.Category}: {alert.Title}
                          </Typography>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            );
          })}
        </div>
      }
    >
      <div
        className={classNames(
          'inline-flex h-[16px] items-center justify-center gap-[2px] rounded-full px-1',
          {
            'bg-ui-blue-darken/15  [&>div]:bg-ui-blue-darken [&>p]:text-ui-blue-darken':
              level === 'INFO',
            'bg-ui-orange-darken/15 [&>div]:bg-ui-orange-darken [&>p]:text-ui-orange-darken':
              level === 'WARNING',
            'bg-ui-red-darken/15 [&>div]:bg-ui-red-darken [&>p]:text-ui-red-darken':
              level === 'CRITICAL',
          }
        )}
      >
        <div className="h-2 w-2 rounded-full" />
        {uniqAlertsCount.length > 1 && (
          <Typography
            variant="bodyXs"
            className="mr-[2px] font-bold leading-none"
          >
            {uniqAlertsCount.length}
          </Typography>
        )}
      </div>
    </UncontrolledPopper>
  );
};

const TripAlerts: FC<TripAlertsProps> = ({ alerts }) => {
  return (
    <div className="flex items-start gap-1">
      {(['CRITICAL', 'WARNING', 'INFO'] as AlertLevel[]).map((level) => {
        const alertsByLevel = getAlertsByLevel<MappedAlert>(level, alerts);
        return (
          !!alertsByLevel.length && (
            <AlertIndicator level={level} alerts={alertsByLevel} />
          )
        );
      })}
    </div>
  );
};

export default TripItem;
