export const CAR_TYPES = {
  COMMERCIAL_VAN_TRUCK: 'Commercial Van/Truck',
  CONVERTIBLE: 'Convertible',
  COUPE: 'Coupe',
  CROSSOVER: 'Crossover',
  FOUR_FIVE_DOORS: '4-5 Door',
  LIMOUSINE_SEDAN: 'Limousine/Sedan',
  MONOSPACE: 'Monospace',
  MOTOR_HOME: 'Motor Home',
  OPEN_AIR_ALL_TERRAIN: 'Open Air All Terrain',
  PASSENGER_VAN: 'Passenger Van',
  PICK_UP_FOUR_DOORS: 'Pick up (double cab) 4 door',
  PICK_UP_TWO_DOORS: 'Pick up (single/extended cab) 2 door',
  RECREATIONAL_VEHICLE: 'Recreational Vehicle',
  ROADSTER: 'Roadster',
  SPECIAL: 'Special',
  SPECIAL_OFFER_CAR: 'Special Offer Car',
  SPORT: 'Sport',
  SUV: 'SUV',
  TWO_FOUR_DOORS: '2/4 Door',
  TWO_THREE_DOORS: '2-3 Door',
  TWO_WHEEL_VEHICLE: '2 Wheel Vehicle',
  UNKNOWN: 'N/A',
  WAGON_ESTATE: 'Wagon/Estate',
};

export const CAR_CATEGORY = {
  COMPACT: 'Compact',
  COMPACT_ELITE: 'Compact Elite',
  ECONOMY: 'Economy',
  ECONOMY_ELITE: 'Economy Elite',
  FULLSIZE: 'Fullsize',
  FULLSIZE_ELITE: 'Fullsize Elite',
  INTERMEDIATE: 'Intermediate',
  INTERMEDIATE_ELITE: 'Intermediate Elite',
  LUXURY: 'Luxury',
  LUXURY_ELITE: 'Luxury Elite',
  MINI: 'Mini',
  MINI_ELITE: 'Mini Elite',
  OVERSIZE: 'Oversize',
  PREMIUM: 'Premium',
  PREMIUM_ELITE: 'Premium Elite',
  SPECIAL: 'Special',
  STANDARD: 'Standard',
  STANDARD_ELITE: 'Standard Elite',
  UNKNOWN: 'N/A',
};

export const FUEL = {
  DIESEL_AIR: 'Diesel Air',
  DIESEL_NO_AIR: 'Diesel No Air',
  ELECTRIC_AIR: 'Electric (Distance < 250mi/400km) Air',
  ELECTRIC_PLUS_AIR: 'Electric Plus (Distance ≥ 250mi/400km) Air',
  ETHANOL_AIR: 'Ethanol Air',
  ETHANOL_NO_AIR: 'Ethanol No Air',
  HYBRID_AIR: 'Hybrid Air',
  HYBRID_PLUG_IN_AIR: 'Hybrid Plug in Air',
  HYDROGEN_AIR: 'Hydrogen Air',
  HYDROGEN_NO_AIR: 'Hydrogen No Air',
  LPG_COMPRESSED_GAS_AIR: 'LPG/Compressed Gas Air',
  LPG_COMPRESSED_GAS_NO_AIR: 'LPG/Compressed Gas No Air',
  MULTI_FUEL_AIR: 'Multi Fuel/Power Air',
  MULTI_FUEL_POWER_NO_AIR: 'Multi fuel/power No Air',
  PETROL_AIR: 'Petrol Air',
  PETROL_NO_AIR: 'Petrol No Air',
  UNKNOWN: 'N/A',
  UNSPECIFIED_FUEL_POWER_WITH_AIR: 'Unspecified Fuel/Power With Air',
  UNSPECIFIED_FUEL_POWER_WITHOUT_AIR: 'Unspecified Fuel/Power Without Air',
};

export const TRANSMISSION = {
  AUTO_AWD: 'Auto AWD',
  AUTO_FOUR_WD: 'Auto 4WD',
  AUTO_UNSPECIFIED_DRIVE: 'Auto Unspecified Drive',
  MANUAL_AWD: 'Manual AWD',
  MANUAL_FOUR_WD: 'Manual 4WD',
  MANUAL_UNSPECIFIED_DRIVE: 'Manual Unspecified Drive',
  UNKNOWN: 'N/A',
};
export type TCarTypes = typeof CAR_TYPES;
export type TCarCategory = typeof CAR_CATEGORY;
export type TFuel = typeof FUEL;
export type TTransmission = typeof TRANSMISSION;
