import classNames from 'classnames';
import { FC } from 'react';

import UncontrolledPopper from 'components/common/Popper/Popper';
import Typography from 'components/common/Typography/Typography';
import { ReactComponent as EditSvg } from 'styles/icons/edit.svg';
import palette from 'styles/palette';

import { popoverShadow } from './OptionsPopover';

const EditedInfoPopover: FC<{
  updatedBy: string;
  updatedAt: string;
}> = ({ updatedAt, updatedBy }) => {
  return (
    <UncontrolledPopper // TODO: fix popper width changing on hover
      trigger="hover"
      placement="top"
      className={classNames('w-[200px] shrink-0 break-words', popoverShadow)}
      controlledArrow
      content={
        <div>
          {/* <div className="max-w-[200px] break-words"> */}
          <Typography variant="bodySmall" color="charcoalGray">
            Edited by
          </Typography>
          <Typography variant="bodySmall" color="black">
            {updatedBy}
          </Typography>
          <Typography variant="bodySmall" color="black">
            {updatedAt}
          </Typography>
        </div>
      }
      offsetOptions={10}
    >
      <div className={'flex shrink-0 cursor-pointer items-center gap-2'}>
        <EditSvg color={palette['grey-6']} width={13.33} height={12.75} />
        <Typography variant="bodySmall" color="charcoalGray">
          Edited
        </Typography>
      </div>
    </UncontrolledPopper>
  );
};

export default EditedInfoPopover;
