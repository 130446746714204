export const travellerTableColumns = [
  {
    field: 'name',
    style: {
      fontWeight: 500,
      maxWidth: '50%',
    },
    text: 'Name',
    width: '50%',
  },
  {
    field: 'position',
    style: {
      fontWeight: 400,
      maxWidth: '25%',
      paddingRight: '8px',
    },
    text: 'Position',
    width: '25%',
  },
  {
    field: 'pcc',
    style: {
      fontWeight: 500,
      maxWidth: '100px',
      paddingRight: '8px',
    },
    text: 'PCC / OID',
    width: '100px',
  },
  {
    field: 'locator',
    headStyle: {
      alignItems: 'flex-end',
      display: 'flex',
      height: '24px',
      justifyContent: 'flex-end',
    },
    style: {
      alignItems: 'center',
      height: '44px',
      justifyContent: 'flex-end',
      maxWidth: '25%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    text: 'GDS Profile locator',
    width: '25%',
  },
  {
    field: 'action',
    width: '7%',
  },
];
