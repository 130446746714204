import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from 'components/common/Loader';
import { useProfile } from 'hooks/profile';

const ProfileOrTravellers: FC = () => {
  const profile = useProfile()[0].value;
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const travelerGuid = new URLSearchParams(location.search).get(
      'travelerGuid'
    );
    if (
      !profile ||
      profile.guid.toLowerCase() !== travelerGuid?.toLowerCase()
    ) {
      return;
    }
    navigate(
      `/${profile.isArranger ? 'travellers' : 'profile'}${location.search}`,
      { replace: true }
    );
  }, [profile, location.search]);

  return <Loader e2e="profile_spinner" />;
};
export default ProfileOrTravellers;
