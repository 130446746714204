import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as Copy2Svg } from 'styles/icons/copy_2.svg';
import palette from 'styles/palette';
import { copyTextToClipboard } from 'utils/copy';

import Button from '../Button/Button';
import { ControlledPopper } from '../Popper/Popper';
import Typography from '../Typography/Typography';

const useTimedPopper = (
  isPopperShown: boolean,
  setiIsPopperShown: Dispatch<SetStateAction<boolean>>,
  timeout: number
) => {
  const ref = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isPopperShown) {
      ref.current = setTimeout(() => {
        setiIsPopperShown(false);
      }, timeout);
    }
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [isPopperShown]);
};

const CopyButton: FC<{
  value: string;
  className?: string;
  iconSize?: number;
}> = ({ className, iconSize = 12, value }) => {
  const [isPopperShown, setiIsPopperShown] = useState(false);
  useTimedPopper(isPopperShown, setiIsPopperShown, 3000);

  return (
    <>
      <ControlledPopper
        trigger="click"
        type="tooltip"
        content={
          <Typography color="white" variant="bodySmall">
            Copied
          </Typography>
        }
        open={isPopperShown}
        setOpen={setiIsPopperShown}
        placement="top"
        offsetOptions={{ mainAxis: 10 }}
        noArrow
      >
        <Button
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            copyTextToClipboard(value);
            setiIsPopperShown(true);
          }}
          customPadding
          className={className}
        >
          <Copy2Svg
            width={iconSize}
            height={iconSize}
            className="cursor-pointer"
            color={palette['grey-6']}
          />
        </Button>
      </ControlledPopper>
    </>
  );
};

export default CopyButton;
