import classNames from 'classnames';
import { FC } from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import Typography from 'components/common/Typography/Typography';

const Loader: FC<{ e2e?: string; className?: string }> = ({
  className,
  e2e = 'loader',
}) => (
  <div
    className={classNames(
      className,
      'flex grow flex-col items-center justify-center gap-2'
    )}
  >
    <div className="flex flex-col items-center gap-2">
      <div className="flex h-10 -scale-50 items-center">
        {/* hack to easily resize spinner  */}
        <Spinner data-e2e-spinner={e2e} />
      </div>
      <Typography variant="bodyMedium">Loading data, please wait...</Typography>
    </div>
  </div>
);

export default Loader;
