import { PropsWithChildren, useEffect, useRef } from 'react';

import { ReactPortal } from './ReactPortal';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  timeoutForClose?: number;
};
export const useCloseTimeout = ({
  isOpen,
  onClose,
  timeoutForClose,
}: ModalProps) => {
  const timer = useRef<number>();
  useEffect(() => {
    if (isOpen) {
      timer.current = window.setTimeout(() => {
        onClose();
      }, timeoutForClose);
    }
    return () => clearTimeout(timer.current);
  }, [isOpen]);
};

export default function Notification({
  children,
  isOpen,
  onClose,
  timeoutForClose = 5000,
}: PropsWithChildren<ModalProps>) {
  useCloseTimeout({ isOpen, onClose, timeoutForClose });

  if (!isOpen) return null;

  return (
    <ReactPortal>
      <div className="w fixed left-1/2 top-[40px] z-50 w-[480px] translate-x-[-50%] bg-midnight-black">
        {children}
      </div>
    </ReactPortal>
  );
}
