import classNames from 'classnames';
import { FC } from 'react';

import CopyButton from 'components/common/CopyButton/CopyButton';
import { ReactComponent as SherpaLogoSvg } from 'styles/icons/sherpa-logo.svg';

import styles from './Sherpa.module.scss';

interface ISherpaProps {
  value: string;
  className?: string;
  iconSize?: number;
  'data-e2e-sherpa'?: string;
}

const Sherpa: FC<ISherpaProps> = ({
  className,
  iconSize,
  value,
  ...otherProps
}) => {
  return (
    <div className={classNames(styles.sherpa, className)} {...otherProps}>
      <SherpaLogoSvg />
      <CopyButton value={value} iconSize={iconSize} />
    </div>
  );
};

export default Sherpa;
