import classNames from 'classnames';
import { FC } from 'react';

import CopyButton from 'components/common/CopyButton/CopyButton';
import Typography, {
  TypographyProps,
} from 'components/common/Typography/Typography';

import styles from './Pnr.module.scss';

interface IPnrProps {
  value: string;
  className?: string;
  iconSize?: number;
  variant: TypographyProps['variant'];
}

const Pnr: FC<IPnrProps> = ({ className, iconSize, value, variant }) => {
  return (
    <div className={classNames(styles.code, className)}>
      <Typography variant={variant} className="break-all">
        {value}
      </Typography>
      {value !== 'N/A' && value !== '' && (
        <CopyButton value={value} iconSize={iconSize} />
      )}
    </div>
  );
};

export default Pnr;
