import classNames from 'classnames';
import { Ref, forwardRef, useEffect, useState } from 'react';

import Typography from 'components/common/Typography/Typography';
import { ReactComponent as InfoOctagonSvg } from 'styles/icons/info-octagon.svg';

export const useTextArea = ({
  errorRules = [],
  initialValue,
  placeholder,
}: {
  placeholder: string;
  initialValue?: string;
  errorRules: ((v: string) => boolean | string)[];
}) => {
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);
  return {
    errors: errorRules.map((isValid) => isValid(value)).filter((v) => !!v),
    initialValue,
    placeholder,
    setTouched,
    setValue,
    touched,
    value,
  };
};
const TextArea = (
  {
    className,
    errors,
    initialValue,
    placeholder,
    setTouched,
    setValue,
    touched,
    value,
  }: ReturnType<typeof useTextArea> & { className?: string },
  ref: Ref<HTMLTextAreaElement>
) => {
  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
  }, [initialValue]);
  const showError = value && touched && !!errors.length;
  return (
    <>
      <textarea
        ref={ref}
        className={classNames(
          className,
          'box-border h-[84px] resize-none gap-2 border border-solid border-grey-5 p-2',
          showError &&
            'border border-solid border-ui-red-darken outline-1 outline-ui-red-darken'
        )}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          const text = e.target.value;
          setValue(text);
          setTouched(true);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      {showError && (
        <div className="mt-2 flex flex-col gap-2">
          {errors.map((errorString, i) => (
            <div className="flex items-center gap-[5px]" key={i}>
              <InfoOctagonSvg />
              <Typography variant="bodySmall" color="uiRedDarken">
                {errorString}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default forwardRef(TextArea);
