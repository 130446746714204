import { FC } from 'react';

import { ReactComponent as SearchSvg } from 'styles/icons/search.svg';

import classNames from 'classnames';
import { useSearchInput } from 'hooks/general';
import styles from './SearchInput.module.scss';

interface ISearchTypes {
  placeholder: string;
  onSearch: (value: string) => void;
  resetSearch: () => void;
  className?: string;
}

const SearchInput: FC<ISearchTypes> = ({
  className,
  onSearch,
  placeholder,
  resetSearch,
}) => {
  const { handleChange, inputRef, term } = useSearchInput({
    onSearch,
    resetSearch,
  });

  return (
    <div className={classNames(styles.searchContainer, className)}>
      <div className={classNames(styles.search, 'gap-[10px] p-[10px]')}>
        <SearchSvg width={16} className="text-grey-5" />
        <input
          className={styles.searchField}
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          value={term}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
