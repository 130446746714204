import { FC } from 'react';

interface ICirclePlus {}

const CirclePlus: FC<ICirclePlus> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 2.33531C5.31794 2.33531 2.33317 5.32008 2.33317 9.00197C2.33317 12.6839 5.31794 15.6686 8.99984 15.6686C12.6817 15.6686 15.6665 12.6839 15.6665 9.00197C15.6665 5.32008 12.6817 2.33531 8.99984 2.33531ZM0.666504 9.00197C0.666504 4.3996 4.39746 0.66864 8.99984 0.66864C13.6022 0.66864 17.3332 4.3996 17.3332 9.00197C17.3332 13.6043 13.6022 17.3353 8.99984 17.3353C4.39746 17.3353 0.666504 13.6043 0.666504 9.00197Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6665 9.00197C5.6665 8.54174 6.0396 8.16864 6.49984 8.16864H11.4998C11.9601 8.16864 12.3332 8.54174 12.3332 9.00197C12.3332 9.46221 11.9601 9.83531 11.4998 9.83531H6.49984C6.0396 9.83531 5.6665 9.46221 5.6665 9.00197Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 5.66864C9.46008 5.66864 9.83317 6.04174 9.83317 6.50197V11.502C9.83317 11.9622 9.46008 12.3353 8.99984 12.3353C8.5396 12.3353 8.1665 11.9622 8.1665 11.502V6.50197C8.1665 6.04174 8.5396 5.66864 8.99984 5.66864Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CirclePlus;
