import Typography from 'components/common/Typography/Typography';
import { FC } from 'react';
import { ReactComponent as CompanyDocsSvg } from 'styles/icons/company_documents.svg';

const EmptyCompanyDocs: FC = () => {
  return (
    <div className="flex items-center justify-center gap-2 p-4">
      <CompanyDocsSvg />
      <div className="flex flex-col">
        <Typography variant="bodyLarge" color="grey-6">
          Company Documents
        </Typography>
        <Typography variant="bodyMedium" color="grey-5">
          have not been added yet
        </Typography>
      </div>
    </div>
  );
};

export default EmptyCompanyDocs;
