import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export function ReactPortal({
  children,
  wrapperId = 'root',
}: PropsWithChildren<{ wrapperId?: string }>) {
  const wrapper = document.getElementById(wrapperId);
  if (!wrapper) {
    return <>{children}</>;
  }
  return createPortal(children, wrapper);
}
