import classNames from 'classnames';
import { format } from 'date-fns/esm';
import { FC } from 'react';

import Typography from 'components/common/Typography/Typography';
import {
  CAR_CATEGORY,
  CAR_TYPES,
  FUEL,
  TRANSMISSION,
} from 'constants/tripsCar';
import { ReactComponent as CarSvg } from 'styles/icons/amenities/taxi.svg';
import { ICarRentalBooking } from 'types/trips';
import { joinTruishStrings, renderPlurals } from 'utils/format';
import { getBookingType, getCurrencyToShow } from 'utils/trips';

import { renderOptions } from '../DetailedTrips';
import styles from '../DetailedTrips.module.scss';

export const getCarDisplayDataFromBooking = (booking: ICarRentalBooking) => {
  const departureDate = booking.LocalPickupAt || '';
  const arrivalDate = booking.LocalDropoffAt || '';
  const pickupLocation = booking.PickupLocation;
  const pickupLocationName = joinTruishStrings(
    [
      pickupLocation?.CountryCode,
      pickupLocation?.CityName,
      pickupLocation?.Address,
    ],
    'Pick up location N/A'
  );
  const dropoffLocation = booking.DropoffLocation;
  const dropoffLocationName = joinTruishStrings(
    [
      dropoffLocation?.CountryCode,
      dropoffLocation?.CityName,
      dropoffLocation?.Address,
    ],
    'Drop off location N/A'
  );

  const mileageAllowance = booking.MileageAllowance;
  const mileageAllowanceText = mileageAllowance?.Unlimited
    ? 'Unlimited'
    : `${mileageAllowance?.MaxDistance ?? ''} ${
        mileageAllowance?.MaxDistanceUnit ?? ''
      }`.trim();
  const duration = booking.NumberOfDays;
  const pnr = booking.PnrRecordLocator;
  const companyName = booking.Company?.Name;
  const carSpecification = booking.CarSpecification;

  const currency = booking.Rate?.Currency ?? '';

  const getTotal = () => {
    const total = booking.Rate?.Total;
    if (!total) return 'N/A';
    return `${getCurrencyToShow(currency)} ${total}`;
  };

  const getRatePerDay = () => {
    const total = booking.Rate?.Total;
    if (!duration || !total) return 'N/A';
    const amount = Number(total) / duration;
    return `${getCurrencyToShow(currency)} ${amount.toFixed(2)}`;
  };

  const options = [
    {
      label: 'Type',
      value: carSpecification?.CarType
        ? CAR_TYPES[carSpecification.CarType]
        : 'N/A',
    },
    {
      label: 'Category',
      value: carSpecification?.Category
        ? CAR_CATEGORY[carSpecification.Category]
        : 'N/A',
    },
    {
      label: 'Transmission',
      value: carSpecification?.Transmission
        ? TRANSMISSION[carSpecification.Transmission]
        : 'N/A',
    },
    {
      label: 'Fuel',
      value: carSpecification?.Fuel ? FUEL[carSpecification.Fuel] : 'N/A',
    },
    { label: 'Rate per day', value: getRatePerDay() },
    { label: 'Total', value: getTotal() },
  ];
  return {
    arrivalDate,
    companyName,
    departureDate,
    dropoffLocationName,
    duration,
    mileageAllowanceText,
    options,
    pickupLocationName,
    pnr,
  };
};

const CarSection: FC<{ booking: ICarRentalBooking; id: number }> = ({
  booking,
  id,
}) => {
  const {
    arrivalDate,
    companyName,
    departureDate,
    dropoffLocationName,
    duration,
    mileageAllowanceText,
    options,
    pickupLocationName,
  } = getCarDisplayDataFromBooking(booking);
  return (
    <div
      className={styles.sectionContainer}
      id={`${getBookingType(booking)}_${id}`}
    >
      <div className="flex gap-[20px]">
        <CarSvg
          width={24}
          height={24}
          className={classNames(styles.jet, 'shrink ')}
          data-e2e-icon="Car"
        />
        <div className="w-full">
          <div className={styles.segmentPrimaryInfo}>
            <div className={classNames(styles.fixWidth, styles.from)}>
              <Typography
                variant="bodyMedium"
                data-e2e-car_summary_departure_date
              >
                {departureDate
                  ? format(new Date(departureDate), 'd MMM yyyy, h:mm a')
                  : 'N/A'}{' '}
              </Typography>
              <Typography variant="bodyMedium2">
                {pickupLocationName}
              </Typography>
            </div>
            <div
              className={classNames(
                styles.fixWidth,
                styles.to,
                'text-right lg:text-left'
              )}
            >
              <Typography
                variant="bodyMedium"
                data-e2e-car_summary_arrival_date
              >
                {arrivalDate
                  ? format(new Date(arrivalDate), 'd MMM yyyy, h:mm a')
                  : 'N/A'}
              </Typography>
              <Typography variant="bodyMedium2">
                {dropoffLocationName}
              </Typography>
            </div>
            <div
              className={classNames(
                'mr-auto flex flex-col gap-1',
                styles.stops
              )}
            >
              <Typography variant="bodySmall">
                {duration
                  ? renderPlurals({
                      count: duration,
                      entity: ['day', 'days'],
                    })
                  : 'N/A days'}
              </Typography>
            </div>
          </div>
          <div className="mt-3 flex items-center bg-grey-1 lg:pr-2">
            <div className="flex items-center gap-2">
              <Typography
                variant="bodySmall2"
                className="bg-green-highlight px-2 py-1"
              >
                {booking.IsPassive ? 'Passive' : 'GDS'}
              </Typography>
              <Typography variant="bodySmall">
                Confirmation Number:{' '}
                <span className="font-bold">
                  {booking.ConfirmationNumber || 'N/A'}
                </span>
              </Typography>
            </div>
          </div>
          <div className="mt-3">
            {companyName && (
              <Typography variant="bodySmall2" data-e2e-vendor={companyName}>
                {companyName}
              </Typography>
            )}
            <div className="mt-3 flex flex-col gap-6 lg:flex-row lg:gap-0">
              <div className="flex grow flex-col">
                <Typography variant="bodySmall">Mileage allowance</Typography>
                <Typography
                  variant="bodyMedium2"
                  className="self-start text-right lg:text-left"
                >
                  {mileageAllowanceText || 'N/A'}
                </Typography>
              </div>
              <div
                className={classNames(
                  'flex flex-col gap-1',
                  styles.optionsBlock
                )}
              >
                {options.map(renderOptions)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarSection;
