import { FC } from 'react';

import Typography, { TypographyProps } from '../Typography/Typography';

const RequiredStar: FC<Omit<TypographyProps, 'children'>> = (props) => {
  return (
    <div className="h-5">
      <Typography {...props} color="ui-red-darken">
        *
      </Typography>
    </div>
  );
};
export default RequiredStar;
